<template>
  <!-- 진행중인 업무 >> 수출 -->
  <div>
    <div class="content_box mt10">
      <div class="flex_box">
        <span class="tot_num">{{ $t('msg.ONEX010T010.011') }}<!-- Total --> : <span class="num">{{ totalCount }}</span></span>
        <span class="input_box">
          <input type="text" id="filter" class="wid200" v-model="txtFilter" @keyup="changeFilter()" autocomplete="off" :placeholder="$t('msg.ONEX010T010.012')">
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid100" v-model="selPo">
            <option value="POL">Departure</option>
            <option value="POD">Arrival</option>
          </select>
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid200" v-model="selCtr" @change="checkSelectCtr()">
            <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="ctrInfo in ctrCdList" :key="ctrInfo.ctrCd" :value="ctrInfo.ctrCd">{{ ctrInfo.ctrEnm }}</option>
          </select>
        </span>
        <span class="ml_auto cal_btn col_43p tar">
          <span v-show="searchParams.kind !== '02' && pkupUsagYn === 'Y'">
            <input type="checkbox" id="onlyPickupYn" v-model="onlyPickupYn" true-value="Y" false-value="N" @change="changeFilter()">
            <label for="onlyPickupYn" class="mr20"><span></span>{{ $t('msg.ONEX010T010.136') }}<!-- 픽업 부킹만 보기 --></label>
          </span>
          <span v-show="searchParams.kind !== '02'" class="ml20">
            <input type="checkbox" id="onlyCancelYn" v-model="onlyCancelYn" true-value="Y" false-value="N" @change="changeFilter()">
            <label for="onlyCancelYn" class="mr20"><span></span>{{ $t('msg.ONEX010T010.137') }}<!-- 취소한 부킹만 보기 --></label>
          </span>
          <a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a> <!-- 엑셀 다운로드 -->
        </span>
      </div>
      <div id="realgrid" :style="`width: 100%; height: ${gridHeight}px`" />
    </div>

    <div class="request_area">
      <div class="top" @click.prevent="openRequestArea()" style="cursor:pointer">
        <span class="tit">{{ $t('msg.ONEX010T010.013') }}<!-- 요청사항 --></span>
        <span class="ntx">{{ $t('msg.ONEX010T010.014') }}<!-- 진행중인 업무를 선택(중복 선택 가능)하고 필요한 요청을 클릭하기 바랍니다. --><span> ({{ $t('msg.ONEX010T010.138', { cnt: checkedCount }) }})<!-- (x개 선택됨) --></span></span>
        <button type="button" class="reqbtn up" @click.prevent="openRequestArea()"><span>{{ $t('msg.ONEX010T010.015') }}<!-- 열기 --></span></button>
      </div>
      <div class="sub_area" @click.prevent="closeRequestArea()" style="display: block; cursor: pointer;" id="working-progress-request-area">
        <div class="top">
          <span class="tit">{{ $t('msg.ONEX010T010.013') }}<!-- 요청사항 --></span>
          <span class="ntx">{{ $t('msg.ONEX010T010.014') }}<!-- 진행중인 업무를 선택(중복 선택 가능)하고 필요한 요청을 클릭하기 바랍니다. --><span> ({{ $t('msg.ONEX010T010.138', { cnt: checkedCount }) }})<!-- (x개 선택됨) --></span></span>
          <button type="button" class="reqbtn down" @click.prevent="closeRequestArea()"><span>{{ $t('msg.ONEX010T010.016') }}<!-- 닫기 --></span></button>
        </div>
        <div class="list">
          <div class="item">
            <p>{{ $t('msg.ONEX010T010.017') }}<!-- 부킹 --></p>
            <ul>
              <li :class="requestAreaBtn.booking01 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBooking('01')">{{ $t('msg.ONEX010T010.018') }}<!-- 부킹 취소 --></a></li>
              <li v-if="memberDetail.userCtrCd !== 'CN'" :class="requestAreaBtn.booking02 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBooking('02')">{{ $t('msg.ONEX010T010.019') }}<!-- 부킹 분할 --></a></li>
              <li v-if="memberDetail.userCtrCd !== 'CN'" :class="requestAreaBtn.booking03 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBooking('03')">{{ $t('msg.ONEX010T010.020') }}<!-- 부킹 병합 --></a></li>
              <li v-if="memberDetail.userCtrCd === 'KR'" :class="requestAreaBtn.booking04 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBooking('04')">{{ $t('msg.ONEX010T010.021') }}<!-- 픽업지 변경 --></a></li>
              <li v-if="pkupUsagYn === 'Y'"><a href="#" class="link" @click.prevent="goPickupBookingPage()">{{ $t('msg.ONEX010T010.022') }}<!-- 픽업부킹 생성 --></a></li>
            </ul>
          </div>
          <div class="item">
            <p>{{ $t('msg.ONEX010T010.026') }}<!-- B/L --></p>
            <ul>
              <li :class="requestAreaBtn.bl01 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBl('01')">{{ $t('msg.ONEX010T010.027') }}<!-- S/R 작성 --></a></li>
              <li v-if="memberDetail.userCtrCd !== 'CN'" :class="requestAreaBtn.bl02 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBl('02')">{{ $t('msg.ONEX010T010.028') }}<!-- B/L 분할 --></a></li>
              <li v-if="memberDetail.userCtrCd === 'KR'" :class="requestAreaBtn.bl03 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestBl('03')">{{ $t('msg.ONEX010T010.033') }}<!-- 컨테이너 번호 변경 --></a></li>
              <li :class="requestAreaBtn.bl04 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBl('04')">{{ $t('msg.ONEX010T010.029') }}<!-- B/L Certificate --></a></li>
              <li v-if="['CN', 'MY'].indexOf(memberDetail.userCtrCd) > -1" :class="requestAreaBtn.bl05 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnCommonBtnAction('VGMPOP_OPEN')">Input VGM<!-- Input VGM --></a></li>
              <li :class="caReqYn === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBl('05')">{{ $t('msg.ONEX010T010.160') }}<!-- C/A Request --></a></li>
              <li v-if="memberDetail.userCtrCd === 'IN'" :class="requestAreaBtn.bl06 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestBl('06')">B/L Issue Request<!-- B/L Issue Request --></a></li>
            </ul>
          </div>
          <div class="item">
            <p>{{ $t('msg.ONEX010T010.023') }}<!-- 정산 --></p>
            <ul>
              <li :class="requestAreaBtn.charge01 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestCharge('01')">{{ $t('msg.ONEX010T010.071') }}<!-- 정산/발행 --></a></li>
              <li :class="requestAreaBtn.charge02 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestCharge('02')">{{ $t('msg.ONEX010T010.024') }}<!-- 운임 정정 요청 --></a></li>
              <li :class="requestAreaBtn.charge03 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestCharge('03')">{{ $t('msg.ONGO080G010.034') }}<!-- Free Time --></a></li>
            </ul>
          </div>
          <div class="item">
            <p>{{ $t('msg.ONEX010T010.030') }}<!-- 운송 --></p>
            <ul>
              <li v-if="memberDetail.userCtrCd === 'KR'" :class="requestAreaBtn.trans01 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestTrans('01')">{{ $t('msg.ONEX010T010.031') }}<!-- 위험물 반입 요청 --></a></li>
              <li v-if="memberDetail.userCtrCd === 'KR'" :class="requestAreaBtn.trans02 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestTrans('02')">{{ $t('msg.ONEX010T010.032') }}<!-- Full 컨테이너 반출 --></a></li>
              <li v-if="memberDetail.userCtrCd === 'KR'" :class="requestAreaBtn.trans03 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestTrans('03')">{{ $t('msg.ONEX010T010.072') }}<!-- 셔틀 요청 --></a></li>
              <li :class="requestAreaBtn.trans04 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestTrans('04')">{{ $t('msg.ONEX010T010.034') }}<!-- COD --></a></li>
              <li :class="requestAreaBtn.trans05 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestTrans('05')">{{ $t('msg.ONEX010T010.035') }}<!-- SHIPBACK --></a></li>
              <li v-if="lang === 'KOR'" :class="requestAreaBtn.trans06 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestTrans('06')">선적 위치<!-- 선적 위치 --></a></li>
            </ul>
          </div>
          <div class="item">
            <p>{{ $t('msg.ONEX010T010.037') }}<!-- 각종 출력 --></p>
            <ul>
              <li :class="requestAreaBtn.report01 === 'Y' ? 'col_48p' : 'disabled col_48p'"><a href="#" class="pop" @click.prevent="fnRequestReport('01')">{{ $t('msg.ONEX010T010.038') }}<!-- 부킹 확인서 --></a></li>
              <li v-if="memberDetail.userCtrCd === 'IN'" :class="requestAreaBtn.report05 === 'Y' || nonFreightYn === 'Y' ? 'col_48p' : 'disabled col_48p'"><a href="#" class="pop" @click.prevent="fnRequestReport('05')">{{ $t('msg.ONEX010T010.039') }}<!-- Invoice --></a></li>
              <li v-else :class="requestAreaBtn.report02 === 'Y' ? 'col_52p' : 'disabled col_52p'"><a href="#" class="pop" @click.prevent="fnRequestReport('02')">{{ $t('msg.ONEX010T010.040') }}<!-- Delay Notice --></a></li>
              <li :class="requestAreaBtn.report03 === 'Y' ? 'col_48p' : 'disabled col_48p'"><a href="#" class="pop" @click.prevent="fnRequestReport('03')">{{ $t('msg.ONEX010T010.042') }}<!-- Vessel Change Notice --></a></li>
              <li :class="requestAreaBtn.report04 === 'Y' ? 'col_52p' : 'disabled col_52p'"><a href="#" class="pop" @click.prevent="fnRequestReport('04')">{{ $t('msg.ONEX010T010.044') }}<!-- B/L Copy --></a></li>
              <li v-if="memberDetail.userCtrCd !== 'IN'" :class="requestAreaBtn.report05 === 'Y' || nonFreightYn === 'Y' ? 'col_48p' : 'disabled col_48p'"><a href="#" class="pop" @click.prevent="fnRequestReport('05')">{{ $t('msg.ONEX010T010.039') }}<!-- Invoice --></a></li>
              <li v-else :class="requestAreaBtn.report02 === 'Y' ? 'col_48p' : 'disabled col_48p'"><a href="#" class="pop" @click.prevent="fnRequestReport('02')">{{ $t('msg.ONEX010T010.040') }}<!-- Delay Notice --></a></li>
              <li :class="surrWaybillYn === 'Y' ? 'col_48p' : 'disabled col_48p'"><a class="pop" @click.prevent="fnRequestReport('10')">Surrender/Sea Waybill Notice</a></li><!-- SURRENDER NOTICE -->
              <li :class="requestAreaBtn.report06 === 'Y' ? 'col_48p' : 'disabled col_48p'"><a href="#" class="pop" @click.prevent="fnRequestReport('06')">{{ $t('msg.ONEX010T010.041') }}<!-- Free Time 승인 내역 --></a></li>
              <li :class="requestAreaBtn.report07 === 'Y' ? 'col_48p' : 'disabled col_48p'"><a href="#" class="pop" @click.prevent="fnRequestReport('07')">{{ $t('msg.ONEX010T010.043') }}<!-- B/L Certificate --></a></li>
              <li v-if="memberDetail.userCtrCd === 'ID'" :class="requestAreaBtn.report08 === 'Y' ? 'col_52p' : 'disabled col_52p'"><a class="pop" @click.prevent="fnRequestReport('08')">{{ $t('msg.ONEX010T010.092') }}</a></li><!-- RECEIPT -->
              <li :class="riderYn === 'Y' ? 'col_48p' : 'disabled col_48p'"><a class="pop" @click.prevent="fnRequestReport('09')">RIDER</a></li><!-- RIDER -->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <common-unsolved ref="commonUnsolved" />

    <win-layer-pop class="export_ongoing_list_popup">
      <component
        v-if="popupComponent"
        :is="popupComponent"
        @close="closePopup"
        @callback="popupCallback"
        :parent-info="popupParams"
      />
    </win-layer-pop>

    <win-layer-pop class="export_ongoing_list_popup_tp2">
      <component
        v-if="popupTp2Component"
        :is="popupTp2Component"
        @close="closeTp2Popup"
        :ok-hidden="isHidden"
        :is-show-pop="isShowPop"
        :detail="popupTp2Params"
      />
    </win-layer-pop>

    <win-layer-pop class="export_ongoing_list_popup_tp3">
      <component
        v-if="popupTp3Component"
        :is="popupTp3Component"
        :parent-info="popupTp3Params"
        @close="closeTp3Popup"
        @selectFunc="popupTp3Callback"
      />
    </win-layer-pop>

    <win-layer-pop class="my_surcharge_pop">
      <component
        v-if="frtSurchargeComponent"
        :is="frtSurchargeComponent"
        @close="freSurchargeClosePopup"
        :parent-info="surChargeParams"
      />
    </win-layer-pop>

    <component
      v-if="commonBookingComponent"
      :is="commonBookingComponent"
      :parent-info="commonBookingParams"
      ref="commonBooking"
      @callback="commonBookingCallback"
    />

    <component
      v-if="commonBlComponent"
      :is="commonBlComponent"
      :parent-info="commonBlParams"
      ref="commonBL"
      @callback="commonBlCallback"
    />
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import commons from '@/api/services/commons'
import exportSvc from '@/api/rest/trans/exportSvc'
import importSvc from '@/api/rest/trans/importSvc'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import shippingConfirm from '@/api/rest/trans/shippingConfirm'
import schedule from '@/api/rest/schedule/schedule'
import cp from '@/api/rest/cp/cp'
import CalcIssue from '@/api/rest/settle/calcIssue'
import myRequest from '@/api/rest/trans/myRequest'
import srInquiry from '@/api/services/srInquiry'
import JSZip from 'jszip'
import moment from 'moment'
import nonFreight from '@/api/rest/settle/nonFreight'
import blAttach from '@/api/rest/trans/blAttach'
import bookings from '@/api/rest/trans/bookings'

let gridView = GridView
let provider = LocalDataProvider

const convertDate = (strDt) => {
  if (strDt === undefined || strDt === null || strDt === '') {
    return ''
  }
  const month = {
    K01: 'JAN',
    K02: 'FEB',
    K03: 'MAR',
    K04: 'APR',
    K05: 'MAY',
    K06: 'JUN',
    K07: 'JUL',
    K08: 'AUG',
    K09: 'SEP',
    K10: 'OCT',
    K11: 'NOV',
    K12: 'DEC'
  }
  const key = 'K' + strDt.substring(4, 6)
  const m = month[key] || ''
  const d = strDt.substring(6, 8)
  const t = strDt.substring(8, 10)
  return `${m} ${d} ${t}H`
}

export const fields = [
  { fieldName: 'bkgNo', dataType: 'text' },
  { fieldName: 'bkgStsCd', dataType: 'text' },
  { fieldName: 'bkgCnclIngYn', dataType: 'text' },
  { fieldName: 'srRno', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'blNoOrSrRno', dataType: 'text' },
  { fieldName: 'blStsCd', dataType: 'text' },
  { fieldName: 'srCnclYn', dataType: 'text' },
  { fieldName: 'blCnclYn', dataType: 'text' },
  { fieldName: 'caStsCd', dataType: 'text' },
  { fieldName: 'shprRspsYn', dataType: 'text' },
  { fieldName: 'shprRspsBtnYn', dataType: 'text' },
  { fieldName: 'mtyPkupUsagYn', dataType: 'text' },
  { fieldName: 'actShprCstEnm', dataType: 'text' },
  { fieldName: 'cgoModeCd', dataType: 'text' },
  { fieldName: 'por', dataType: 'text' },
  { fieldName: 'dly', dataType: 'text' },
  { fieldName: 'etd', dataType: 'text' },
  { fieldName: 'etdDiff', dataType: 'text' },
  { fieldName: 'eta', dataType: 'text' },
  { fieldName: 'etaDiff', dataType: 'text' },
  { fieldName: 'statusCd', dataType: 'text' },
  { fieldName: 'polCtrCd', dataType: 'text' },
  { fieldName: 'polPortCd', dataType: 'text' },
  { fieldName: 'polPortNm', dataType: 'text' },
  { fieldName: 'podCtrCd', dataType: 'text' },
  { fieldName: 'podPortCd', dataType: 'text' },
  { fieldName: 'podPortNm', dataType: 'text' },
  { fieldName: 'vslCd', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'cntrQty', dataType: 'text' },
  { fieldName: 'dgCertiCnt', dataType: 'Number' },
  { fieldName: 'srYn', dataType: 'text' },
  { fieldName: 'docDtm', dataType: 'text' },
  { fieldName: 'cntrCntChk', dataType: 'text' },
  { fieldName: 'vgmStatus', dataType: 'text' },
  { fieldName: 'blCntrQty', dataType: 'Number' },
  { fieldName: 'shprCntrQty', dataType: 'Number' },
  { fieldName: 'bkgAcpnMethCd', dataType: 'text' },
  { fieldName: 'issueReason', dataType: 'text' },
  { fieldName: 'issueKind', dataType: 'text' },
  { fieldName: 'issueDate', dataType: 'text' },
  { fieldName: 'issueStatus', dataType: 'text' },
  { fieldName: 'issueElYn', dataType: 'text' },
  { fieldName: 'issueCertiDt', dataType: 'text' },
  { fieldName: 'cntrStsCd', dataType: 'text' },
  { fieldName: 'cntrCfsYn', dataType: 'text' },
  { fieldName: 'blCatCd', dataType: 'text' },
  { fieldName: 'surrYn', dataType: 'text' },
  { fieldName: 'wbilCfmYn', dataType: 'text' },
  { fieldName: 'elYn', dataType: 'text' },
  { fieldName: 'frtYn', dataType: 'text' },
  { fieldName: 'cgoTypCd', dataType: 'text' },
  { fieldName: 'hzYn', dataType: 'text' },
  { fieldName: 'rvsdEndpDtm', dataType: 'text' },
  { fieldName: 'rvsdEndpYn', dataType: 'text' },
  { fieldName: 'msnNo', dataType: 'text' },
  { fieldName: 'cnclRsnRmk', dataType: 'text' },
  { fieldName: 'cnclRsnCd', dataType: 'text' },
  { fieldName: 'cnclRsnDsc', dataType: 'text' },
  { fieldName: 'fstEntDtm', dataType: 'text' },
  { fieldName: 'anChk', dataType: 'text' },
  { fieldName: 'anEmail', dataType: 'text' },
  { fieldName: 'anYn', dataType: 'text' },
  { fieldName: 'doChk', dataType: 'text' },
  { fieldName: 'podArvYn', dataType: 'text' },
  { fieldName: 'docs00', dataType: 'text' }, // VGM
  { fieldName: 'docs01', dataType: 'text' },
  { fieldName: 'docs02', dataType: 'text' },
  { fieldName: 'docs03', dataType: 'text' },
  { fieldName: 'docs04', dataType: 'text' },
  { fieldName: 'docs05', dataType: 'text' },
  { fieldName: 'cntrPickUp', dataType: 'text' },
  { fieldName: 'cntrGti', dataType: 'text' },
  { fieldName: 'cntrLdg', dataType: 'text' },
  { fieldName: 'cntrDis', dataType: 'text' },
  { fieldName: 'cntrGto', dataType: 'text' },
  { fieldName: 'cntrRtn', dataType: 'text' },
  { fieldName: 'reqDtm', dataType: 'text' },
  { fieldName: 'tel', dataType: 'text' },
  { fieldName: 'rteCd', dataType: 'text' },
  { fieldName: 'srCmplsSbmtYn', dataType: 'text' },
  { fieldName: 'blChkYn', dataType: 'text' },
  { fieldName: 'temcount', dataType: 'text' },
  { fieldName: 'tsPortCd', dataType: 'text' },
  { fieldName: 'reqRno', dataType: 'text' },
  { fieldName: 'reqCatCd', dataType: 'text' },
  { fieldName: 'rmk', dataType: 'text' },
  { fieldName: 'rspsRmk', dataType: 'text' },
  { fieldName: 'reqUno', dataType: 'text' },
  { fieldName: 'apvStsCd', dataType: 'text' },
  { fieldName: 'docmRmk', dataType: 'text' },
  { fieldName: 'waitRsnCd', dataType: 'text' },
  { fieldName: 'waitRsnDsc', dataType: 'text' },
  { fieldName: 'waitRsnRmk', dataType: 'text' },
  { fieldName: 'iotCntrCnt', dataType: 'Number' },
  { fieldName: 'rpaCfmYn', dataType: 'text' },
  { fieldName: 'spcShiprYn', dataType: 'text' },
  { fieldName: 'bkgCntr', dataType: 'text' },
  { fieldName: 'maxRejectCont', dataType: 'text' },
  { fieldName: 'polTrmlCd', dataType: 'text' },
  { fieldName: 'podTrmlCd', dataType: 'text' },
  { fieldName: 'onlyActYn', dataType: 'text' },
  { fieldName: 'recentCaStsNm', dataType: 'text' },
  { fieldName: 'recentCaStsCd', dataType: 'text' },
  { fieldName: 'recentCaApvYn', dataType: 'text' },
  { fieldName: 'isFwdrYn', dataType: 'text' },
  { fieldName: 'fwdrCstNo', dataType: 'text' },
  { fieldName: 'apvCont', dataType: 'text' },
  { fieldName: 'frtRatesChkYn', dataType: 'text' },
  { fieldName: 'caFdrChk', dataType: 'text' }
]

// 서류, 컨테이너 toggle 이전 컬럼
// 아래 HTML TAG를 한줄로 만든 이유 : 줄바꿈 할 경우 엑셀 다운로드시 개행문자(\n)가 추가되어
// 엑셀에서 줄바꿈이 무분별하게 되어 한줄로 수정하여 개행문자 없애고, 필요한 부분에만 개행문자 추가함. 2022.08.25 dsyang.
// 참고로 html.push()로 내용 추가하는 경우 개행문자 자동 추가됨.
const defCol1 = [
{
    name: 'bkgNo',
    fieldName: 'bkgNo',
    header: { text: app.$t('msg.ONEX010T010.045') + '\n(' + app.$t('msg.ONEX010T010.093') + ')' }, // Booking No.\n(Status)
    width: '90',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const blNo = grid.getValue(idx, 'blNo') || ''
        // const srRno = grid.getValue(idx, 'srRno') || ''
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const bkgStsCd = grid.getValue(idx, 'bkgStsCd')
        // const bkgCnclIngYn = grid.getValue(idx, 'bkgCnclIngYn')
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const bkgStsCd = vmAppWpx.gridList[idx].bkgStsCd
        const bkgCnclIngYn = vmAppWpx.gridList[idx].bkgCnclIngYn
        const docmRmk = (vmAppWpx.gridList[idx].docmRmk) ? vmAppWpx.gridList[idx].docmRmk.replace(/\r\n/g, '</br>') : '' || ''
        const waitRsnCd = vmAppWpx.gridList[idx].waitRsnCd || ''
        const waitRsnDsc = vmAppWpx.gridList[idx].waitRsnDsc || ''
        const waitRsnRmk = (vmAppWpx.gridList[idx].waitRsnRmk) ? vmAppWpx.gridList[idx].waitRsnRmk.replace(/\r\n/g, '</br>') : '' || ''
        const id = bkgNo + '_' + blNo + '_' + srRno
        const onlyActYn = vmAppWpx.gridList[idx].onlyActYn
        const apvCont = (vmAppWpx.gridList[idx].apvCont) ? vmAppWpx.gridList[idx].apvCont.replace(/\r\n/g, '</br>') : undefined
        const caFdrChk = vmAppWpx.gridList[idx].caFdrChk || ''
        const html = []
//vmAppWpx.memberDetail.userCtrCd !== 'JP'
        if (onlyActYn === 'Y') {
          html.push(`${bkgNo}`)
        } else {
          html.push(`<a href="#" onclick="vmAppWpx.goDetailPageAtGrid(event, 'BOOKING_EDIT', ${idx})">${bkgNo}</a>`)
        }
        //html.push(`${bkgNo}`)
        if (bkgStsCd === '00') {
          html.push(`<span class="label2 red">${app.$t('msg.CSBK100.022')}</span>`) // 미승인
        } else if (bkgStsCd === '01') {
          if (bkgCnclIngYn === 'Y') {
            html.push(`<span class="label2 green">${app.$t('msg.ONEX010T010.129')}</span>`) // 취소 처리중
          } else {
            html.push(`<span class="label2 green">${app.$t('msg.CSBK100.023')}</span>`) // 취소
          }
        } else if (bkgStsCd === '02' || bkgStsCd === '05') {
          // 미승인 및 미승인 사유
          if (vmAppWpx.memberDetail.userCtrCd === 'JP') {
            //docmRmk = docmRmk.replace(/\r/g, '</br>')
            const htmlTag = `<span class="label2 red" id="bkg02_btn_${id}" onmouseover="vmAppWpx.showTooltipBkg02JP(event, '${id}', 'bkg02', '${bkgNo}', '${docmRmk}', '${waitRsnCd}', '${waitRsnDsc}', '${waitRsnRmk}', '${bkgStsCd}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'bkg02')">${app.$t('msg.CSBK100.022')}</span><div id="bkg02_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'bkg02')" class="tooltip_wrap position_absolute bkg02_tt" style="width:360px; display:none; left:80px; top:24px;"><div class="cont text_left"><class="title text_left"><br/>${app.$t('msg.ONEX010T010.068')}<ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
            html.push(htmlTag)
          } else {
            const htmlTag = `<span class="label2 red" id="bkg02_btn_${id}" onmouseover="vmAppWpx.showTooltipBkg02(event, '${id}', 'bkg02', '${bkgNo}', '${docmRmk}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'bkg02')">${app.$t('msg.CSBK100.022')}</span><div id="bkg02_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'bkg02')" class="tooltip_wrap position_absolute bkg02_tt" style="width:360px; display:none; left:80px; top:24px;"><div class="cont text_left"><class="title text_left"><br/>${app.$t('msg.ONEX010T010.068')}<ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
            html.push(htmlTag)
          }
          //htmlTag.split('\n').join('')
         // html.push(htmlTag) // 미승인 - 한국어일경우 추가 노출 정보 있음
        } else if (bkgStsCd === '03') {
          // const cnclRsnRmk = grid.getValue(idx, 'cnclRsnRmk') || ''
          // const cnclRsnCd = grid.getValue(idx, 'cnclRsnCd') || ''
          // const cnclRsnDsc = grid.getValue(idx, 'cnclRsnDsc') || ''
          const cnclRsnRmk = vmAppWpx.gridList[idx].cnclRsnRmk || ''
          const cnclRsnCd = vmAppWpx.gridList[idx].cnclRsnCd || ''
          const cnclRsnDsc = vmAppWpx.gridList[idx].cnclRsnDsc || ''

          if (cnclRsnCd !== '') {
            // 취소 및 취소 사유
            const htmlTag = `<span class="label2 gray" id="bkg03_btn_${id}" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'bkg03')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'bkg03')">${app.$t('msg.CSBK100.024')}</span><div id="bkg03_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'bkg03')" class="tooltip_wrap position_absolute bkg03_tt" style="width:300px; display:none; left:80px; top:24px;"><div class="cont text_left"><class="title text_left"><br/>${app.$t('msg.ONEX010T010.069')}<ul class="bul_list_sm t2"><li class="text_left tt_cont"><br/>${cnclRsnDsc}${cnclRsnRmk !== '' ? '<br/>' + cnclRsnRmk : ''}</li></ul></div> </div>`
            //htmlTag.split('\n').join('')
            html.push(htmlTag) // 취소
          } else {
            html.push(`<span class="label2 gray">${app.$t('msg.CSBK100.024')}</span>`) // 취소
          }
        } else if (bkgStsCd === '04') {
          if (bkgCnclIngYn === 'Y') {
            html.push(`<span class="label2 green">${app.$t('msg.ONEX010T010.129')}</span>`) // 취소 처리중
          } else {
            html.push(`<span class="label2 green">${app.$t('msg.CSBK100.023')}</span>`) // 승인 - 국가별 추가정보 있음
          }
        } else {
          // html.push(`<span class="label2 gray">${bkgStsCd}</span>`) // 기타
        }

        if (typeof apvCont !== 'undefined') {
          const htmlTag = `<span class="label2 red" id="warn_btn_${id}" onmouseover="vmAppWpx.showTooltipWarn(event, '${id}', 'warn', '${bkgNo}', '${apvCont}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'warn')" style="font-size:9.5px;">${app.$t('msg.CSBK100.552')}</span><div id="warn_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'warn')" class="tooltip_wrap position_absolute warn_tt" style="width:360px; display:none; left:80px; top:24px;"><div class="cont text_left"><class="title text_left"><br/>${app.$t('msg.CMBA380.RE05')} ${app.$t('msg.ONEX010T010.068')}<ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
          html.push(htmlTag)
        }
        if (typeof caFdrChk !== 'undefined' && caFdrChk !== '') {
          html.push(`<span class="label2 blue">${caFdrChk}</span>`) // 1485328 [FY2024 Global BSC NKG] FEEDER FUNCTION OF YZR -> Feeder 상태값 추가
        }
        return html.join('<br/>')
      }
    }
  },
  {
    name: 'bkgCntr',
    fieldName: 'bkgCntr',
    header: { text: app.$t('Booking') + '\n' + app.$t('Container') }, // Booking Container
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const html = []

        // SR 1247643 번 요청사항 - 부킹 컨테이너 정보는 부킹 항목에 노출 요건 적용
        const cntrQtyBk = vmAppWpx.gridList[idx].cntrQtyBk
        const dgCertiCnt = vmAppWpx.gridList[idx].dgCertiCnt
        const hzYn = vmAppWpx.gridList[idx].hzYn

        let dgCertiCntTxt

        if (dgCertiCnt > -1) {
          dgCertiCntTxt = `${cntrQtyBk.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">DG Certi. (${dgCertiCnt})</span>`
        } else if (hzYn === 'Y') {
          dgCertiCntTxt = `${cntrQtyBk.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">HZ</span>`
        } else {
          dgCertiCntTxt = cntrQtyBk.split(',').join('<br/>')
        }

        html.push(`${dgCertiCntTxt}`)

        return html.join('<br/>')
      }
    }
  },
  {
    name: 'blNo',
    fieldName: 'blNoOrSrRno',
    header: { text: app.$t('msg.ONEX010T010.046') + '\n(' + app.$t('msg.ONEX010T010.093') + ')' }, // B/L No.\n(Status)
    width: '80',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const srCnclYn = vmAppWpx.gridList[idx].srCnclYn || ''
        const blCnclYn = vmAppWpx.gridList[idx].blCnclYn || ''
        const blStsCd = vmAppWpx.gridList[idx].blStsCd || ''
        const caStsCd = vmAppWpx.gridList[idx].caStsCd || ''
        const reqDtm = vmAppWpx.gridList[idx].reqDtm || ''
        const blChkYn = vmAppWpx.gridList[idx].blChkYn || ''
        const usrInfo = vmAppWpx.gridList[idx].usrInfo || ''
        const srCmplsSbmtYn = vmAppWpx.gridList[idx].srCmplsSbmtYn || ''
        const srRspsRmk = vmAppWpx.gridList[idx].srRspsRmk || ''
        const temcount = vmAppWpx.gridList[idx].temcount || ''
        const srYn = vmAppWpx.gridList[idx].srYn || ''
        const id = bkgNo + '_' + blNo + '_' + srRno
        const srEdi = vmAppWpx.gridList[idx].srEdi || ''
        const caAcceptYn = vmAppWpx.gridList[idx].caAcceptYn || ''
        const recentCaStsNm = vmAppWpx.gridList[idx].recentCaStsNm || ''
        const recentCaStsCd = vmAppWpx.gridList[idx].recentCaStsCd || ''
        const recentCaApvYn = vmAppWpx.gridList[idx].recentCaApvYn || ''

        // 부킹 승인 전 또는 S/R Create 전 일 때 (공란)
        if (blNo === '' && srRno === '') {
          return ''
        }

        const html = []
        if (srRno !== '' && blNo === '') {
          if (blCnclYn !== 'Y') { // BL_NO가 없는데 BL이 취소된 경우는 화면에 표시하지 않는다
            html.push(`<a href="#" onclick="vmAppWpx.goDetailPageAtGrid(event, 'SR_EDIT', ${idx})">${srRno}</a>`)
            if (srCnclYn === 'Y') {
              html.push(`<span class="label2 gray">${app.$t('msg.CSBK100.024')}</span>`) // S/R Cancel
            } else {
              if (srYn === 'N') {
                html.push(`<span class="label2 red">${app.$t('msg.CSBK100.407')}</span>`) // 미제출 - S/R Create
              } else if (srEdi === 'Y' && vmAppWpx.memberDetail.userCtrCd === 'KR') {
                  html.push(`<span class="label2 blue">${app.$t('msg.ONEX060P011_SR.001')}</span>`) // S/R 제출 - S/R Create
              } else if (blChkYn === 'A' || blChkYn === 'Y') {
                let msg = '- S/R Requested'
                let stsMsg = vmAppWpx.memberDetail.userCtrCd === 'CN' ? 'S/R ' + app.$t('msg.CSBK130.004') : app.$t('msg.CSBK100.022')
                const htmlTag = `<span class="label2 red" id="blst_btn_${id}" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'blst')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blst')">${stsMsg}</span><div id="blst_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blst')" class="tooltip_wrap position_absolute blst_tt" style="width:380px; display:none; left:0; top:24px;"><div class="cont text_left"><class="title text_left">${app.$t('msg.ONEX010T010.068')}<br/><ul class="bul_list_sm t2"><li class="text_left tt_cont"><a href="#" onclick="vmAppWpx.fnBlErrChk(event, '${bkgNo}', '${srRno}', '${blNo}')">${msg}</a></li></ul></div></div>`
                html.push(htmlTag) // 미승인 - S/R Create
              } else {
                  html.push(`<span class="label2 red">${app.$t('msg.CSBK100.022')}</span>`) // 미승인 - S/R Create
              }
            }
          }
        } else {
          //console.log('blStsCd', blStsCd)
          html.push(`<a href="#" onclick="vmAppWpx.goDetailPageBlAtGrid(event, 'BL_EDIT', ${idx})">${blNo}</a>`)
          // 03: Accepted, 02: Requested, 07: Pending
          if (blCnclYn === 'Y') {
            html.push(`<span class="label2 gray">${app.$t('msg.CSBK100.024')}</span>`) // 취소
          } else if (caStsCd !== '' && caStsCd !== '0' && caStsCd !== '4') {
            let onover = `vmAppWpx.showTooltip(event, '${id}', 'blca')`
            const arrMsg = []
            if (srCmplsSbmtYn === 'Y' && blStsCd === '02') {
              arrMsg.push(`${app.$t('msg.ONEX010T010.139')} : Forced Submission`) // WEB S/R
            } else {
              if (blStsCd === '02') {
                arrMsg.push(`${app.$t('msg.ONEX010T010.139')} : Requested`) // WEB S/R
              } else if (blStsCd === '03') {
                arrMsg.push(`${app.$t('msg.ONEX010T010.139')} : Accepted`) // WEB S/R
              } else if (blStsCd === '07') {
                arrMsg.push(`${app.$t('msg.ONEX010T010.139')} : Pending`) // WEB S/R
              } else {
                arrMsg.push(`${app.$t('msg.ONEX010T010.139')} : Requested`) // WEB S/R
              }
            }

            // CA 상태가 안맞는다면 언어 팩부터 확인
            // as is 로직이 언어에 따라 상태 노출하는 문구가 다름
            // ko-KR
            // "CA_STS_CD_1": "C/A 송부 요망 양하지 패널티 발생 가능, 발생 시 Email 안내 예정",
            // "CA_STS_CD_2": "수정중",
            // "CA_STS_CD_3": "검토요망",
            // "CA_STS_CD_4": "승인완료",
            // "CA_STS_CD_8": "임시 저장중<br/>(최종적으로 C/A Submit 원하시는 경우,<br/>B/L No클릭 -> C/A Preview -><br/>C/A Submit 해주시기 바랍니다.)",
            // "CA_STS_CD_9": "수정중"
            // 나머지
            // "CA_STS_CD_1": "E-mail with LOI required",
            // "CA_STS_CD_2": "checking",
            // "CA_STS_CD_3": "Not accepted",
            // "CA_STS_CD_4": "Accepted",
            // "CA_STS_CD_8": "Temporarily saving<br/>(If you want to submit C/A finally, please click <br/>B/L No -> C/A Preview -><br/>C/A Submit.)",
            // "CA_STS_CD_9": "editing"
            let newCaStsCd = caStsCd
            if (caStsCd === '8' && caAcceptYn === 'Y') {
              newCaStsCd = '10'
            }
            arrMsg.push(`${app.$t('msg.ONEX010T010.140')} : ${app.$t('msg.ONEX010T010.CA_STS_CD_' + newCaStsCd)}`) // C/A 상태
            if (caStsCd === '1' || caStsCd === '3') {
              onover = `vmAppWpx.showTooltipCaSts(event, '${id}', 'blca', '${blNo}', '${reqDtm}', '${caStsCd}', '${usrInfo}')`
            }

            const arrBtn = []
            if (temcount !== '0') {
              arrBtn.push(`<li class="text_left"><button type="button" class="button sm request" onclick="vmAppWpx.caRequestPopCall('${blNo}', '${caAcceptYn}')">${app.$t('msg.CMBA380.CA01')} ${newCaStsCd !== '10' ? app.$t('msg.MYIN040G010.029') : ''}</button></li>`)
            }
            let caTxt = `<span class="label2 blue" id="blca_btn_${id}" onmouseover="${onover}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blca')">${app.$t('msg.ONEX010T010.070')}</span><br/>` // C/A 확인
            if (caStsCd === '3') { // Reject시만 표현
              caTxt = `<span class="label2 red" id="blca_btn_${id}" onmouseover="${onover}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blca')">${app.$t('msg.ONEX010T010.156')}</span><br/>` // C/A 거절
            }

            if (!vmAppWpx.$ekmtcCommon.isEmpty(recentCaStsNm) && vmAppWpx.memberDetail.userCtrCd === 'CN') {
              if (caStsCd === '1' || caStsCd === '3') {
                onover = `vmAppWpx.showTooltipCaSts2(event, '${id}', 'blca', '${blNo}', '${reqDtm}', '${caStsCd}', '${usrInfo}', '${recentCaStsNm}')`
              }
              caTxt = `<span class="label2 blue" id="blca_btn_${id}" onmouseover="${onover}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blca')">C/A ${recentCaStsNm}</span><br/>` // C/A 확인
            }

            const htmlTag = `${caTxt}<div id="blca_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blca')"
            class="tooltip_wrap position_absolute blca_tt"
              style="width:380px; display:none; left:0; top:12px;">
              <div class="cont text_left"><ul class="bul_list_sm t2"><li class="text_left tt_cont">
              ${arrMsg.join('<br/>')}</li>${arrBtn.join('')}
              </ul>
              </div>
              </div>`
            html.push(htmlTag) // C/A 확인
          // } else if (blStsCd === '07') {
          //   html.push(`<span class="label2 red">${app.$t('msg.CSBK100.022')}</span>`) // 미승인
          } else if (blStsCd === '03') {
            html.push(`<span class="label2 green">${app.$t('msg.CSBK100.023')}</span>`) // 승인
          } else if (vmAppWpx.memberDetail.userCtrCd === 'KR' && srEdi === 'Y' && caStsCd === '0') {
               html.push(`<span class="label2 red">${app.$t('msg.CSBK100.022')}</span>`) // 미승인 // SR EDI 로 들어온 경우
          } else if (blStsCd === '') {
             if (vmAppWpx.memberDetail.userCtrCd === 'CN') {
               if (caStsCd === '4') {
                 html.push(`<span class="label2 green">${app.$t('msg.CSBK100.023')}</span>`) // 승인
               } else {
                 html.push('<span class="label2 red">B/L Created</span>') // 미승인 - S/R Create
               }
             } else if (vmAppWpx.memberDetail.userCtrCd === 'KR' && srEdi === 'Y' && caStsCd === '0') {
               html.push(`<span class="label2 red">${app.$t('msg.CSBK100.022')}</span>`) // 미승인 // SR EDI 로 들어온 경우
             } else {
               html.push(`<span class="label2 red">${app.$t('msg.CSBK100.407')}</span>`) // 미제출 - S/R Create
             }
          } else {
            let msg = ''
            if (blStsCd === '02') {
              msg = '- S/R Requested'
            } else if (blStsCd === '04') {
              msg = '- S/R Rejected'
            } else if (blStsCd === '07') {
              msg = '- S/R Pending'
            }
            if (blStsCd === '04' || blStsCd === '07') {
              msg = msg + '<br/>- ' + srRspsRmk
            }
            const htmlTag = `<span class="label2 red" id="blst_btn_${id}" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'blst')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blst')">${app.$t('msg.CSBK100.022')}</span><br/><div id="blst_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'blst')" class="tooltip_wrap position_absolute blst_tt" style="width:400px; display:none; left:0; top:24px;"><div class="cont text_left"><class="title text_left">${app.$t('msg.ONEX010T010.068')}<ul class="bul_list_sm t2"><br/><li class="text_left tt_cont"><a href="#" onclick="vmAppWpx.fnBlErrChk(event, '${bkgNo}', '${srRno}', '${blNo}')">${msg}</a></li></ul></div></div>`
            html.push(htmlTag) // 미승인
          }
        }
        return html.join('<br/>')
      }
    }
  },
  {
    name: 'cntrQty',
    fieldName: 'cntrQty',
    header: { text: 'BL\n' + app.$t('msg.ONEX010T010.052') }, // BL Container
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const cntrQty = grid.getValue(idx, 'cntrQty')
        // const dgCertiCnt = grid.getValue(idx, 'dgCertiCnt')
        // const hzYn = grid.getValue(idx, 'hzYn')
        const cntrQty = vmAppWpx.gridList[idx].cntrQty
        const dgCertiCnt = vmAppWpx.gridList[idx].dgCertiCnt
        const hzYn = vmAppWpx.gridList[idx].hzYn
        const iotCntrCnt = vmAppWpx.gridList[idx].iotCntrCnt || ''
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const blNoOrSrRno = vmAppWpx.gridList[idx].srRno || ''

        let iotBtn
        let dgCertiCntTxt

        // console.log('iotCntrCnt @@@@ ', iotCntrCnt)

        if (blNo !== null && blNo !== '' && iotCntrCnt > 0) {
          iotBtn = `<br/><a class="button sm" onclick="vmAppWpx.openRfDataInfoPop('${blNo}')">IoT</a>`
        } else {
          iotBtn = ''
        }

        if (dgCertiCnt > -1) {
          dgCertiCntTxt = `${cntrQty.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">DG Certi. (${dgCertiCnt})</span>`
        } else if (hzYn === 'Y') {
          dgCertiCntTxt = `${cntrQty.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">HZ</span>`
        } else {
          dgCertiCntTxt = cntrQty.split(',').join('<br/>')
        }

        // SR 1247643 번 요청사항 - 컨테이너 정보가 BL, SR인 경우 BL 컨테이너 수량 노출하고 부킹(BK) 컨테이너 정보는 부킹번호 항목에 노출.
        if (vmAppWpx.gridList[idx].cntrInfoSearchType !== 'BK') {
          return dgCertiCntTxt + iotBtn
        } else {
          return iotBtn
        }
      }
    }
  },
  {
    name: 'request',
    fieldName: 'reqCatCd',
    header: {
      // text: app.$t('msg.ONEX050T010.019') + '\n(' + app.$t('msg.ONEX010T010.157') + ')',
      template: `<span>${app.$t('msg.ONEX050T010.019')}<br>(${app.$t('msg.ONEX010T010.157')})</span>`,
      styleName: 'multiline-editor'
    }, // Request(보완요청)
    editable: false,
    sortable: true,
    editor: { type: 'multiline' },
    styleName: 'multiline-editor',
    width: '70',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqCnt = vmAppWpx.gridList[idx].reqCnt
        const etcCnt = vmAppWpx.gridList[idx].etcCnt
        const blNo = vmAppWpx.gridList[idx].blNo
        const bkgNo = vmAppWpx.gridList[idx].bkgNo

        const id = blNo + '_' + bkgNo + '_' + idx
        let cont
        let htmlTag
        let tooltipHtmlTag = ''

        let fontColor
        let display
        const html = []

        // Request중 ETC 항목만
        if (etcCnt > 0) {
          if (reqCnt === '0') { // 승인
            cont = app.$t('msg.MYIN040G010.007')
            fontColor = 'green'
          } else { // 전부 request
            cont = app.$t('msg.ONEX010T010.157')
            fontColor = 'red'
          }

          htmlTag = `<span class="label2 ${fontColor}" id="etcReq_btn_${id}"
                           onmouseover="vmAppWpx.showTooltipEtcReq(event, '${id}', 'etcReq', '${idx}')"
                           onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'etcReq')">${cont}
                      </span><div id="etcReq_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'etcReq')"
                            class="tooltip_wrap position_absolute etcReq_tt"
                            style="width:310px; display:none; left:80px; top:24px;">
                              <div class="cont text_left"><class="title text_left">
                                  <ul class="bul_list_sm t2">
                                  <li class="text_left tt_cont">

                                  </li>
                                  </ul>
                              </div>
                          </div>`

          html.push(htmlTag)
        } else {
          html.push(htmlTag)
        }

        return html.join('<br/>')
      }
    }
  },
  {
    name: 'statusCd',
    fieldName: 'statusCd',
    header: { text: app.$t('msg.ONEX010T010.047') }, // 선적확정
    width: '60',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const shprRspsYn = grid.getValue(idx, 'shprRspsYn')
        // const shprRspsBtnYn = grid.getValue(idx, 'shprRspsBtnYn')
        // const mtyPkupUsagYn = grid.getValue(idx, 'mtyPkupUsagYn')
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const shprRspsYn = vmAppWpx.gridList[idx].shprRspsYn
        const shprRspsBtnYn = vmAppWpx.gridList[idx].shprRspsBtnYn
        const mtyPkupUsagYn = vmAppWpx.gridList[idx].mtyPkupUsagYn
        const bkgStsCd = vmAppWpx.gridList[idx].bkgStsCd
        //미승인일 경우
        if (bkgStsCd === '02' || bkgStsCd === '05') {
          return ''
        }

        if (mtyPkupUsagYn === 'Y') {
          return `<span class="color_red">${app.$t('msg.ONEX010T010.130')}</span>` // 픽업부킹
        }

        if (shprRspsYn === 'Y') {
          return app.$t('msg.ONEX010T010.131') // '완료'
        } else if (shprRspsBtnYn === 'E') {
          return app.$t('msg.ONEX010T010.131') // '완료'
        } else if (shprRspsBtnYn === 'Y') {
          return `<span class="yes"><a class="button sm" href="#" onclick="vmAppWpx.updateShprRspsYn(event, '${bkgNo}');">Yes</a></span>`
        } else {
          return ''
        }
      }
    }
  },
  { name: 'actShprCstEnm', fieldName: 'actShprCstEnm', header: { text: app.$t('msg.ONEX010T010.048') }, width: '100', editable: false }, // 실화주
  {
    name: 'polPortCd',
    fieldName: 'etd',
    header: { text: app.$t('msg.ONEX010T010.049') }, // 출발지 ETD
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const por = grid.getValue(idx, 'por') || ''
        // const etd = grid.getValue(idx, 'etd') || ''
        // const diff = grid.getValue(idx, 'etdDiff')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        const por = vmAppWpx.gridList[idx].por || ''
        const etd = vmAppWpx.gridList[idx].etd || ''
        const diff = vmAppWpx.gridList[idx].etdDiff
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const mtyPkupUsagYn = vmAppWpx.gridList[idx].mtyPkupUsagYn || ''
        const etdText = convertDate(etd)

        if (mtyPkupUsagYn !== 'Y') {
          if (diff !== undefined) {
            if (diff !== '0' && diff.indexOf('-') === -1) {
              return `<a href="#" onclick="vmAppWpx.porDlySchedulePopCall(event, '${bkgNo}')">${por} <br><span class="font_11">${etdText} (+${diff})</span></a>`
            } else {
              return `<a href="#" onclick="vmAppWpx.porDlySchedulePopCall(event, '${bkgNo}')">${por} <br><span class="font_11">${etdText} (${diff})</span></a>`
            }
          } else {
            return `<a href="#" onclick="vmAppWpx.porDlySchedulePopCall(event, '${bkgNo}')">${por} <br><span class="font_11">${etdText} </span><a/>`
          }
        } else {
          return ''
        }
      }
    }
  },
  {
    name: 'tsPortCd',
    fieldName: 'tsPortCd',
    header: { text: 'T/S' },
    width: '50',
    editable: false,
    visible: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const html = []
        const idx = cell.index.dataRow
        const ts = vmAppWpx.gridList[idx].tsPortCd || ''
        const tsPlc = vmAppWpx.gridList[idx].tsPortNm || ''
        const tsCtr = vmAppWpx.gridList[idx].tsCtrCd || ''
        const tsTxt = tsPlc + ', ' + tsCtr
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const id = bkgNo + '_' + blNo + '_' + srRno
        const htmlTag = `<span class="label2 gray" id="ts_btn_${id}" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'ts')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'ts')">${ts}</span><div id="ts_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'ts')" class="tooltip_wrap position_absolute ts_tt" style="width:280px; display:none; left:0; top:24px;"><div class="cont text_left"><class="title text_left">${tsTxt}</div></div>`
        html.push(htmlTag)
        return html.join('<br/>')
        //return ts
      }
    }
  },
  {
    name: 'podPortCd',
    fieldName: 'eta',
    header: { text: app.$t('msg.ONEX010T010.050') }, // 도착지 ETA
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const dly = grid.getValue(idx, 'dly') || ''
        // const eta = grid.getValue(idx, 'eta') || ''
        // const diff = grid.getValue(idx, 'etaDiff')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        const dly = vmAppWpx.gridList[idx].dly || ''
        const eta = vmAppWpx.gridList[idx].eta || ''
        const diff = vmAppWpx.gridList[idx].etaDiff
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const etaText = convertDate(eta)
        const mtyPkupUsagYn = vmAppWpx.gridList[idx].mtyPkupUsagYn || ''

        if (mtyPkupUsagYn !== 'Y') {
          if (diff !== undefined) {
            if (diff !== '0' && diff.indexOf('-') === -1) {
              return `<a href="#" onclick="vmAppWpx.porDlySchedulePopCall(event, '${bkgNo}')">${dly} <br><span class="font_11">${etaText} (+${diff})</span></a>`
            } else {
              return `<a href="#" onclick="vmAppWpx.porDlySchedulePopCall(event, '${bkgNo}')">${dly} <br><span class="font_11">${etaText} (${diff})</span></a>`
            }
          } else {
            return `<a href="#" onclick="vmAppWpx.porDlySchedulePopCall(event, '${bkgNo}')">${dly}</a>`
          }
        } else {
          return ''
        }
      }
    }
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    header: { text: app.$t('msg.ONEX010T010.051') }, // VSL/VOY
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const srRno = grid.getValue(idx, 'srRno') || ''
        // const blNo = grid.getValue(idx, 'blNo') || ''
        // const vslNm = grid.getValue(idx, 'vslNm') || ''
        // const voyNo = grid.getValue(idx, 'voyNo') || ''
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const vslNm = vmAppWpx.gridList[idx].vslNm || ''
        const voyNo = vmAppWpx.gridList[idx].voyNo || ''

        return `<a style='cursor:pointer' onclick="vmAppWpx.vesselScheduleInfoPopCall(event, '${bkgNo}', '${srRno}', '${blNo}')">${vslNm}<br/>${voyNo}</a>`
      }
    }
  },
  {
    name: 'tel',
    fieldName: 'tel',
    header: { text: app.$t('msg.ONEX010T010.053') }, // 담당자 연락처
    width: '50',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const srRno = grid.getValue(idx, 'srRno') || ''
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const anYn = vmAppWpx.gridList[idx].anYn
        const podCtrCd = vmAppWpx.gridList[idx].podCtrCd
        const rpaCfmYn = vmAppWpx.gridList[idx].rpaCfmYn

        return `<button type="button" class="tbl_icon tel" onclick="vmAppWpx.exportManagerPopCall('${bkgNo}', '${srRno}', '${blNo}', '${anYn}', '${podCtrCd}', '${rpaCfmYn}');">연락처</button>`
      }
    }
  },
  {
    name: 'spcShiprYn',
    fieldName: 'spcShiprYn',
    header: { text: app.$t('msg.ONEX010T010.158') }, // 디지털 세일즈 부킹여부
    width: '40',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const polPlcCd = vmAppWpx.gridList[idx].polPortCd
        const polCtrCd = vmAppWpx.gridList[idx].polCtrCd
        const podPlcCd = vmAppWpx.gridList[idx].podPortCd
        const podCtrCd = vmAppWpx.gridList[idx].podCtrCd
        const frtAppNo = vmAppWpx.gridList[idx].frtAppNo
        const polPortNm = vmAppWpx.gridList[idx].por.split(',')[0].trim()
        const podPortNm = vmAppWpx.gridList[idx].dly.split(',')[0].trim()
        const etd = vmAppWpx.gridList[idx].etd.substring(0, 8)
        const eta = vmAppWpx.gridList[idx].eta.substring(0, 8)
        const bkgNo = vmAppWpx.gridList[idx].bkgNo

        if (vmAppWpx.gridList[idx].spcShiprYn !== 'Y') {
          vmAppWpx.gridList[idx].spcShiprYn = ''
        }

        const spcShiprYn = vmAppWpx.gridList[idx].spcShiprYn
        return `<a style='cursor:pointer' onclick="vmAppWpx.freSurchargePopCall(event, '${polPlcCd}', '${polCtrCd}', '${podPlcCd}', '${podCtrCd}', '${frtAppNo}','${polPortNm}', '${podPortNm}', '${etd}', '${eta}');">${spcShiprYn}</a>`

        // const spcShiprYn = vmAppWpx.gridList[idx].spcShiprYn
        // return spcShiprYn
      }
    }
  }
]

// 서류 토글 선택 컬럼
const defCol2 = []

const columnDoc = [
  {
    name: 'docs00',
    fieldName: 'docs00',
    header: { text: app.$t('VGM') }, // VGM
    width: '50',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const vgmStatus = vmAppWpx.gridList[idx].vgmStatus
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const id = bkgNo + '_' + blNo
        const ctrCd = vmAppWpx.memberDetail.userCtrCd
        const bkgAcpnMethCd = vmAppWpx.gridList[idx].bkgAcpnMethCd
        let srYn = vmAppWpx.gridList[idx].srYn

        if (vgmStatus === 'Y') {
          const arrTooltip = []
          // return '<button type="button" class="status_icon finish">' + app.$t('msg.ONEX010T010.131') + '</button>'

          // VGM 팝업창
          arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs00(event, '${bkgNo}', '${bkgAcpnMethCd}', '${blNo}', 'VGMPOP_OPEN')">Input VGM</a>`)

          return `<button type="button" id="docs00_btn_${id}" class="status_icon finish" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'docs00')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs00')">${app.$t('msg.ONEX010T010.124')}</button>
            <div id="docs00_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs00')" class="tooltip_wrap position_absolute" style="width:250px; display:none; left:0; top:24px;">
              <div class="cont text_left"><button type="button" class="close" onclick="vmAppWpx.closeTooltip(event, '${id}', 'docs00')">close<br/></button>
                <p class="title text_left">${app.$t('msg.ONEX010T010.131')}</p><br/>
                <ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}
                  </li>
                  </ul>
                </div></div>`
        } else {
          const arrTooltip = []
          if (vgmStatus !== 'Y') {
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs00(event, '${bkgNo}', '${bkgAcpnMethCd}', '${blNo}', 'VGMPOP_OPEN')">${app.$t('msg.ONEX010T010.135')}</a>`)
          }

          if (srYn === 'Y') {
            return `<button type="button" id="docs00_btn_${id}" class="status_icon uncom" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'docs00')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs00')">${app.$t('msg.ONEX010T010.124')}</button>
            <div id="docs00_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs00')" class="tooltip_wrap position_absolute" style="width:250px; display:none; left:0; top:24px;">
              <div class="cont text_left"><button type="button" class="close" onclick="vmAppWpx.closeTooltip(event, '${id}', 'docs00')">close<br/></button>
                <p class="title text_left">${app.$t('msg.ONEX010T010.153')}</p><br/>
                <ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}
                  </li>
                  </ul>
                </div></div>`
          } else {
            return `<button type="button" id="docs00_btn_${id}" class="status_icon uncom"></button>`
          }
        }
      }
    }
  },
  {
      name: 'docs01',
      fieldName: 'docs01',
      header: { text: app.$t('msg.ONEX010T010.056') }, // 서류마감
      width: '60',
      editable: false,
      sortable: false,
      renderer: {
        type: 'html',
        callback (grid, cell, w, h) {
          const idx = cell.index.dataRow
          // const srRno = grid.getValue(idx, 'srRno') || ''
          // const srYn = grid.getValue(idx, 'srYn')
          // const docDtm = grid.getValue(idx, 'docDtm')
          // const cntrCntChk = grid.getValue(idx, 'cntrCntChk')
          // const vgmStatus = grid.getValue(idx, 'vgmStatus')
          // const elYn = grid.getValue(idx, 'elYn')
          // const bkgNo = grid.getValue(idx, 'bkgNo')
          // const blNo = grid.getValue(idx, 'blNo')
          const srRno = vmAppWpx.gridList[idx].srRno || ''
          const docDtm = vmAppWpx.gridList[idx].docDtm
          const cntrCntChk = vmAppWpx.gridList[idx].cntrCntChk
          const vgmStatus = vmAppWpx.gridList[idx].vgmStatus
          const elYn = vmAppWpx.gridList[idx].elYn
          const bkgNo = vmAppWpx.gridList[idx].bkgNo
          const blNo = vmAppWpx.gridList[idx].blNo || ''
          const docDtmText = convertDate(docDtm)
          const id = bkgNo + '_' + blNo
          const ctrCd = vmAppWpx.memberDetail.userCtrCd
          let srYn = vmAppWpx.gridList[idx].srYn
          let docDtmTextVal = ''

          // srRno가 없을때, BL NO. 체크해서 있으면, srRno가 있는걸로 로직체크
          if (srRno === '' && blNo === '') { // BL NO. 가 없을때만, 마감시간 표시
              return `<span class="font_11">${docDtmText}</span>`
          }

          // CN 일때는, S/R 제출 여부 건너뜀
          if (vmAppWpx.memberDetail.userCtrCd === 'CN') {
            srYn = 'Y'
          }

          if (srYn === 'Y' && cntrCntChk === 'YY' && ((ctrCd !== 'KR' && ctrCd !== 'ID') || (elYn === 'Y' && (ctrCd === 'KR' || ctrCd === 'ID')))) {
            return '<button type="button" class="status_icon finish">' + app.$t('msg.ONEX010T010.131') + '</button>'
          } else {
            const arrTooltip = []
            if (srYn !== 'Y' && vmAppWpx.memberDetail.userCtrCd !== 'CN') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs01(event, '${bkgNo}', '${srRno}', '${blNo}', 'SR_SUBMIT')">${app.$t('msg.ONEX010T010.132')}</a>`)
            }
            if (cntrCntChk !== 'YY') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs01(event, '${bkgNo}', '${srRno}', '${blNo}', 'CNTR_SUBMIT')">${app.$t('msg.ONEX010T010.133')}</a>`)
            }
            if (elYn !== 'Y' && (ctrCd === 'KR' || ctrCd === 'ID')) {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs01(event, '${bkgNo}', '${srRno}', '${blNo}', 'EL_SUBMIT')">${app.$t('msg.ONEX010T010.134')}</a>`)
            }
            // if (vgmStatus !== 'Y') {
            //   arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs01(event, '${bkgNo}', '${srRno}', '${blNo}', 'VGM_SUBMIT')">${app.$t('msg.ONEX010T010.135')}</a>`)
            // }

            // 서류마감일 없을때 아이콘 위치 변경
            if (docDtmText === '' || docDtmText === undefined) {
              docDtmTextVal = ''
            } else {
              docDtmTextVal = `<br/><span class="font_11">${docDtmText}</span><br/>`
            }

            return `<button type="button" id="docs01_btn_${id}"
            class="status_icon uncom"
            onmouseover="vmAppWpx.showTooltip(event, '${id}', 'docs01')"
            onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs01')">${app.$t('msg.ONEX010T010.124')}</button>
            ${docDtmTextVal}
            <div id="docs01_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs01')" class="tooltip_wrap position_absolute" style="width:250px; display:none; left:0; top:24px;">
              <div class="cont text_left"><button type="button" class="close" onclick="vmAppWpx.closeTooltip(event, '${id}', 'docs01')">close<br/></button>
                <p class="title text_left">${app.$t('msg.ONEX010T010.153')}</p><br/>
                <ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}
                  </li>
                  </ul>
                </div></div>`
          }
        }
      }
  },
  {
    name: 'docs02',
    fieldName: 'docs02',
    header: { text: app.$t('msg.ONEX010T010.057') }, // 운임
    width: '50',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const frtYn = grid.getValue(idx, 'frtYn')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        // const srRno = grid.getValue(idx, 'srRno') || ''
        const frtYn = vmAppWpx.gridList[idx].frtYn
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const frtRatesChkYn = vmAppWpx.gridList[idx].frtRatesChkYn || ''
        const id = bkgNo + '_' + blNo

        // S/R Create 전
        if (srRno === '' && blNo === '') {
          return ''
        }

        const cssClasss = frtYn === 'Y' ? 'finish' : 'uncom'
        const linkClass = app.$ekmtcCommon.isNotEmpty(blNo) ? 'link' : ''
        const btnText = frtYn === 'Y' ? app.$t('msg.ONEX010T010.131') : app.$t('msg.ONEX010T010.124')
        let title = ''

        if (frtYn === 'Y') {
          title = app.$t('msg.ONEX010T010.131') // 완료
          return `<button type="button" id="docs02_btn_${id}" class="status_icon ${cssClasss}">${btnText}</button><br/><div id="docs02_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs02')" class="tooltip_wrap position_absolute docs02_tt" style="width:250px; display:none; left:0; top:24px;"><div class="cont text_left"><p class="title text_left">${title}</p><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
        } else {
          const arrTooltip = []

          if (frtYn === 'N') {
            title = app.$t('msg.ONEX010T010.124') // '미완료'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs02(event, '${bkgNo}', '${srRno}', '${blNo}', 'FRT_POPUP')">${app.$t('msg.ONEX010T010.122')}</a>`) // 운임 미정산
          } else if (frtYn === 'E') {
            title = app.$t('msg.ONEX010T010.124') // '미완료'
            if (['KR', 'JP'].indexOf(vmAppWpx.memberDetail.userCtrCd) === -1) {
              arrTooltip.push(`${app.$t('msg.ONEX010T010.123')}`) // 운임 미등록
            } else {
              if (frtRatesChkYn === 'Y') {
                title = `<p style="text-align:center;">${app.$t('msg.DOIssue.003').replaceAll('\n', '<br>')}</p>`
              } else {
                arrTooltip.push(`<a href="#" class="${linkClass}" onclick="vmAppWpx.fnClickDocs02(event, '${bkgNo}', '${srRno}', '${blNo}', 'FRT_REQUEST')">${app.$t('msg.ONEX010T010.123')}</a>`) // 운임 미등록
              }
            }
          }
          return `<button type="button" id="docs02_btn_${id}" class="status_icon ${cssClasss}" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'docs02')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs02')">${btnText}</button><br/><div id="docs02_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs02')" class="tooltip_wrap position_absolute" style="width:250px; display:none; left:0; top:24px;"><div class="cont text_left"><button type="button" class="close" onclick="vmAppWpx.closeTooltip(event, '${id}', 'docs02')">close</button><br/><class="title text_left">${title}<br/><ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}</li></ul></div></div>`
        }
      }
    }
  },
  {
    name: 'docs03',
    fieldName: 'docs03',
    header: {
      text: app.$t('msg.ONEX010T010.058')// 발행
    },
    width: '50',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const issueStatus = vmAppWpx.gridList[idx].issueStatus || ''
        const issueDate = vmAppWpx.gridList[idx].issueDate || ''
        const issueReason = vmAppWpx.gridList[idx].issueReason || ''
        const cntrStsCd = vmAppWpx.gridList[idx].cntrStsCd || ''
        const cntrCfsYn = vmAppWpx.gridList[idx].cntrCfsYn || ''
        const blCatCd = vmAppWpx.gridList[idx].blCatCd || ''
        const surrYn = vmAppWpx.gridList[idx].surrYn || ''
        const wbilCfmYn = vmAppWpx.gridList[idx].wbilCfmYn || ''
        const frtYn = vmAppWpx.gridList[idx].frtYn || ''
        const maxRejectCont = vmAppWpx.gridList[idx].maxRejectCont || ''
        const onlyActYn = vmAppWpx.gridList[idx].onlyActYn
        const rejectNm = vmAppWpx.gridList[idx]?.rejectNm ?? ''
        const rejectTel = vmAppWpx.gridList[idx]?.rejectTel ?? ''

        const id = bkgNo + '_' + blNo
        let issueElYn = vmAppWpx.gridList[idx].issueElYn || ''
        let issueCertiDt = vmAppWpx.gridList[idx].issueCertiDt || ''
        let certi = ''

        if (issueElYn === 'N') {
          if (blCatCd === '01' && surrYn === 'Y') {
            issueElYn = 'Y'
          } else if (blCatCd === '02' && wbilCfmYn === 'Y') {
            issueElYn = 'Y'
          }
        }

        if (srRno === '' && blNo === '') {
          return '' // S/R Create 전
        }

        const html = []
        const arrTooltip = []
        let cssClass = ''
        let btnText = ''
        let tooltipTitle = ''
        let isTooltipLayer = true

        // if (issueKind === 'Surrender') {
        //   cssClass = 'srurr'
        //   btnText = 'Surrender B/L'

        //   tooltipTitle = app.$t('msg.ONEX010T010.095') // Surrender B/L 발행
        //   arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
        // } else if (issueKind === 'Waywill') {
        //   cssClass = 'seaway'
        //   btnText = 'Sea Waybill'

        //   tooltipTitle = app.$t('msg.ONEX010T010.096') // 'S/Waybill 발행'
        //   arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
        // } else if (issueKind === 'OBL') {
        //   cssClass = 'origin'
        //   btnText = 'Original B/L'

        //   tooltipTitle = app.$t('msg.ONEX010T010.094') // 'Original B/L 발행'
        //   arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
        //   arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'OBL_RE')">${app.$t('msg.ONEX010T010.114')}</a>`) // O.B/L 재발행
        // } else {
          if (issueStatus === '11') {
            // O.B/L 재발행
            cssClass = 'origin'
            btnText = 'Original B/L'

            tooltipTitle = app.$t('msg.ONEX010T010.094') // 'Original B/L 발행'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
            if (onlyActYn !== 'Y') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'OBL_RE')">${app.$t('msg.ONEX010T010.114')}</a>`) // O.B/L 재발행
            }
            if (vmAppWpx.memberDetail.userCtrCd === 'ID') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'SUR_TEL', '${issueStatus}')">Surrender/telex Request</a>`) // Surrender/telex Request
            }
          } else if (issueStatus === '01') {
            // O.B/L 발행
            cssClass = 'origin'
            btnText = 'Original B/L'

            tooltipTitle = app.$t('msg.ONEX010T010.094') // 'Original B/L 발행'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
            if (onlyActYn !== 'Y') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'OBL_RE')">${app.$t('msg.ONEX010T010.114')}</a>`) // O.B/L 재발행
            }
            if (vmAppWpx.memberDetail.userCtrCd === 'ID') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'SUR_TEL', '${issueStatus}')">Surrender/telex Request</a>`) // Surrender/telex Request
            }
          } else if (issueStatus === '06') {
            // O.B/L 발행중
            cssClass = 'request'
            btnText = 'O.B/L 발행중'

            tooltipTitle = app.$t('msg.ONEX010T010.097') // '발행중'
            arrTooltip.push(app.$t('msg.ONEX010T010.098')) // 'O.B/L 발행중'
          } else if (issueStatus === '16') {
            // O.B/L 승인
            cssClass = 'origin'
            btnText = 'O.B/L 승인'

            tooltipTitle = app.$t('msg.ONEX010T010.094') // 'Original B/L 발행'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
            if (onlyActYn !== 'Y') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'OBL_RE')">${app.$t('msg.ONEX010T010.114')}</a>`) // O.B/L 재발행
            }
            if (vmAppWpx.memberDetail.userCtrCd === 'ID') {
              arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'SUR_TEL', '${issueStatus}')">Surrender/telex Request</a>`) // Surrender/telex Request
            }
            // tooltipTitle = '승인'
            // arrTooltip.push('O.B/L 승인')
          } else if (issueStatus === '02') {
            // Surrender 발행
            cssClass = 'srurr'
            btnText = 'Surrender 발행'

            tooltipTitle = app.$t('msg.ONEX010T010.095') // 'Surrender 발행'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
          } else if (issueStatus === '07') {
            // Surrender 처리중
            cssClass = 'request'
            btnText = 'Surrender 처리중'

            tooltipTitle = app.$t('msg.ONEX010T010.101') // '처리중'
            arrTooltip.push(app.$t('msg.ONEX010T010.103')) // 'Surrender 처리중'
          } else if (issueStatus === '17') {
            // Surrender 승인
            cssClass = 'srurr'
            btnText = 'Surrender 승인'

            tooltipTitle = app.$t('msg.ONEX010T010.095') // 'Surrender 발행'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
            // tooltipTitle = '승인'
            // arrTooltip.push('Surrender 승인')
          } else if (issueStatus === '42') {
            // Surrender 취소
            cssClass = 'uncom'
            btnText = 'Surrender 취소'

            tooltipTitle = app.$t('msg.ONEX010T010.105') // '취소'
            arrTooltip.push('Surrender 취소')
          } else if (issueStatus === '03') {
            // S/Waybill 발행
            cssClass = 'seaway'
            btnText = 'Sea Waybill'

            tooltipTitle = app.$t('msg.ONEX010T010.096') // 'S/Waybill 발행'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
          } else if (issueStatus === '04') {
            // S/Waybill 발행중
            cssClass = 'request'
            btnText = 'S/Waybill 발행중'

            tooltipTitle = app.$t('msg.ONEX010T010.097') // '발행중'
            arrTooltip.push('S/Waybill 발행중')
          } else if (issueStatus === '14') {
            // S/Waybill 승인
            cssClass = 'seaway'
            btnText = 'S/Waybill 승인'

            tooltipTitle = app.$t('msg.ONEX010T010.096') // 'S/Waybill 발행'
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_COPY')">${app.$t('msg.ONEX010T010.113')}</a>`) // B/L Copy
            // tooltipTitle = '승인'
            // arrTooltip.push('S/Waybill 승인')
          } else if (issueStatus === '46') {
            // S/Waybill 취소
            cssClass = 'uncom'
            btnText = 'S/Waybill 취소'

            tooltipTitle = app.$t('msg.ONEX010T010.105') // '취소'
            arrTooltip.push(app.$t('msg.ONEX010T010.108')) // 'S/Waybill 취소'
          } else if (issueStatus === '88') { // 발행거절
            cssClass = 'uncom'
            btnText = '발행거절'
            tooltipTitle = app.$t('msg.CSBL220_STS.088') // '발행거절'

            if (vmAppWpx.memberDetail.userCtrCd === 'ID' || vmAppWpx.memberDetail.userCtrCd === 'IN') {
              if (maxRejectCont !== '') {
                arrTooltip.push(`<br/>[${vmAppWpx.$t('msg.CMBA380.030')}]`)
                arrTooltip.push(`${rejectNm} ${rejectTel === '--' ? '' : rejectTel}`)
                arrTooltip.push('<br/>[Reason]')
                arrTooltip.push(maxRejectCont)
              }
            } else {
              if (issueReason !== '') {
                arrTooltip.push(issueReason)
              }
            }
          } else if (issueStatus === '89') { // Pending
            cssClass = 'uncom'
            btnText = 'Pending'
            tooltipTitle = app.$t('msg.ONEX010T010.110') // 'Pending'
            if (vmAppWpx.memberDetail.userCtrCd === 'IN') {
              if (maxRejectCont !== '') {
                arrTooltip.push(`<br/>[${vmAppWpx.$t('msg.CMBA380.030')}]`)
                arrTooltip.push(`${rejectNm} ${rejectTel === '--' ? '' : rejectTel}`)
                arrTooltip.push('<br/>[Reason]')
                arrTooltip.push(maxRejectCont)
              }
            } else {
              if (issueReason !== '') {
                arrTooltip.push(issueReason)
              }
            }
          } else if (vmAppWpx.memberDetail.userCtrCd !== 'JP' && issueStatus === '99') {
            if (cntrStsCd === 'N' && issueElYn === 'N') {
              cssClass = 'uncom'
              btnText = '요청불가1'
              tooltipTitle = app.$t('msg.ONEX010T010.111') // '요청불가'

              arrTooltip.push(app.$t('art.CSBL220.003')) // 컨테이너 미반입, 면장 미입력
            } else if (issueElYn === 'N') {
              cssClass = 'uncom'
              btnText = '요청불가2'
              tooltipTitle = app.$t('msg.ONEX010T010.111') // '요청불가'

              arrTooltip.push(app.$t('art.CSBL220.001')) // 면장 미입력
            } else if (cntrStsCd === 'N' && cntrCfsYn === 'N') {
              cssClass = 'uncom'
              btnText = '요청불가2'
              tooltipTitle = app.$t('msg.ONEX010T010.111') // 요청불가'

              arrTooltip.push(app.$t('art.CSBL220.002')) // 컨테이너 미반입
            } else {
              // 운임 미등록 상태일 경우
              if (frtYn === 'E') {
                cssClass = 'uncom'
                btnText = '요청불가3'
                tooltipTitle = app.$t('msg.ONEX010T010.111') // 요청불가'

                arrTooltip.push(app.$t('msg.ONEX010T010.123')) // 운임 미등록
              } else {
                cssClass = 'button sm request'
                btnText = app.$t('msg.ONEX010T010.152')
                tooltipTitle = app.$t('msg.ONEX010T010.112') // '미요청'
                isTooltipLayer = false
              }
            }
          }
        // }

        if (cssClass !== '') {
          if (issueCertiDt !== '' && issueCertiDt.length === 8 && (cssClass === 'seaway' || cssClass === 'srurr' || cssClass === 'origin')) {
            certi = 'Onboard<br/>' + issueCertiDt.substring(4, 6) + '/' + issueCertiDt.substring(6, 8)

            arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_CERTI')">Certi.</a>`)
          }

          let htmlTag = ''

          if (!isTooltipLayer) {
            if (cssClass.indexOf('button') !== -1) {
              htmlTag = `<button type="button" id="docs03_btn_${id}" class="${cssClass}" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_ISSUE')">${btnText}</button>`
            } else {
              htmlTag = `<button type="button" id="docs03_btn_${id}" class="status_icon ${cssClass}" onclick="vmAppWpx.fnClickDocs03(event, '${bkgNo}', '${srRno}', '${blNo}', 'BL_ISSUE')">${btnText}</button>`
            }
          } else {
            htmlTag = `<button type="button" id="docs03_btn_${id}" class="status_icon ${cssClass}" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'docs03')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs03')">${btnText}</button><br/><div id="docs03_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs03')" class="tooltip_wrap short position_absolute" style="width:380px; display:none; left:-260px; top:20px;"><div class="cont text_left"><class="title text_left">${tooltipTitle}<br/><ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}</li></ul></div></div>${certi !== '' ? '<span class=\'font_11\'>' + certi + '</span>' : ''}`
          }
          html.push(htmlTag)
        }
        return html.join('<br/>')
      }
    }
  },
  {
    name: 'docs04',
    fieldName: 'docs04',
    header: { text: app.$t('msg.ONEX010T010.059') }, // A/N
    width: '50',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const rvsdEndpYn = grid.getValue(idx, 'rvsdEndpYn')
        // const anChk = grid.getValue(idx, 'anChk')
        // const anYn = grid.getValue(idx, 'anYn') || ''
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const srRno = grid.getValue(idx, 'srRno') || ''
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const rvsdEndpYn = vmAppWpx.gridList[idx].rvsdEndpYn
        const anChk = vmAppWpx.gridList[idx].anChk
        const anYn = vmAppWpx.gridList[idx].anYn || ''
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const srRno = vmAppWpx.gridList[idx].srRno || ''
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const podCtrCd = vmAppWpx.gridList[idx].podCtrCd || ''
        const id = bkgNo + '_' + blNo

        // 선박 출항 전 (공란으로 표시)
        if (rvsdEndpYn === 'N' || (anChk !== 'N' && anChk !== 'Y')) {
          return ''
        }
        const cssClasss = anChk === 'N' ? 'uncom' : 'finish'
        const btnText = anChk === 'N' ? app.$t('msg.ONEX010T010.124') : app.$t('msg.ONEX010T010.131')
        const arrTooltip = []
        if (anChk === 'Y') {
          arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs04(event, '${bkgNo}', '${srRno}', '${blNo}', '${podCtrCd}', 'AN_SENT')">${app.$t('msg.ONEX010T010.115')}</a>`) // A/N 출력
        } else if (anYn === 'Y') {
          arrTooltip.push(`<a href="#" class="link" onclick="vmAppWpx.fnClickDocs04(event, '${bkgNo}', '${srRno}', '${blNo}', '${podCtrCd}', 'AN_SEND')">${app.$t('msg.ONEX010T010.115')}</a>`) // A/N 출력
        } else {
          arrTooltip.push(`${app.$t('msg.ONEX010T010.116')}`) // A/N이 준비되지 않았습니다.
        }
        return `<button type="button" id="docs04_btn_${id}" class="status_icon ${cssClasss}" onmouseover="vmAppWpx.showTooltip(event, '${id}', 'docs04')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs04')">${btnText}</button><br/><div id="docs04_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'docs04')" class="tooltip_wrap short position_absolute" style="width:380px; display:none; left:-260px; top:24px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}</li></ul></div></div>`
      }
    }
  },
  {
    name: 'docs05',
    fieldName: 'docs05',
    header: { text: app.$t('msg.ONEX010T010.060') }, // D/O
    width: '50',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const doChk = vmAppWpx.gridList[idx].doChk
        const podArvYn = vmAppWpx.gridList[idx].podArvYn
        const blNo = vmAppWpx.gridList[idx].blNo || ''

        // 선박 입항 전
        if (blNo === '' || podArvYn !== 'Y') {
          return ''
        }

        const doIssYn = (doChk !== undefined && (doChk.indexOf('C') === 0)) ? 'Y' : 'N'
        const cssClasss = doIssYn === 'N' ? 'uncom' : 'finish'
        const btnText = doIssYn === 'N' ? app.$t('msg.ONEX010T010.124') : app.$t('msg.ONEX010T010.131')
        return `<button type="button" class="status_icon ${cssClasss}">${btnText}</button>`
      }
    }
  }
]
// 컨테이너 토글 선택 컬럼
const columnCon = [
  {
    name: 'cntrPickUp',
    fieldName: 'cntrPickUp',
    width: '50',
    editable: false,
    sortable: false,
    header: { text: app.$t('msg.ONEX010T010.061') }, // 픽업
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const cntrPickUp = grid.getValue(idx, 'cntrPickUp')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const cntrPickUp = vmAppWpx.gridList[idx].cntrPickUp
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const id = bkgNo + '_' + blNo
        let cnt = 0
        let tot = 0
        if (cntrPickUp.indexOf('/') > -1) {
          cnt = parseInt(cntrPickUp.split('/')[0], 10)
          tot = parseInt(cntrPickUp.split('/')[1], 10)
        }
        return `<span id="cntr01_btn_${id}" onmouseover="vmAppWpx.showTooltipCntrInfo(event, '${id}', 'cntr01', '${bkgNo}', '${blNo}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr01')">${cntrPickUp}</span><div id="cntr01_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr01')" class="tooltip_wrap short position_absolute cntr_tt" style="width:300px; display:none;top:24px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  },
  {
    name: 'cntrGti',
    fieldName: 'cntrGti',
    width: '50',
    editable: false,
    sortable: false,
    header: { text: app.$t('msg.ONEX010T010.062') }, // 반입
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const cntrGti = grid.getValue(idx, 'cntrGti')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const cntrGti = vmAppWpx.gridList[idx].cntrGti
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const id = bkgNo + '_' + blNo

        let cnt = 0
        let tot = 0

        if (cntrGti.indexOf('/') > -1) {
          cnt = parseInt(cntrGti.split('/')[0], 10)
          tot = parseInt(cntrGti.split('/')[1], 10)
        }

        const cssClasss = cnt > tot ? 'color_red' : ''

        return `<span id="cntr02_btn_${id}" onmouseover="vmAppWpx.showTooltipCntrInfo(event, '${id}', 'cntr02', '${bkgNo}', '${blNo}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr02')" class="${cssClasss}">${cntrGti}</span><div id="cntr02_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr02')" class="tooltip_wrap short position_absolute cntr_tt" style="width:300px; display:none;top:24px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  },
  {
    name: 'cntrLdg',
    fieldName: 'cntrLdg',
    width: '50',
    editable: false,
    sortable: false,
    header: { text: app.$t('msg.ONEX010T010.063') }, // 선적
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const cntrLdg = grid.getValue(idx, 'cntrLdg')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const cntrLdg = vmAppWpx.gridList[idx].cntrLdg
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const id = bkgNo + '_' + blNo

        let cnt = 0
        let tot = 0

        if (cntrLdg.indexOf('/') > -1) {
          cnt = parseInt(cntrLdg.split('/')[0], 10)
          tot = parseInt(cntrLdg.split('/')[1], 10)
        }

        const cssClasss = cnt > tot ? 'color_red' : ''

        return `<span id="cntr03_btn_${id}" onmouseover="vmAppWpx.showTooltipCntrInfo(event, '${id}', 'cntr03', '${bkgNo}', '${blNo}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr03')" class="${cssClasss}">${cntrLdg}</span><div id="cntr03_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr03')" class="tooltip_wrap short position_absolute cntr_tt" style="width:300px; display:none; left:-160px; top:24px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  },
  {
    name: 'cntrDis',
    fieldName: 'cntrDis',
    width: '50',
    editable: false,
    sortable: false,
    header: { text: app.$t('msg.ONEX010T010.064') }, // 양하
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const cntrDis = grid.getValue(idx, 'cntrDis')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const cntrDis = vmAppWpx.gridList[idx].cntrDis
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const id = bkgNo + '_' + blNo
        return `<span id="cntr04_btn_${id}" onmouseover="vmAppWpx.showTooltipCntrInfo(event, '${id}', 'cntr04', '${bkgNo}', '${blNo}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr04')">${cntrDis}</span><div id="cntr04_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr04')" class="tooltip_wrap short position_absolute cntr_tt" style="width:300px; display:none; left:-200px; top:24px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  },
  {
    name: 'cntrGto',
    fieldName: 'cntrGto',
    width: '50',
    editable: false,
    sortable: false,
    header: { text: app.$t('msg.ONEX010T010.065') }, // 반출
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const cntrGto = grid.getValue(idx, 'cntrGto')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const cntrGto = vmAppWpx.gridList[idx].cntrGto
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const id = bkgNo + '_' + blNo
        return `<span id="cntr05_btn_${id}" onmouseover="vmAppWpx.showTooltipCntrInfo(event, '${id}', 'cntr05', '${bkgNo}', '${blNo}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr05')">${cntrGto}</span><div id="cntr05_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr05')" class="tooltip_wrap short position_absolute cntr_tt" style="width:300px; display:none; left:-240px; top:24px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  },
  {
    name: 'cntrRtn',
    fieldName: 'cntrRtn',
    width: '50',
    editable: false,
    sortable: false,
    header: { text: app.$t('msg.ONEX010T010.066') }, // 반납
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const cntrRtn = grid.getValue(idx, 'cntrRtn')
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const cntrRtn = vmAppWpx.gridList[idx].cntrRtn
        const bkgNo = vmAppWpx.gridList[idx].bkgNo
        const blNo = vmAppWpx.gridList[idx].blNo || ''
        const id = bkgNo + '_' + blNo
        return `<span id="cntr06_btn_${id}" onmouseover="vmAppWpx.showTooltipCntrInfo(event, '${id}', 'cntr06', '${bkgNo}', '${blNo}')" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr06')">${cntrRtn}</span><div id="cntr06_tt_${id}" onmouseout="vmAppWpx.hideTooltip(event, '${id}', 'cntr06')" class="tooltip_wrap short position_absolute cntr_tt" style="width:340px; display:none; left:-280px; top:24px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  }
]

export const columns1 = [
  ...defCol1,
  ...columnDoc,
  ...defCol2
]

export const columns2 = [
  ...defCol1,
  ...columnCon,
  ...defCol2
]

const defLayer1 = [
  'bkgNo',
  'bkgCntr',
  'blNo',
  'cntrQty',
  'request',
  'statusCd',
  'actShprCstEnm',
  'polPortCd',
  'tsPortCd',
  'podPortCd',
  'vslNm',
  'tel',
  'spcShiprYn'
]

const defLayer2 = []

const layerDoc = [
  {
    name: 'docs',
    direction: 'horizontal',
    header: {
      text: '서류 진행 현황',
      template: `<ul class="tab_toggle" style="margin-top:2px;"><li><a href="#" class="on" onclick="vmAppWpx.changeColumn(event, 'doc');">${app.$t('msg.ONEX010T010.054')}</a></li><li><a href="#" onclick="vmAppWpx.changeColumn(event, 'cntr');">${app.$t('msg.ONEX010T010.055')}</a></li></ul>`
    },
    items: [
      'docs00',
      'docs01',
      'docs02',
      'docs03'
    ]
  }
]

const layerCon = [
{
    name: 'cntr',
    direction: 'horizontal',
    header: {
      text: '컨테이너 진행 현황',
      template: `<ul class="tab_toggle" style="margin-top:2px;"><li><a href="#" onclick="vmAppWpx.changeColumn(event, 'doc');">${app.$t('msg.ONEX010T010.054')}</a></li><li><a href="#" class="on" onclick="vmAppWpx.changeColumn(event, 'cntr');">${app.$t('msg.ONEX010T010.055')}</a></li></ul>`
    },
    items: [
      'cntrPickUp',
      'cntrGti',
      'cntrLdg',
      'cntrDis',
      'cntrGto',
      'cntrRtn'
    ]
  }
]

const layout1 = [
  ...defLayer1,
  ...layerDoc,
  ...defLayer2
]

const layout2 = [
  ...defLayer1,
  ...layerCon,
  ...defLayer2
]

export default {
  name: 'WorkingProgressExport',
  components: {
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple'),
    ExportManagerPop: () => import('@/pages/trans/popup/ExportManagerPop'), // 수출 담당자 팝업
    FareRegisReqPop: () => import('@/pages/trans/popup/FareRegisReqPop'), // 운임 등록 요청 팝업
    PorDlySchedulePop: () => import('@/pages/trans/popup/PorDlySchedulePop'), // 출발지/도착지 스케줄 정보
    VesselScheduleInfoPop: () => import('@/pages/schedule/popup/VesselScheduleInfoPop'), // 선박정보 및 스케줄
    DangerDocReqPop: () => import('@/pages/trans/popup/DangerDocReqPop'), // 위험물반입신고 요청서 팝업
    FullContainerOutReqPop: () => import('@/pages/trans/popup/FullContainerOutReqPop'), // Full 컨테이너 반출 요청서 팝업
    CntrNoCorrectReqPop: () => import('@/pages/trans/popup/CntrNoCorrectReqPop'), // 컨테이너 번호변경 요청서 팝업
    FreeTimeExtensReqPop: () => import('@/pages/settle/popup/FreeTimeExtensReqPop'), // Free Time 연장요청 팝업
    ContainerShuttlePop: () => import('@/pages/trans/popup/ContainerShuttlePop'), // 컨테이너 셔틀 요청서 팝업
    CodRequestPop: () => import('@/pages/trans/popup/CodRequestPop'), // COD 요청서 팝업
    ShipbackReqPop: () => import('@/pages/trans/popup/ShipbackReqPop'), // Shipback 요청서 팝업
    BLModifyNotiPop: () => import('@/pages/trans/popup/BLModifyNotiPop'),
    BookingConfirmPop: () => import('@/pages/pdf/popup/BookingConfirmPop'), // Booking Confirm (PDF)
    ShipmentFinishPop: () => import('@/pages/pdf/popup/ShipmentFinishPop'), // 선적 마감 안내문 (PDF)
    DelayNoticeOutPop: () => import('@/pages/pdf/popup/DelayNoticePop'), // Delay Notice(수출) (PDF)
    VesselChangePop: () => import('@/pages/pdf/popup/VesselChangePop'), // Vessel Change Notice (PDF)
    RollOverNoticePop: () => import('@/pages/pdf/popup/RollOverNoticePop'), // Roll Over Notice (PDF)
    BlCopyPop: () => import('@/pages/pdf/popup/BlCopyPop'), // B/L Copy (PDF)
    CertificatePop: () => import('@/pages/pdf/popup/CertificatePop'), // Certificate
    FreeTimePop: () => import('@/pages/pdf/popup/FreeTimePop'), // Free Time 승인
    InvoicePop: () => import('@/pages/pdf/popup/InvoicePop'), // Invoice
    CombineInvoicePop: () => import('@/pages/pdf/popup/CombineInvoicePop'), // combine Invoice
    BLPaymentAccountlessPop: () => import('@/pages/trans/popup/BLPaymentAccountlessPop'), // 미정산 내역 팝업
    ArrivalNoticePop: () => import('@/pages/pdf/popup/ArrivalNoticePop'), // A/N (PDF)
    FreightPop: () => import('@/pages/settle/popup/CalcIssueFreightPop'), // 운임 수정 요청
    OriginalBLReIssuePop: () => import('@/pages/trans/popup/OriginalBLReIssuePop'), // O B/L 재발행 팝업
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    CommonBL: () => import('@/pages/trans/CommonBL'),
    CommonBooking: () => import('@/pages/trans/CommonBooking'),
    CaRequestPop: () => import('@/pages/trans/popup/CARequestPop'),
    ReceiptPrintPop: () => import('@/pages/pdf/popup/ReceiptPrintPop'), /* Receipt 팝업 */
    DemInvoicePop: () => import('@/pages/settle/popup/DemInvoicePop'),
    blAttachPop: () => import('@/pages/trans/popup/BLAttachPop'),
    SurrenderNoticePop: () => import('@/pages/pdf/popup/SurrenderNoticePop'),
    SeawaybillNoticePop: () => import('@/pages/pdf/popup/SeawaybillNoticePop'),
    RfDataNotiPop: () => import('@/pages/trans/popup/RfDataNotiPop'),
    RfDataInfoPop: () => import('@/pages/trans/popup/RfDataInfoPop'),
    CommonUnsolved: () => import('@/pages/trans/CommonUnsolved'),
    TerminalVesselLocation: () => import('@/pages/trans/popup/TerminalVesselLocation'),
    FreSurchargePop: () => import('@/pages/schedule/popup/FreSurchargePop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      commonBookingComponent: null,
      commonBookingParams: {},
      commonBookingCallback: null,
      commonBlComponent: null,
      commonBlParams: {},
      commonBlCallback: null,
      popupComponent: null,
      popupParams: {
          items: []
      },
      popupCallback: '',
      popupTp2Component: null,
      popupTp2Params: {},
      popupTp2Callback: '',
      popupTp3Component: null,
      popupTp3Params: {},
      popupTp3Callback: '',
      frtSurchargeComponent: null,
      surChargeParams: {},
      isHidden: true,
      isShowPop: false,
      gridHeight: 300,
      searchParams: {},
      requestAreaBtn: {},
      prevSearchParams: undefined,
      txtFilter: '',
      onlyPickupYn: 'N',
      onlyCancelYn: 'N',
      pkupUsagYn: '',
      totalCount: 0,
      list: [],
      tempData: {},
      cntrInfo: {},
      colType: 'doc',
      lang: '',
      checkedCount: 0,
      auxInvoiceData: [],
      picInfo: [],
      reqInfo: [],
      tempPicList: '',
      prdYn: true,
      vgmBkgNo: '',
      vgmBlSrNo: '',
      vgmBlNo: '',
      vgmBkgAcpnMethCd: '',
      ctrCdList: [],
      selCtr: '',
      selPo: 'POD',
      nonFreightYn: '', // dem/det JamSin
      nonFreightList: [], // dem/det JamSin
      relYn: true, //process.env.VUE_APP_MODE !== 'PRD' // JamSin
      uploadFileList: [],
      riderBlNo: '',
      riderYn: 'N',
      surrWaybillYn: 'N',
      viewYn: process.env.VUE_APP_MODE !== 'PRD',
      tooltipCont: '',
      reqPixYn: false,
      caReqYn: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this._ = require('lodash')
    this.lang = this.auth.serviceLang

    this.gridHeight = $('body').height() - 400
    if (this.gridHeight < 300) {
      this.gridHeight = 300
    }

    this.commonBlCallback = this.searchAct
    this.commonBlComponent = 'common-bL'
    this.init()
  },
  mounted () {
    window.vmAppWpx = this

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    // const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)

    provider.setFields(fields)

    // 반드시 setColumns을 먼저하고 setColumnLayout을 이후에 호출할것. 2024.01.09
    // 순서 바뀌면 화면 컬럼 사이즈 세팅이 설정 값대로 반영이 안되는 증상이 있음.
    gridView.setColumns(columns1)
    gridView.setColumnLayout(layout1)
    gridView.setFooter({ visible: false })
    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })

    gridView.header.height = 80
    gridView.displayOptions.rowHeight = 70
    gridView.displayOptions.fitStyle = 'evenFill'
    gridView.setCopyOptions({ copyDisplayText: true, lookupDisplay: true, copyTextCallback: this.copyTextCallback })

    if (vmAppWpx.memberDetail.userCtrCd === 'IN') {
      gridView.columnByName('docs03').header.text = vmAppWpx.$t('msg.CSBK100.375')
    }

    // 중국일 경우 T/S 추가
    if (vmAppWpx.memberDetail.userCtrCd === 'CN') {
      gridView.columnByName('tsPortCd').visible = true
    }

    if (vmAppWpx.memberDetail.userCtrCd !== 'KR') {
      gridView.columnByName('request').visible = false
    }
    this.gridEvents()

    this.setRequestArea([]) // 요청사항 버튼 초기 셋팅
  },
  methods: {
    async init () {
      await this.initCommCodes()

      if (this.$props.parentInfo.workType === 'E') {
        this.search(this.$props.parentInfo)
      }
    },
    async initCommCodes () {
      const arrCdId = ['01043', 'CS068', '01122', '01086', '01113'] // 01122(C/A 구분 코드), 01076(C/A 사유 코드)
      await Promise.all([
        commons.getCommonsCodesCommon(arrCdId).then(res => {
          this.commonCodes = res
        }),
        commons.getCommonsCodesCountry().then(res => {
          this.ctrCdList = res
        }),
        cp.getCallActionHandler({ actionCd: 'Q101' }).then(res => {
          const result = res.data

          if (this.$ekmtcCommon.isNotEmpty(result)) {
            const flag = result.result
            if (flag === 'Y') {
              this.pkupUsagYn = 'Y'
            } else {
              this.pkupUsagYn = 'N'
            }
          }
        }).catch(err => {
          console.log(err)
          this.pkupUsagYn = 'Y'
        })
      ])
    },
    async fnCommonBtnAction (type) {
      const containerInfo = await srInquiry.getVgmContainerInfo(this.vgmBkgNo)

      // BL CNTR 조회
      //const param = {
      //  blNo: this.vgmBlNo,
      //  bkgNo: this.vgmBkgNo
      //}
      //let containerInfo = ''
      //await trans.fnBlBkgInfo(param).then(res => {
      //  console.log('containerList===================>', res)
      //  containerInfo = res
      //})
      //console.log('containerList===================>', containerInfo.data.containerList)
      this.commonBlCallback = ''
      if (type === 'VGMPOP_SAVE' || type === 'VGMPOP_OPEN') {
        const vgmFlag = type.split('_')[1]
        this.commonBlParams = {
          action: 'VGMPOP',
          vgmFlag: vgmFlag,
          bkgNo: this.vgmBkgNo,
          blNo: this.vgmBlNo,
          methCd: this.vgmBkgAcpnMethCd,
          originCntrList: containerInfo.data.containerList,
          //tareModifyYn: this.cstCatCd === 'Y' || this.regParams.shprVanYn === 'Y' ? 'Y' : 'N'
          tareModifyYn: 'Y'
        }

        //if (vgmFlag === 'SAVE') {
        //  this.commonBlCallback = this.refresh
        //}
      }

      //if (this.commonBlComponent === null) {
      if (this.$refs.commonBL === undefined) {
        this.commonBlComponent = 'common-bL'
      } else {
        this.$nextTick(() => {
          this.$refs.commonBL.initPageFlag()
          this.$refs.commonBL.doAction()
        })
      }
    },
    // Ctrl + C 복사 데이터 조합 CallBack (Grid renderer 로직과 같아야 함)
    copyTextCallback (grid, itemIndex, column, value) {
      /*
      // Booking No 복사시 데이터 조합
      if (column.name === 'bkgNo') {
        var bkgNo = vmAppWpx.gridList[itemIndex].bkgNo || ''
        var bkgStsCd = vmAppWpx.gridList[itemIndex].bkgStsCd || ''
        var bkgCnclIngYn = vmAppWpx.gridList[itemIndex].bkgCnclIngYn || ''

        var cnclRsnRmk = vmAppWpx.gridList[itemIndex].cnclRsnRmk
        var cnclRsnCd = vmAppWpx.gridList[itemIndex].cnclRsnCd
        var cnclRsnDsc = vmAppWpx.gridList[itemIndex].cnclRsnDsc

        if (bkgStsCd === '00') {
          bkgNo += '\n' + this.$t('msg.CSBK100.022')
        } else if (bkgStsCd === '01') {
          if (bkgCnclIngYn === 'Y') {
            bkgNo += '\n' + this.$t('msg.ONEX010T010.129')
          } else {
            bkgNo += '\n' + this.$t('msg.CSBK100.023')
          }
        } else if (bkgStsCd === '02' || bkgStsCd === '05') {
          bkgNo += '\n' + this.$t('msg.CSBK100.022') + '\n' + this.$t('msg.ONEX010T010.068')
        } else if (bkgStsCd === '03') {
          if (cnclRsnCd !== '') {
            bkgNo += '\n' + this.$t('msg.CSBK100.024') + '\n' + this.$t('msg.ONEX010T010.069') + '\n' + cnclRsnDsc + (cnclRsnRmk !== '' ? '\n' + cnclRsnRmk : '')
          } else {
            bkgNo += '\n' + this.$t('msg.CSBK100.024')
          }
        } else if (bkgStsCd === '04') {
          if (bkgCnclIngYn === 'Y') {
            bkgNo += '\n' + this.$t('msg.ONEX010T010.129')
          } else {
            bkgNo += '\n' + this.$t('msg.CSBK100.023')
          }
        }
        return bkgNo
      }

      // B/L No 복사시 데이터 조합
      if (column.name === 'blNo') {
        var blNo = vmAppWpx.gridList[itemIndex].blNo || ''
        var srRno = vmAppWpx.gridList[itemIndex].srRno || ''
        var blCnclYn = vmAppWpx.gridList[itemIndex].blCnclYn || ''
        var srCnclYn = vmAppWpx.gridList[itemIndex].srCnclYn || ''
        var srYn = vmAppWpx.gridList[itemIndex].srYn || ''
        var srEdi = vmAppWpx.gridList[itemIndex].srEdi || ''
        var blChkYn = vmAppWpx.gridList[itemIndex].blChkYn || ''
        var caStsCd = vmAppWpx.gridList[itemIndex].caStsCd || ''
        var srCmplsSbmtYn = vmAppWpx.gridList[itemIndex].srCmplsSbmtYn || ''
        var blStsCd = vmAppWpx.gridList[itemIndex].blStsCd || ''
        var temcount = vmAppWpx.gridList[itemIndex].temcount || ''

        var msgTemp = ''

        if (blNo === '' && srRno === '') {
          return msgTemp
        }

        if (srRno !== '' && blNo === '') {
          if (blCnclYn !== 'Y') {
            msgTemp = srRno
            if (srCnclYn === 'Y') {
              msgTemp += '\n' + this.$t('msg.CSBK100.024')
            } else {
              if (srYn === 'N') {
                msgTemp += '\n' + this.$t('msg.CSBK100.407')
              } else if (srEdi === 'Y' && vmAppWpx.memberDetail.userCtrCd === 'KR') {
                msgTemp += '\n' + this.$t('msg.ONEX060P011_SR.001')
              } else if (blChkYn === 'A' || blChkYn === 'Y') {
                var stsMsg = vmAppWpx.memberDetail.userCtrCd === 'CN' ? 'S/R ' + this.$t('msg.CSBK130.004') : this.$t('msg.CSBK100.022')
                msgTemp += stsMsg + this.$t('msg.ONEX010T010.068') + '\n' + '- S/R Requested'
              } else {
                msgTemp += '\n' + this.$t('msg.CSBK100.022')
              }
            }
          }
        } else {
          msgTemp = blNo
          if (blCnclYn === 'Y') {
            msgTemp += '\n' + this.$t('msg.CSBK100.024')
          } else if (caStsCd !== '' && caStsCd !== '0' && caStsCd !== '4') {
            var arrMsg = ''
            if (srCmplsSbmtYn === 'Y' && blStsCd === '02') {
              arrMsg = this.$t('msg.ONEX010T010.139') + ': Forced Submission'
            } else {
              if (blStsCd === '02') {
                arrMsg = this.$t('msg.ONEX010T010.139') + ': Requested'
              } else if (blStsCd === '03') {
                arrMsg = this.$t('msg.ONEX010T010.139') + ': Accepted'
              } else if (blStsCd === '07') {
                arrMsg = this.$t('msg.ONEX010T010.139') + ': Pending'
              } else {
                arrMsg = this.$t('msg.ONEX010T010.139') + ': Requested'
              }
            }
            arrMsg += '\n' + this.$t('msg.ONEX010T010.140') + ' : ' + this.$t('msg.ONEX010T010.CA_STS_CD_' + caStsCd)

            var arrBtn = ''
            var caTxt = ''
            if (temcount !== '0') {
              arrBtn = this.$t('msg.CMBA380.CA01') + ' ' + this.$t('msg.MYIN040G010.029')
            }
            caTxt = this.$t('msg.ONEX010T010.070')
            if (caStsCd === '3') {
              caTxt = this.$t('msg.ONEX010T010.156')
            }
            msgTemp += '\n' + caTxt + '\n' + arrMsg + '\n' + arrBtn
          } else if (blStsCd === '03') {
            msgTemp += '\n' + this.$t('msg.CSBK100.023')
          } else if (vmAppWpx.memberDetail.userCtrCd === 'KR' && srEdi === 'Y' && caStsCd === '0') {
            msgTemp += '\n' + this.$t('msg.CSBK100.022')
          } else if (blStsCd === '') {
            if (vmAppWpx.memberDetail.userCtrCd === 'CN') {
              if (caStsCd === '4') {
                msgTemp += '\n' + this.$t('msg.CSBK100.023')
              } else {
                msgTemp += '\n' + 'B/L Created'
              }
            } else if (vmAppWpx.memberDetail.userCtrCd === 'KR' && srEdi === 'Y' && caStsCd === '0') {
              msgTemp += '\n' + this.$t('msg.CSBK100.022')
            } else {
              msgTemp += '\n' + this.$t('msg.CSBK100.407')
            }
          } else {
            msgTemp += '\n' + this.$t('msg.CSBK100.022') + '\n' + this.$t('msg.ONEX010T010.068')
          }
        }
        return msgTemp
      } */

      if (column.name === 'statusCd') {
        var bkgStsCd1 = vmAppWpx.gridList[itemIndex].bkgStsCd
        var mtyPkupUsagYn = vmAppWpx.gridList[itemIndex].mtyPkupUsagYn
        var shprRspsYn = vmAppWpx.gridList[itemIndex].shprRspsYn
        var shprRspsBtnYn = vmAppWpx.gridList[itemIndex].shprRspsBtnYn

        if (bkgStsCd1 === '02' || bkgStsCd1 === '05') {
          return ''
        }

        if (mtyPkupUsagYn === 'Y') {
          return this.$t('msg.ONIM050G010.130')
        }

        if (shprRspsYn === 'Y') {
          return this.$t('msg.ONEX010T010.131') // '완료'
        } else if (shprRspsBtnYn === 'E') {
          return this.$t('msg.ONEX010T010.131') // '완료'
        } else if (shprRspsBtnYn === 'Y') {
          return 'Yes'
        } else {
          return ''
        }
      }

      if (column.name === 'polPortCd') {
        var por = vmAppWpx.gridList[itemIndex].por || ''
        var etd = vmAppWpx.gridList[itemIndex].etd || ''
        var mtyPkupUsagYn1 = vmAppWpx.gridList[itemIndex].mtyPkupUsagYn || ''
        var etdDiff = vmAppWpx.gridList[itemIndex].etdDiff
        var etdText = convertDate(etd)

        if (mtyPkupUsagYn1 !== 'Y') {
          if (etdDiff !== undefined) {
            if (etdDiff !== '0' && etdDiff.indexOf('-') === -1) {
              return por + '\n' + etdText + ' (+' + etdDiff + ')'
            } else {
              return por + '\n' + etdText + ' (' + etdDiff + ')'
            }
          } else {
            return por + '\n' + etdText
          }
        } else {
          return ''
        }
      }

      if (column.name === 'podPortCd') {
        var dly = vmAppWpx.gridList[itemIndex].dly || ''
        var eta = vmAppWpx.gridList[itemIndex].eta || ''
        var mtyPkupUsagYn2 = vmAppWpx.gridList[itemIndex].mtyPkupUsagYn || ''
        var etaDiff = vmAppWpx.gridList[itemIndex].etaDiff
        var etaText = convertDate(eta)

        if (mtyPkupUsagYn2 !== 'Y') {
          if (etaDiff !== undefined) {
            if (etaDiff !== '0' && etaDiff.indexOf('-') === -1) {
              return dly + '\n' + etaText + ' (+' + etaDiff + ')'
            } else {
              return dly + '\n' + etaText + ' (' + etaDiff + ')'
            }
          } else {
            return dly
          }
        } else {
          return ''
        }
      }

      if (column.name === 'vslNm') {
        var vslNm = vmAppWpx.gridList[itemIndex].vslNm || ''
        var voyNo = vmAppWpx.gridList[itemIndex].voyNo || ''

        return vslNm + '\n' + voyNo
      }

      if (column.name === 'cntrQty') {
        var cntrQty = vmAppWpx.gridList[itemIndex].cntrQty
        var dgCertiCnt = vmAppWpx.gridList[itemIndex].dgCertiCnt
        var hzYn = vmAppWpx.gridList[itemIndex].hzYn

        if (dgCertiCnt > -1) {
          return cntrQty + '\n' + 'DG Certi. (' + dgCertiCnt + ')'
        } else if (hzYn === 'Y') {
          return cntrQty + '\n' + 'HZ'
        } else {
          return cntrQty
        }
      }

      // 담당자 연락처 복사시 데이터 조합
      if (column.name === 'tel') {
        return '연락처'
      }

      if (column.name === 'docs01') {
        var srRno01 = vmAppWpx.gridList[itemIndex].srRno || ''
        var blNo01 = vmAppWpx.gridList[itemIndex].blNo || ''
        var docDtm = vmAppWpx.gridList[itemIndex].docDtm
        var srYn01 = vmAppWpx.gridList[itemIndex].srYn
        var cntrCntChk = vmAppWpx.gridList[itemIndex].cntrCntChk
        var vgmStatus = vmAppWpx.gridList[itemIndex].vgmStatus
        var elYn = vmAppWpx.gridList[itemIndex].elYn
        var ctrCd = vmAppWpx.memberDetail.userCtrCd
        var docDtmText = convertDate(docDtm)

        // srRno가 없을때, BL NO. 체크해서 있으면, srRno가 있는걸로 로직체크
        if (srRno01 === '' && blNo01 === '') { // BL NO. 가 없을때만, 마감시간 표시
          return docDtmText
        }

        // CN 일때는, S/R 제출 여부 건너뜀
        if (vmAppWpx.memberDetail.userCtrCd === 'CN') {
          srYn01 = 'Y'
        }

        if (srYn01 === 'Y' && cntrCntChk === 'YY' && vgmStatus === 'Y' && ((ctrCd !== 'KR' && ctrCd !== 'ID') || (elYn === 'Y' && (ctrCd === 'KR' || ctrCd === 'ID')))) {
          return this.$t('msg.ONEX010T010.131')
        } else {
          var arrTooltip = ''
          if (srYn01 !== 'Y' && ctrCd !== 'CN') {
            arrTooltip += this.$t('msg.ONEX010T010.132')
          }
          if (cntrCntChk !== 'YY') {
            arrTooltip += '\n' + this.$t('msg.ONEX010T010.133')
          }
          if (elYn !== 'Y' && (ctrCd === 'KR' || ctrCd === 'ID')) {
            arrTooltip += '\n' + this.$t('msg.ONEX010T010.134')
          }
          if (vgmStatus !== 'Y') {
            arrTooltip += '\n' + this.$t('msg.ONEX010T010.135')
          }
          return docDtmText + '\n' + arrTooltip
        }
      }

      if (column.name === 'docs02') {
        var frtYn = vmAppWpx.gridList[itemIndex].frtYn
        var blNo02 = vmAppWpx.gridList[itemIndex].blNo || ''
        var srRno02 = vmAppWpx.gridList[itemIndex].srRno || ''

        // S/R Create 전
        if (blNo02 === '' && srRno02 === '') {
          return ''
        }

        var btnText = frtYn === 'Y' ? this.$t('msg.ONEX010T010.131') : this.$t('msg.ONEX010T010.124')

        if (frtYn === 'Y') {
          return btnText
        } else {
          var arrTooltip02 = ''

          if (frtYn === 'N') {
            arrTooltip02 = this.$t('msg.ONEX010T010.122') // 운임 미정산
          } else if (frtYn === 'E') {
            if (vmAppWpx.memberDetail.userCtrCd !== 'KR') {
              arrTooltip02 = this.$t('msg.ONEX010T010.123') // 운임 미등록
            } else {
              arrTooltip02 = this.$t('msg.ONEX010T010.123') // 운임 미등록
            }
          }
          return btnText + '\n' + arrTooltip02
        }
      }

      if (column.name === 'docs03') {
        var srRno03 = vmAppWpx.gridList[itemIndex].srRno || ''
        var blNo03 = vmAppWpx.gridList[itemIndex].blNo || ''
        var issueStatus = vmAppWpx.gridList[itemIndex].issueStatus || ''
        var issueReason = vmAppWpx.gridList[itemIndex].issueReason || ''
        var cntrStsCd = vmAppWpx.gridList[itemIndex].cntrStsCd || ''
        var cntrCfsYn = vmAppWpx.gridList[itemIndex].cntrCfsYn || ''
        var blCatCd = vmAppWpx.gridList[itemIndex].blCatCd || ''
        var surrYn = vmAppWpx.gridList[itemIndex].surrYn || ''
        var wbilCfmYn = vmAppWpx.gridList[itemIndex].wbilCfmYn || ''
        var frtYn03 = vmAppWpx.gridList[itemIndex].frtYn || ''
        var issueElYn = vmAppWpx.gridList[itemIndex].issueElYn || ''
        var issueCertiDt = vmAppWpx.gridList[itemIndex].issueCertiDt || ''
        var certi = ''

        if (issueElYn === 'N') {
          if (blCatCd === '01' && surrYn === 'Y') {
            issueElYn = 'Y'
          } else if (blCatCd === '02' && wbilCfmYn === 'Y') {
            issueElYn = 'Y'
          }
        }

        if (srRno03 === '' && blNo03 === '') {
          return '' // S/R Create 전
        }

        var arrTooltip03 = ''

        var cssClass = ''
        var btnText03 = ''
        var tooltipTitle = ''
        var isTooltipLayer = true

        if (issueStatus === '11') {
          // O.B/L 재발행
          cssClass = 'origin'
          btnText03 = 'Original B/L'

          tooltipTitle = this.$t('msg.ONEX010T010.094') // 'Original B/L 발행'
          arrTooltip03 = this.$t('msg.ONEX010T010.113') + '\n' + this.$t('msg.ONEX010T010.114')// B/L Copy + O.B/L 재발행
        } else if (issueStatus === '01') {
          // O.B/L 발행
          cssClass = 'origin'
          btnText03 = 'Original B/L'

          tooltipTitle = this.$t('msg.ONEX010T010.094') // 'Original B/L 발행'
          arrTooltip03 = this.$t('msg.ONEX010T010.113') + '\n' + this.$t('msg.ONEX010T010.114')// B/L Copy + O.B/L 재발행
        } else if (issueStatus === '06') {
          // O.B/L 발행중
          cssClass = 'request'
          btnText03 = 'O.B/L 발행중'

          tooltipTitle = this.$t('msg.ONEX010T010.097') // '발행중'
          arrTooltip03 = this.$t('msg.ONEX010T010.098') // 'O.B/L 발행중'
        } else if (issueStatus === '16') {
          // O.B/L 승인
          cssClass = 'origin'
          btnText03 = 'O.B/L 승인'

          tooltipTitle = this.$t('msg.ONEX010T010.094') // 'Original B/L 발행'
          arrTooltip03 = this.$t('msg.ONEX010T010.113') + '\n' + this.$t('msg.ONEX010T010.114')// B/L Copy + O.B/L 재발행
        } else if (issueStatus === '02') {
          // Surrender 발행
          cssClass = 'srurr'
          btnText03 = 'Surrender 발행'

          tooltipTitle = this.$t('msg.ONEX010T010.095') // 'Surrender 발행'
          arrTooltip03 = this.$t('msg.ONEX010T010.113') // B/L Copy
        } else if (issueStatus === '07') {
          // Surrender 처리중
          cssClass = 'request'
          btnText03 = 'Surrender 처리중'

          tooltipTitle = this.$t('msg.ONEX010T010.101') // '처리중'
          arrTooltip = this.$t('msg.ONEX010T010.103') // 'Surrender 처리중'
        } else if (issueStatus === '17') {
          // Surrender 승인
          cssClass = 'srurr'
          btnText03 = 'Surrender 승인'

          tooltipTitle = this.$t('msg.ONEX010T010.095') // 'Surrender 발행'
          arrTooltip = this.$t('msg.ONEX010T010.113') // B/L Copy
        } else if (issueStatus === '42') {
          // Surrender 취소
          cssClass = 'uncom'
          btnText03 = 'Surrender 취소'

          tooltipTitle = this.$t('msg.ONEX010T010.105') // '취소'
          arrTooltip03 = 'Surrender 취소'
        } else if (issueStatus === '03') {
          // S/Waybill 발행
          cssClass = 'seaway'
          btnText03 = 'Sea Waybill'

          tooltipTitle = this.$t('msg.ONEX010T010.096') // 'S/Waybill 발행'
          arrTooltip03 = this.$t('msg.ONEX010T010.113') // B/L Copy
        } else if (issueStatus === '04') {
          // S/Waybill 발행중
          cssClass = 'request'
          btnText03 = 'S/Waybill 발행중'

          tooltipTitle = this.$t('msg.ONEX010T010.097') // '발행중'
          arrTooltip03 = 'S/Waybill 발행중'
        } else if (issueStatus === '14') {
          // S/Waybill 승인
          cssClass = 'seaway'
          btnText03 = 'S/Waybill 승인'

          tooltipTitle = this.$t('msg.ONEX010T010.096') // 'S/Waybill 발행'
          arrTooltip03 = this.$t('msg.ONEX010T010.113') // B/L Copy
        } else if (issueStatus === '46') {
          // S/Waybill 취소
          cssClass = 'uncom'
          btnText03 = 'S/Waybill 취소'

          tooltipTitle = this.$t('msg.ONEX010T010.105') // '취소'
          arrTooltip03 = this.$t('msg.ONEX010T010.108') // 'S/Waybill 취소'
        } else if (issueStatus === '88') { // 발행불가
          cssClass = 'uncom'
          btnText03 = '발행불가'
          tooltipTitle = this.$t('msg.ONEX010T010.109') // '발행불가'

          if (issueReason !== '') {
            arrTooltip03 = issueReason
          }
        } else if (issueStatus === '89') { // Pending
          cssClass = 'uncom'
          btnText03 = 'Pending'
          tooltipTitle = this.$t('msg.ONEX010T010.110') // 'Pending'

          if (issueReason !== '') {
            arrTooltip03 = issueReason
          }
        } else if (vmAppWpx.memberDetail.userCtrCd !== 'JP' && issueStatus === '99') {
          if (cntrStsCd === 'N' && issueElYn === 'N') {
            cssClass = 'uncom'
            btnText03 = '요청불가1'
            tooltipTitle = this.$t('msg.ONEX010T010.111') // '요청불가'
            arrTooltip03 = this.$t('art.CSBL220.003') // 컨테이너 미반입, 면장 미입력
          } else if (issueElYn === 'N') {
            cssClass = 'uncom'
            btnText03 = '요청불가2'
            tooltipTitle = this.$t('msg.ONEX010T010.111') // '요청불가'
            arrTooltip03 = this.$t('art.CSBL220.001') // 면장 미입력
          } else if (cntrStsCd === 'N' && cntrCfsYn === 'N') {
            cssClass = 'uncom'
            btnText03 = '요청불가2'
            tooltipTitle = this.$t('msg.ONEX010T010.111') // 요청불가'
            arrTooltip03 = this.$t('art.CSBL220.002') // 컨테이너 미반입
          } else {
            // 운임 미등록 상태일 경우
            if (frtYn03 === 'E') {
              cssClass = 'uncom'
              btnText03 = '요청불가3'
              tooltipTitle = this.$t('msg.ONEX010T010.111') // 요청불가'
              arrTooltip03 = this.$t('msg.ONEX010T010.123') // 운임 미등록
            } else {
              cssClass = 'button sm request'
              btnText03 = this.$t('msg.ONEX010T010.152')
              tooltipTitle = this.$t('msg.ONEX010T010.112') // '미요청'
              isTooltipLayer = false
            }
          }
        }

        if (cssClass !== '') {
          if (issueCertiDt !== '' && issueCertiDt.length === 8 && (cssClass === 'seaway' || cssClass === 'srurr' || cssClass === 'origin')) {
            certi = 'Onboard' + '\n' + issueCertiDt.substring(4, 6) + '/' + issueCertiDt.substring(6, 8)

            arrTooltip03 += '\n' + 'Certi.'
          }

          var htmlTag = ''

          if (!isTooltipLayer) {
            if (cssClass.indexOf('button') !== -1) {
              htmlTag = btnText03
            } else {
              htmlTag = btnText03
            }
          } else {
            htmlTag = btnText03 + '\n' + arrTooltip03 + '\n' + (certi !== '' ? '\n' + certi : '')
          }
        }
        return htmlTag
      }

      if (column.name === 'docs04') {
        var rvsdEndpYn = vmAppWpx.gridList[itemIndex].rvsdEndpYn
        var anChk = vmAppWpx.gridList[itemIndex].anChk
        var anYn = vmAppWpx.gridList[itemIndex].anYn || ''
        var podCtrCd = vmAppWpx.gridList[itemIndex].podCtrCd || ''

        // 선박 출항 전 (공란으로 표시)
        if (rvsdEndpYn === 'N' || (anChk !== 'N' && anChk !== 'Y')) {
          return ''
        }

        var btnText04 = anChk === 'N' ? app.$t('msg.ONEX010T010.124') : app.$t('msg.ONEX010T010.131')
        var arrTooltip04 = ''

        if (anChk === 'Y') {
          arrTooltip04 = this.$t('msg.ONEX010T010.115') // A/N 출력
        } else if (anYn === 'Y') {
          arrTooltip04 = this.$t('msg.ONEX010T010.115') // A/N 출력
        } else {
          arrTooltip04 = this.$t('msg.ONEX010T010.116') // A/N이 준비되지 않았습니다.
        }
        return btnText04 + '\n' + arrTooltip04
      }

      if (column.name === 'docs05') {
        var doChk = vmAppWpx.gridList[itemIndex].doChk
        var podArvYn = vmAppWpx.gridList[itemIndex].podArvYn
        var blNo05 = vmAppWpx.gridList[itemIndex].blNo || ''

        // 선박 입항 전
        if (blNo05 === '' || podArvYn !== 'Y') {
          return ''
        }

        var doIssYn = (doChk !== undefined && (doChk.indexOf('C') === 0)) ? 'Y' : 'N'
        var btnText05 = doIssYn === 'N' ? this.$t('msg.ONEX010T010.124') : this.$t('msg.ONEX010T010.131')

        return btnText05
      }
    },
    search (searchParams) {
      this.searchParams = this._.cloneDeep(searchParams)
      this.searchAct('SEARCH_BUTTON')
    },
    searchAct (act) {
      exportSvc.getOnGoingList(this.searchParams).then(res => {
        const list = res.data
        // console.log(res.data)
        this.setGridData(list)
        if (list?.length === 1) {
          gridView.setAllCheck(true)
          this.setRequestArea(gridView.getCheckedRows())
        }
        this.tempData = {}

        // 검색 버튼 클릭시
        if (act === 'SEARCH_BUTTON') {
          if (list === undefined) {
            this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
          } else {
            const tempList = list.filter(vo => {
              let isPp = true
              let isCc = true
              if (this.searchParams.kind !== '02') {
                isPp = this.onlyPickupYn === 'N' ? vo.mtyPkupUsagYn !== 'Y' : vo.mtyPkupUsagYn === 'Y'
                isCc = this.onlyCancelYn === 'N' ? vo.bkgStsCd !== '03' : vo.bkgStsCd === '03'
              }
              return isPp && isCc
            })

            if (tempList.length === 0) {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
            }
          }
        }

        //setTimeout(() => {
        //  this.closeRequestArea()
        //}, 2000)
      })
    },
    gridEvents () {
      const TH = this

      gridView.onItemAllChecked = (grid, checked) => {
        if (checked) {
          let rows = provider.getRows().map((v, i) => i)
          TH.setRequestArea(rows)
        } else {
          TH.setRequestArea([])
        }
      }

      gridView.onCellClicked = (grid, clickData) => {
        if (clickData.cellType === 'header' || clickData.cellType === 'head') {
          return
        }

        if (clickData.cellType === 'check') {
          TH.setRequestArea(grid.getCheckedRows())
          const gridSelect = {
            itemIndex: clickData.itemIndex,
            dataRow: clickData.dataRow,
            fieldIndex: 0,
            column: 'bkgNo',
            fieldName: 'bkgNo'
          }
          gridView.setCurrent(gridSelect)
          // return
        }

        // const fieldName = clickData.fieldName
        // const bkgNo = grid.getValue(clickData.dataRow, 'bkgNo')
        // const srRno = grid.getValue(clickData.dataRow, 'srRno') || ''
        // const blNo = grid.getValue(clickData.dataRow, 'blNo') || ''
        // const goParams = {
        //   bkgNo: bkgNo,
        //   srRno: srRno,
        //   blNo: blNo
        // }

        // if (fieldName === 'bkgNo') {
        //   TH.goDetailPage('BOOKING_EDIT', goParams)
        // } else if (fieldName === 'blNo') {
        //   if (srRno === '' && blNo === '') {
        //     return
        //   }

        //   if (blNo !== '') {
        //     TH.goDetailPageBl('BL_EDIT', goParams)
        //   } else {
        //     TH.goDetailPage('SR_EDIT', goParams)
        //   }
        // } else if (fieldName === 'polPortCd' || fieldName === 'podPortCd') {
        //   TH.porDlySchedulePopCall(bkgNo)
        // } else if (fieldName === 'vslNm') {
        //   TH.vesselScheduleInfoPopCall(bkgNo, srRno, blNo)
        // }
      }
    },
    setCurrentData (gridCurrent) {
      let checkedRows = gridView.getCheckedRows()
      let prevData

      if (gridCurrent === undefined) {
        gridCurrent = gridView.getCurrent()
      }

      prevData = {
        searchParams: this.searchParams,
        gridSelect: {
          column: gridCurrent.column,
          dataRow: gridCurrent.dataRow,
          fieldIndex: gridCurrent.fieldIndex,
          fieldName: gridCurrent.fieldName,
          itemIndex: gridCurrent.itemIndex
        },
        txtFilter: this.txtFilter,
        onlyPickupYn: this.onlyPickupYn,
        onlyCancelYn: this.onlyCancelYn,
        checkedRows: checkedRows
      }

      sessionStorage.setItem('working-progress-prev-data', JSON.stringify(prevData))
    },
    setRequestArea (chkRows) {
      if (chkRows.length === 0) {
        this.requestAreaBtn = {
          booking01: 'N', // 부킹취소
          booking02: 'N', // 부킹분할
          booking03: 'N', // 부킹병합
          booking04: 'N', // 픽업변경
          booking05: 'Y', // 픽업생성
          charge01: 'N', // 정산/발행 화면으로 이동
          charge02: 'N', // 운임 정정 요청
          charge03: 'N', // Freetime 연장 요청
          bl01: 'N', // B/L 작성
          bl02: 'N', // B/L 분할
          bl03: 'N', // 컨테이너 번호 변경
          bl04: 'N', // B/L Certi
          bl05: 'N', // VGM Input
          bl06: 'N', // B/L Issue request
          trans01: 'N', // 위험물 반입 요청
          trans02: 'N', // Full 컨테이너 반출
          trans03: 'N', // 셔틀 요청
          trans04: 'N', // COD
          trans05: 'N', // SHIPBACK
          trans06: 'N', // 선적위치
          report01: 'N', // 부킹 확인서
          report02: 'N', // Delay Notice
          report03: 'N', // Vessel Change Notice
          report04: 'N', // B/L Copy
          report05: 'N', // Invocie
          report06: 'N', // Free Time 승인 내역
          report07: 'N', // B/L Certificate
          report10: 'N', // surrender/waybill notice
          arrShipCancelBkgNo: []
        }
        this.checkedCount = 0
        this.riderYn = 'N'
        this.surrWaybillYn = 'N'
        this.caReqYn = 'N' // C/A Request 비활성화
        return
      }
      this.nonFreightYn = ''
      this.checkedCount = chkRows.length
      const searchList = []
      let isSameSchedule = true
      let prePolPortCd = ''
      let prePodPortCd = ''
      let preVslCd = ''
      let preVoyNo = ''
      let vgmShowYn = false
      let invBkgNo = ''
      let spcShiprYn = ''
      const TH = this

      chkRows.forEach(i => {
        const bkgNo = this.gridList[i].bkgNo
        const blNo = this.gridList[i].blNo || ''
        const polPortCd = this.gridList[i].polPortCd || ''
        const podPortCd = this.gridList[i].podPortCd || ''
        const vslCd = this.gridList[i].vslCd || ''
        const voyNo = this.gridList[i].voyNo || ''
        const issueStatus = this.gridList[i].issueStatus || ''
        const polCtrCd = this.gridList[i].polCtrCd || ''
        const etd = this.gridList[i].etd || ''

        searchList.push({
                           bkgNo: bkgNo,
                           blNo: blNo,
                           issueStatus: issueStatus,
                           polCtrCd: polCtrCd,
                           etd: etd
                      })

        invBkgNo = bkgNo
        spcShiprYn = this.gridList[i].spcShiprYn

        // VGM Input Check
        this.vgmBkgNo = this.gridList[i].bkgNo
        this.vgmBlSrNo = this.gridList[i].blNoOrSrRno || ''
        this.vgmBlNo = this.gridList[i].blNo || ''
        this.vgmBkgAcpnMethCd = this.gridList[i].bkgAcpnMethCd || ''

        // VGM Input Check
        if (this.$ekmtcCommon.isNotEmpty(this.vgmBkgNo) && this.$ekmtcCommon.isNotEmpty(this.vgmBlNo)) {
          vgmShowYn = true
        }

        // pol, pod, 선명/항차 동일여부 확인
        if (isSameSchedule) {
          if (prePolPortCd !== '' && prePolPortCd !== polPortCd) {
            isSameSchedule = false
          }
          if (prePodPortCd !== '' && prePodPortCd !== podPortCd) {
            isSameSchedule = false
          }
          if (preVslCd !== '' && preVslCd !== vslCd) {
            isSameSchedule = false
          }
          if (preVoyNo !== '' && preVoyNo !== voyNo) {
            isSameSchedule = false
          }
          prePolPortCd = polPortCd
          prePodPortCd = podPortCd
          preVslCd = vslCd
          preVoyNo = voyNo
        }
      })

      if (isSameSchedule && (prePolPortCd === '' || prePodPortCd === '' || preVslCd === '' || preVoyNo === '')) {
        isSameSchedule = false
      }

      const checkCnt = searchList.length
      const params = {
        searchList: searchList
      }

      this.riderBlNo = searchList[0].blNo

      if (checkCnt > 1) {
        this.riderYn = 'N'
        this.caReqYn = 'N' // 여러건 체크시 C/A Request 비활성화
      } else {
        if (searchList[0].blNo !== '') {
          this.caReqYn = 'Y' // 1건 체크시 bl 번호 존재하면 C/A Request 활성화
        } else {
          this.caReqYn = 'N'
        }

        if (this.$ekmtcCommon.isNotEmpty(this.riderBlNo)) {
          this.getBLAttachList()
        } else {
          this.riderYn = 'N'
        }
      }

      var surrCnt = 0
      var wayBillCnt = 0
      var otherCnt = 0

      // surr , seawaybill 노티스 출력물 조건
      searchList.forEach(item => {
        if (item.issueStatus === '02' || item.issueStatus === '17') {
          surrCnt += 1
        } else if (item.issueStatus === '03' || item.issueStatus === '14') {
          wayBillCnt += 1
        } else {
          otherCnt += 1
        }

        // console.log('surrCnt @@@ ', surrCnt)
        // console.log('wayBillCnt @@@ ', wayBillCnt)

        if (surrCnt > 0 && wayBillCnt > 0) { // 서렌더+웨이빌 복수 출력 안됨
          this.surrWaybillYn = 'N'
        } else {
          if (this.$ekmtcCommon.isNotEmpty(item.blNo)) {
            if (item.issueStatus === '02' || item.issueStatus === '17' || item.issueStatus === '03' || item.issueStatus === '14') {
              if (otherCnt > 0) { // 서렌더 or 웨이빌 선택시 두개 제외한 상테 체크박스 포함시 출력 안됨
                this.surrWaybillYn = 'N'
              } else {
                this.surrWaybillYn = 'Y'
              }
            } else {
              this.surrWaybillYn = 'N'
            }
          } else {
            this.surrWaybillYn = 'N'
          }
        }
      })

      // console.log('params @@@@@ ', params)

      exportSvc.getOnGoingRequestBtn(params).then(res => {
          TH.requestAreaBtn = res.data

          console.log('getOnGoingRequestBtn res @@@@ ', res)

          // 해외일때는 COD, SHIPBACK 버튼 무조건 오픈
          if (TH.memberDetail.userCtrCd !== 'KR' && TH.memberDetail.userCtrCd !== 'JP') {
            TH.requestAreaBtn.trans04 = 'Y' // COD
            TH.requestAreaBtn.trans05 = 'Y' // SHIPBACK
          }

          // VGM Input Check
          if (vgmShowYn) {
            TH.requestAreaBtn.bl05 = 'Y'
          }

          // if (searchList[0].polCtrCd === 'MY') {
          //     TH.requestAreaBtn.bl05 = 'Y' // VGM
          // }

          // KMTC ON 부킹인 경우 부킹 병합 비활성화 처리
          if (spcShiprYn === 'Y') {
            TH.requestAreaBtn.booking03 = 'N' // 부킹 병합
          }

          // 다중 업무 수행 불가 항목
          if (checkCnt > 1) {
            // 부킹
            TH.requestAreaBtn.booking01 = 'N' // 부킹 취소
            TH.requestAreaBtn.booking02 = 'N' // 부킹 분할
            TH.requestAreaBtn.booking03 = 'N' // 부킹 병합
            TH.requestAreaBtn.booking04 = 'N' // 픽업지 변경

            // B/L
            // if (TH.requestAreaBtn.bl01 === 'Y' && !isSameSchedule) {
              TH.requestAreaBtn.bl01 = 'N' // B/L 작성 (POL, POD, 선명, 항차 정보가 같은 경우만 작성 가능)
            // }
            TH.requestAreaBtn.bl02 = 'N' // B/L 분할
            TH.requestAreaBtn.bl03 = 'N' // 컨테이너 번호 변경 요청
            TH.requestAreaBtn.bl04 = 'N' // B/L Certificate
            TH.requestAreaBtn.bl05 = 'N' // VGM Input
            TH.requestAreaBtn.bl06 = 'N' // B/L Issue Request

            // 정산
            TH.requestAreaBtn.charge02 = 'N' // 운임 정정 요청
            TH.requestAreaBtn.charge03 = 'N' // Freetime 연장 요청

            // 운송
            TH.requestAreaBtn.trans01 = 'N' // 위험물 반입 요청
            TH.requestAreaBtn.trans02 = 'N' // Full 컨테이너 반출
            TH.requestAreaBtn.trans03 = 'N' // 셔틀 요청
            TH.requestAreaBtn.trans04 = 'N' // COD
            TH.requestAreaBtn.trans05 = 'N' // SHIPBACK
            TH.requestAreaBtn.trans06 = 'N' // 선적위치
          } else {
            // 현재 시간 계산1`
            let today = new Date()
            let year = today.getFullYear() //연도
            let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
            let date = ('0' + today.getDate()).slice(-2) //날짜
            let hour = ('0' + today.getHours()).slice(-2) //시간
            let time = ('0' + today.getMinutes()).slice(-2) //시간
            let now = year + month + date + hour + time

            if (searchList[0].polCtrCd === 'KR' && searchList[0].etd < now) {
              TH.requestAreaBtn.trans06 = 'Y' // 선적위치
            }

            if (searchList[0].polCtrCd === 'IN') {
              TH.requestAreaBtn.bl06 = 'Y' // B/L 발행요청

              if (this.memberDetail.staffFlag === 'Y') {
                TH.requestAreaBtn.report05 = 'Y'
              }
            }
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          // SG(싱가포르) 위험물 부킹일때, unconfirmed, checking 상태면 부킹 취소 불가
          if (searchList[0].polCtrCd === 'SG' && checkCnt === 1) {
            const sgDgParam = {
              bkgNo: searchList[0].bkgNo
            }

            let sgBookingDg = 'N'
            bookings.getSgBookingDgCheck(sgDgParam).then((res) => {
              if (res.data !== undefined) {
                console.log('getSgBookingDgCheck res @@@@ ', res)

                sgBookingDg = res.data

                if (sgBookingDg === 'Y') {
                  TH.requestAreaBtn.booking01 = 'N' // 부킹 취소
                }
              }
            })
          }
        })
      // dem/det JamSin
      nonFreight.getNonFreightBooking({ bkgNo: invBkgNo }).then(async result => {
        if (result.data.length > 0) {
          if (this.relYn) {
            this.nonFreightYn = 'Y' // non JamSin
          }
        }
        this.nonFreightList = result.data
      })
    },
    // dem/det JamSin
    async fnInvoicePop () {
      let params = {
        invoice: this.nonFreightList[0],
        blNo: this.nonFreightList[0].blNo,
        lang: this.auth.serviceLang,
        usrNm: '',
        ctrCd: this.memberDetail.userCtrCd,
        demDetCatCd: '02',
        eiCatCd: 'O'
      }
      this.openPopup('DemInvoicePop', params)
    },
    // 선적확정 처리
    async updateShprRspsYn (e, bkgNo) {
      e.preventDefault()
      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.074'), useConfirmBtn: true })) {
        return
      }

      const THIS = this

      shippingConfirm.shippingConfirm({ bkgNo: bkgNo }).then(res => {
        THIS.searchAct()
      })
    },
    //~ 선적확정 처리
    setGridData (list) {
      this.totalCount = list === undefined ? 0 : list.length
      this.list = list

      const tempPrevData = sessionStorage.getItem('working-progress-prev-data')
      const prevData = tempPrevData === null || tempPrevData === undefined ? undefined : JSON.parse(tempPrevData)

      if (prevData !== undefined) {
        this.txtFilter = prevData.txtFilter || ''
        this.onlyPickupYn = prevData.onlyPickupYn || 'N'
        this.onlyCancelYn = prevData.onlyCancelYn || 'N'
        this.changeFilter()
      } else {
        this.txtFilter = ''
        this.onlyPickupYn = 'N'
        this.onlyCancelYn = 'N'
        // provider.setRows(list)
        this.changeFilter()
      }

      if (prevData !== undefined) {
        if (prevData.checkedRows.length > 0) {
          gridView.checkItems(prevData.checkedRows, true)
          this.setRequestArea(gridView.getCheckedRows())
        }

        if (prevData.gridSelect !== undefined) {
          gridView.setCurrent(prevData.gridSelect)
        }
      }

      sessionStorage.removeItem('working-progress-prev-data')
    },
    // filter
    changeFilter () {
      if (this.list === undefined || this.list.length === 0) {
        this.gridList = this.list
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
        return
      }

      let arrFilter

      if (this.txtFilter.indexOf(',') !== -1) {
        arrFilter = this.txtFilter.split(',')
      } else {
        arrFilter = [this.txtFilter]
      }

      if (this.txtFilter === '') {
        // JamSin Filter
        gridView.setAllCheck(false)
        if (this.selCtr === '') {
          this.gridList = this.list.filter(vo => {
            let isPp = true
            let isCc = true
            if (this.searchParams.kind !== '02') {
              isPp = this.onlyPickupYn === 'N' ? vo.mtyPkupUsagYn !== 'Y' : vo.mtyPkupUsagYn === 'Y'
              isCc = this.onlyCancelYn === 'N' ? vo.bkgStsCd !== '03' : vo.bkgStsCd === '03'
            }
            return isPp && isCc
          })
        } else {
          this.gridList = this.list.filter(vo => {
            let isPp = true
            let isCc = true
            if (this.searchParams.kind !== '02') {
              isPp = this.onlyPickupYn === 'N' ? vo.mtyPkupUsagYn !== 'Y' : vo.mtyPkupUsagYn === 'Y'
              isCc = this.onlyCancelYn === 'N' ? vo.bkgStsCd !== '03' : vo.bkgStsCd === '03'
            }
            return isPp && isCc
          })
          if (this.selPo === 'POL') {
            this.gridList = this.gridList.filter(vo => vo.polCtrCd === this.selCtr)
          } else if (this.selPo === 'POD') {
            this.gridList = this.gridList.filter(vo => vo.podCtrCd === this.selCtr)
          }
        }
      } else {
        this.gridList = this.list.filter(vo => {
          const bkgNo = vo.bkgNo.toLowerCase()
          const blNo = vo.blNo === undefined ? '' : vo.blNo.toLowerCase()
          const srRno = vo.srRno === undefined ? '' : vo.srRno.toLowerCase()
          const vslCd = vo.vslCd === undefined ? '' : vo.vslCd.toLowerCase()
          const voyNo = vo.voyNo === undefined ? '' : vo.voyNo.toLowerCase()
          const vslNm = vo.vslNm === undefined ? '' : vo.vslNm.toLowerCase()
          const por = vo.por === undefined ? '' : vo.por.toLowerCase()
          const dly = vo.dly === undefined ? '' : vo.dly.toLowerCase()
          const cntrQty = vo.cntrQty === undefined ? '' : vo.cntrQty.toLowerCase()
          const actShprCstEnm = vo.actShprCstEnm === undefined ? '' : vo.actShprCstEnm.toLowerCase()
          const blStsNm = this.getFilterBlStsNm(vo)
          const bkgStsNm = this.getFilterBkgStsNm(vo)
          const cntrHz = this.getFilterCntrHz(vo)
          // const isPp = this.onlyPickupYn === 'N' || vo.mtyPkupUsagYn === 'Y'
          // const isCc = this.onlyCancelYn === 'N' || vo.bkgStsCd === '03'
          let isPp = true
          let isCc = true
          if (this.searchParams.kind !== '02') {
            isPp = this.onlyPickupYn === 'N' ? vo.mtyPkupUsagYn !== 'Y' : vo.mtyPkupUsagYn === 'Y'
            isCc = this.onlyCancelYn === 'N' ? vo.bkgStsCd !== '03' : vo.bkgStsCd === '03'
          }
          let txtFilter

          if (!isPp || !isCc) {
            return false
          }

          for (let i in arrFilter) {
            txtFilter = arrFilter[i].toLowerCase().replace(/^\s+|\s+$/g, '')
            if (txtFilter === '') {
              continue
            }
            if (bkgNo.indexOf(txtFilter) !== -1 || blNo.indexOf(txtFilter) !== -1 || srRno.indexOf(txtFilter) !== -1) {
              return true
            } else if (vslCd.indexOf(txtFilter) !== -1 || voyNo.indexOf(txtFilter) !== -1 || vslNm.indexOf(txtFilter) !== -1) {
              return true
            } else if (por.indexOf(txtFilter) !== -1 || dly.indexOf(txtFilter) !== -1) {
              return true
            } else if (cntrQty.indexOf(txtFilter) !== -1) {
              return true
            } else if (actShprCstEnm.indexOf(txtFilter) !== -1) {
              return true
            } else if (bkgStsNm.indexOf(txtFilter) !== -1 || blStsNm.indexOf(txtFilter) !== -1 || cntrHz.indexOf(txtFilter) !== -1) {
              return true
            }
          }
          return false
        })
      }
      this.totalCount = this.gridList.length
      provider.setRows(this.gridList)
    },
    getFilterBkgStsNm (vo) {
      const bkgStsCd = vo.bkgStsCd
      const bkgCnclIngYn = vo.bkgCnclIngYn
      let rtn = ''

      if (bkgStsCd === '00') {
        rtn = this.$t('msg.CSBK100.022') // 미승인
      } else if (bkgStsCd === '01') {
        if (bkgCnclIngYn === 'Y') {
          rtn = this.$t('msg.ONEX010T010.129') // 취소 처리중
        } else {
          rtn = this.$t('msg.CSBK100.023') // 승인
        }
      } else if (bkgStsCd === '02' || bkgStsCd === '05') {
        rtn = this.$t('msg.CSBK100.022') // 미승인
      } else if (bkgStsCd === '03') {
        rtn = this.$t('msg.CSBK100.024') // 취소
      } else if (bkgStsCd === '04') {
        if (bkgCnclIngYn === 'Y') {
          rtn = this.$t('msg.ONEX010T010.129') // 취소 처리중
        } else {
          rtn = this.$t('msg.CSBK100.023') // 승인
        }
      }

      return rtn.toLowerCase()
    },
    getFilterBlStsNm (vo) {
      const blNo = vo.blNo || ''
      const srRno = vo.srRno || ''
      const srCnclYn = vo.srCnclYn || ''
      const blCnclYn = vo.blCnclYn || ''
      const blStsCd = vo.blStsCd || ''
      const caStsCd = vo.caStsCd || ''
      let rtn = ''

      if (blNo === '' && srRno === '') {
        return rtn
      }

      if (srRno !== '' && blNo === '') {
        if (srCnclYn === 'Y') {
          rtn = this.$t('msg.CSBK100.024') // S/R Cancel
        } else {
          rtn = this.$t('msg.CSBK100.022') // 미승인
        }
      } else {
        if (blCnclYn === 'Y') {
          rtn = this.$t('msg.CSBK100.024') // 취소
        } else if (caStsCd !== '' && caStsCd !== '0' && caStsCd !== '4') {
          rtn = this.$t('msg.ONEX010T010.070') // C/A 확인
        } else if (blStsCd === '03') {
          rtn = this.$t('msg.CSBK100.023') // 승인
        } else {
          rtn = this.$t('msg.CSBK100.022') // 미승인
        }
      }

      return rtn.toLowerCase()
    },
    getFilterCntrHz (vo) {
      const dgCertiCnt = vo.dgCertiCnt
      const hzYn = vo.hzYn
      let rtn = ''

      if (dgCertiCnt > -1) {
        rtn = `DG Certi. (${dgCertiCnt})`
      } else if (hzYn === 'Y') {
        rtn = 'HZ'
      }

      return rtn.toLowerCase()
    },
    //~ filter
    changeColumn (e, chgType) {
      e.preventDefault()
      if (this.colType === chgType) {
        return
      }
      this.colType = chgType
      if (this.colType === 'doc') {
        gridView.setColumns(columns1)
        gridView.setColumnLayout(layout1)
      } else {
        gridView.setColumns(columns2)
        gridView.setColumnLayout(layout2)
      }
    },
    convertDate (strDt) {
      if (strDt === undefined || strDt === null || strDt === '') {
        return ''
      }
      const month = {
        K01: 'JAN',
        K02: 'FEB',
        K03: 'MAR',
        K04: 'APR',
        K05: 'MAY',
        K06: 'JUN',
        K07: 'JUL',
        K08: 'AUG',
        K09: 'SEP',
        K10: 'OCT',
        K11: 'NOV',
        K12: 'DEC'
      }
      const key = 'K' + strDt.substring(4, 6)
      const m = month[key] || ''
      const d = strDt.substring(6, 8)
      const t = strDt.substring(8, 10)
      return `${m} ${d} ${t}H`
    },
    keywordEnter (e) {
      if (e.keyCode === 13) {
        this.search()
      }
    },
    async showTooltip (e, id, type) {
      const ttId = type + '_tt_' + id
      $('#' + ttId).show()

      this.tooltipTopCheck(ttId)
    },
    tooltipTopCheck (ttId) {
      const objId = $('#' + ttId)
      const tbody = objId.parents('tbody').eq(0)
      const tr = objId.parents('tr').eq(0)
      const arrTr = tbody.find('> tr')
      const rnum = arrTr.index(tr) + 1
      const td = objId.closest('td').eq(0)
      const arrTd = tr.find('td')
      const tdIdx = arrTd.index(td)
      const width = parseInt($('#' + ttId.replace('_tt_', '_btn_')).css('width'))
      const calCnt = (arrTd.length > 14) ? 6 : 4
      const td_width = Math.round(((parseInt(td.css('width')) - width) / 2))
      if (tdIdx <= (arrTd.length - calCnt)) {
        $('#' + ttId).css({ left: (width + td_width - 3) + 'px' })
      } else {
        const orgWidth = parseInt(objId.css('width'))
        $('#' + ttId).css({ left: ((orgWidth - (width + 16)) * -1) + 'px' })
      }
      if (Math.round(arrTr.length / 2) < rnum) {
        const height = Math.round((parseInt($('#' + ttId).css('height')) / 2))
        $('#' + ttId).css({ top: ((height) * -1) + 'px' })
      }
    },
    hideTooltip (e, id, type) {
      const tid = e.relatedTarget.id || ''
      const btnId = type + '_btn_' + id
      const ttId = type + '_tt_' + id
      // console.log(e)
      // console.log('tid !== btnId', tid !== btnId)
      // console.log('tid !== ttId', tid !== ttId)
      // console.log('btnId.length', $(e.relatedTarget).parents('#' + btnId).length)
      // console.log('ttId.length', $(e.relatedTarget).parents('#' + ttId).length)
      if (tid !== btnId && tid !== ttId && $(e.relatedTarget).parents('#' + btnId).length === 0 && $(e.relatedTarget).parents('#' + ttId).length === 0) {
        $('#' + ttId).hide()
      }
    },
    async showTooltipEtcReq (e, id, type, idx) {
      const ttId = type + '_tt_' + id
      const blNo = this.gridList[idx].blNo
      const reqUno = this.gridList[idx].reqUno
      const reqCnt = this.gridList[idx].reqCnt

      let html = []
      let rvo = []
      const key = 'REQETC_' + idx
      let tempList = this.tempData[key]

      if (tempList === undefined && reqCnt > 0) {
        await exportSvc.getOnGoingReqColList(blNo).then(res => {
            tempList = res.data
            this.tempData[key] = tempList
          })
      }

      if (tempList !== undefined && tempList.length > 0) {
        // console.log('tempList @@@@@ ', tempList)
        let arrIdx = []

        await tempList.forEach(async (tvo, idx) => {
          if (tvo.apvStsCd === '01' && tvo.rmk !== undefined) {
            arrIdx.push(tvo)
          }
        })

        await arrIdx.forEach(async (tvo, idx) => {
          let rmk = tvo.rmk

          if (rmk !== undefined) {
            // 요청자 이메일 정보 조회
            let usrEmail = tvo.reqUsrEmail === undefined ? '' : tvo.reqUsrEmail + '(' + tvo.reqUno + ')'

            if (rmk.includes('\n')) {
              rmk = rmk.split('\n')

              await rmk.forEach((item, rdx) => {
                if (rdx === 0) {
                  html.push(`<strong>${rdx + 1}. ${item || ''}</strong>`)
                } else {
                  html.push(`<strong>${item || ''}</strong>`)
                }
              })

              html.push(usrEmail)
            } else {
              html.push(`<strong>${idx + 1}. ${rmk || ''}</strong>`)
              html.push(usrEmail)
            }
          }
        })
      }

      if (reqCnt > 0) {
          html.push('docsr@ekmtc.com')

          $('#' + ttId).find('.tt_cont').html(html.join('<br/>'))
          $('#' + ttId).show()
          this.tooltipTopCheck(ttId)
      }
    },
    // Booking No. (미승인 사유 Tooltip)
    async showTooltipBkg02 (e, id, type, bkgNo, docmRmk) {
      $('.bkg01_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'BKG_CNTR_STS_' + id
      let tempList = this.tempData[key]

      if (tempList === undefined) {
        const params = {
          bkgNo: bkgNo
        }

        await exportSvc.getOnGoingBkgCntrSts(params).then(async res => {
            tempList = res.data
            this.tempData[key] = tempList
          })
      }

      let html = ''
      let htmlSnd = ''

      // document Remark 있으면 표시
      if (docmRmk !== undefined && docmRmk.length > 0) {
        htmlSnd = '<br/><br/>'
        htmlSnd += 'Document Remark <br/>'
        htmlSnd += `<span style="width:max-content; max-width:360px; word-break: break-all;">${docmRmk}</span>`
      } else {
        htmlSnd = ''
      }

      if (tempList !== undefined && tempList.length > 0) {
        html = tempList.map(tvo => {
          let apvStsNm = this.getCodeNm('01043', tvo.apvStsCd) || ''
          let msg = ''
          if (tvo.apvStsCd === '02' && tvo.cgoTypCd === '01') {
            msg = this.$t('msg.ONEX010T010.BKG_CNTR_STS_02_HZ')
          } else {
            msg = this.$t('msg.ONEX010T010.BKG_CNTR_STS_' + tvo.apvStsCd)
          }

          if (htmlSnd !== undefined && htmlSnd.length > 0) {
            msg += htmlSnd
          }

          return `<span class="color_blue">- ${apvStsNm}</span><br/>
            &nbsp; ${msg}`
        }).join('<br/>')
      } else {
        if (htmlSnd !== undefined && htmlSnd.length > 0) {
            html = htmlSnd
        }
      }

      if (html !== '') {
        $('#' + type + '_tt_' + id).find('.tt_cont').html(html)
        $('#' + ttId).show()
        this.tooltipTopCheck(ttId)
      }
    },
      // Booking No. (미승인 사유 Tooltip)
      async showTooltipBkg02JP (e, id, type, bkgNo, docmRmk, waitRsnCd, waitRsnDsc, waitRsnRmk, bkgStsCd) {
      $('.bkg01_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'BKG_CNTR_STS_' + id
      let tempList = this.tempData[key]

      if (tempList === undefined) {
        const params = {
          bkgNo: bkgNo
        }

        await exportSvc.getOnGoingBkgCntrSts(params).then(async res => {
            tempList = res.data
            this.tempData[key] = tempList
          })
      }
      let html = ''
      let htmlSndReason = ''
      // Booking Wating Reason 있으면 표시
       if (waitRsnDsc !== undefined && waitRsnDsc.length > 0 && bkgStsCd === '02') {
        if (tempList !== undefined && tempList.length > 0) {
          htmlSndReason = '<br/><br/>'
        } else {
          htmlSndReason = '<br/>'
        }
        //htmlSndReason += 'Booking Wating Reason <br/>'
        if (waitRsnCd !== '05') {
          //htmlSndReason += `<span class="color_blue">-Checking ${waitRsnDsc}</span><br/>`
          htmlSndReason += `<span style="width:max-content; max-width:360px; word-break: break-all;">Checking ${waitRsnDsc}</span></br>`
        } else {
          htmlSndReason += `<span style="width:max-content; max-width:360px; word-break: break-all;">${waitRsnRmk}</span></br>`
        }
      } else {
        htmlSndReason = ''
      }
      let htmlSnd = ''
      // document Remark 있으면 표시
      //if (docmRmk !== undefined && docmRmk.length > 0) {
        //htmlSnd = '<br/>'
        //htmlSnd += 'Document Remark <br/>'
        //htmlSnd += `<span style="width:max-content; max-width:360px; word-break: break-all;">${docmRmk}</span>`
      //} else {
       // htmlSnd = ''
     // }
      let msg = ''
      if (tempList !== undefined && tempList.length > 0) {
        html = tempList.map(tvo => {
          let apvStsNm = this.getCodeNm('01043', tvo.apvStsCd) || ''
          if (tvo.apvStsCd === '02' && tvo.cgoTypCd === '01') {
            msg = this.$t('msg.ONEX010T010.BKG_CNTR_STS_02_HZ')
          } else {
            msg = this.$t('msg.ONEX010T010.BKG_CNTR_STS_' + tvo.apvStsCd)
          }
          if (htmlSndReason !== undefined && htmlSndReason.length > 0) {
            msg += htmlSndReason
          }
          //if (htmlSnd !== undefined && htmlSnd.length > 0) {
          //  msg += htmlSnd
          //}

          return `<span class="color_blue">- ${apvStsNm}</span><br/>
            &nbsp; ${msg}`
        }).join('<br/>')
      } else {
        if (htmlSndReason !== undefined && htmlSndReason.length > 0) {
            html += htmlSndReason
        }
        //if (htmlSnd !== undefined && htmlSnd.length > 0) {
           // html += htmlSnd
        //}
      }

      if (html !== '') {
        $('#' + type + '_tt_' + id).find('.tt_cont').html(html)
        $('#' + ttId).show()
        this.tooltipTopCheck(ttId)
      }
    },
    async showTooltipBlTax (e, id, type, blNo) {
      $('.docs02_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'BLTAX_' + id
      let tempList = this.tempData[key]

      if (tempList === undefined) {
        const params = {
          blNo: blNo
        }

        await exportSvc.getOnGoingBlTax(params).then(async res => {
            tempList = res.data
            this.tempData[key] = tempList
          })
      }

      let html = []
      if (tempList !== undefined && tempList.length > 0) {
        const ttl1 = this.$t('msg.ONEX010T010.117') // 계산서번호
        const ttl2 = this.$t('msg.ONEX010T010.118') // 금액
        const ttl3 = this.$t('msg.ONEX010T010.119') // 상태
        const ttl4 = this.$t('msg.ONEX010T010.120') // 발급 Email
        const ttl5 = this.$t('msg.ONEX010T010.121') // 입금표번호
        tempList.forEach(tvo => {
          if (this.lang === 'KOR') {
            html.push(`${ttl1} : ${tvo.acshRno || ''}`)
            html.push(`${ttl2} : ${this.$ekmtcCommon.changeNumberFormat(tvo.slprcAmt || '0', { isComma: true })}`)
            html.push(`${ttl3} : ${this.getCodeNm('01113', tvo.snreStsCd)}`)
            html.push(`${ttl4} : ${tvo.recptEmlAddr || ''}`)
          } else {
            html.push(`${ttl5} : ${tvo.rcvRno || ''}`)
            html.push(`${ttl2} : ${this.$ekmtcCommon.changeNumberFormat(tvo.slprcAmt || '0', { isComma: true })}`)
          }
        })
      }

      $('#' + type + '_tt_' + id).find('.tt_cont').html(html.join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    async showTooltipCaSts (e, id, type, blNo, reqDtm, caStsCd, userInfo) {
      $('.blca_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'CASTS_' + id
      let tempList = this.tempData[key]
      let temPicList = this.tempPicList[key]
      let resVo = []

      if (tempList === undefined) {
        const params = {
          blNo: blNo,
          reqDtm: reqDtm,
          gubun: 'S'
        }

        await exportSvc.getOnGoingCaReject(params).then(res => {
            tempList = res.data
            this.tempData[key] = tempList
        })
      }

      if (temPicList === undefined) {
        if (this.$ekmtcCommon.isNotEmpty(this.tempData) && this.$ekmtcCommon.isNotEmpty(this.tempData[key])) {
          this.picInfo = await this.userListFnc(this.tempData[key])
        }
      }

      const html = []
      html.push(this.$t('msg.ONEX010T010.CA_STS_CD_' + caStsCd))
      if (tempList !== undefined && tempList.length > 0) {
        tempList.forEach(tvo => {
          if (tvo.rmk !== undefined && tvo.rmk !== '') {
            html.push('-' + tvo.rmk)
          }
        })
      }

      if (this.picInfo) {
        const name = this.picInfo.usrNm !== undefined ? this.picInfo.usrNm : this.picInfo.usrEnm //userInfo.split(';')[0]
        const picEmail = this.$ekmtcCommon.isEmpty(userInfo.split(';')[1]) ? this.picInfo.usrEmail : this.memberDetail.userCtrCd !== 'KR' ? this.picInfo.usrEmail : userInfo.split(';')[1]
        const phone = userInfo.split(';')[2]

        html.push(this.$t('msg.CSBK100.129') + ' : ' + (this.$ekmtcCommon.isNotEmpty(name) ? name : ''))
        html.push('email : ' + picEmail)
        html.push('TEL : ' + (this.$ekmtcCommon.isNotEmpty(this.picInfo.usrPhone) ? this.picInfo.usrPhone : ''))
      }

      // if (userInfo) {
      //   html.push(this.$t('msg.CSBK100.129') + ' : ' + name)
      //   // html.push('email :  DOCCA@EKMTC.COM')
      //   html.push('email :  ' + email)
      //   html.push('TEL : ' + phone)
      // }

      $('#' + type + '_tt_' + id).find('.tt_cont').html(html.join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    async showTooltipCaSts2 (e, id, type, blNo, reqDtm, caStsCd, userInfo, recentCaStsNm) {
      $('.blca_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'CASTS_' + id
      let tempList = this.tempData[key]
      let temPicList = this.tempPicList[key]
      let resVo = []

      if (tempList === undefined) {
        const params = {
          blNo: blNo,
          reqDtm: reqDtm,
          gubun: 'S'
        }

        await exportSvc.getOnGoingCaReject(params).then(res => {
          tempList = res.data
          this.tempData[key] = tempList
        })
      }

      if (temPicList === undefined) {
        if (this.$ekmtcCommon.isNotEmpty(this.tempData) && this.$ekmtcCommon.isNotEmpty(this.tempData[key])) {
          this.picInfo = await this.userListFnc(this.tempData[key])
        }
      }

      const html = []
      html.push(this.$t('msg.ONEX010T010.CA_STS_CD_' + caStsCd))
      if (tempList !== undefined && tempList.length > 0) {
        tempList.forEach(tvo => {
          if (tvo.rmk !== undefined && tvo.rmk !== '') {
            html.push('-' + tvo.rmk)
          }
        })
      }

      if (this.picInfo) {
        const name = this.picInfo.usrNm !== undefined ? this.picInfo.usrNm : this.picInfo.usrEnm //userInfo.split(';')[0]
        const picEmail = this.$ekmtcCommon.isEmpty(userInfo.split(';')[1]) ? this.picInfo.usrEmail : this.memberDetail.userCtrCd !== 'KR' ? this.picInfo.usrEmail : userInfo.split(';')[1]
        const phone = userInfo.split(';')[2]

        html.push(this.$t('msg.CSBK100.129') + ' : ' + (this.$ekmtcCommon.isNotEmpty(name) ? name : ''))
        html.push('email : ' + picEmail)
        html.push('TEL : ' + (this.$ekmtcCommon.isNotEmpty(this.picInfo.usrPhone) ? this.picInfo.usrPhone : ''))
      }

      if (this.$ekmtcCommon.isNotEmpty(recentCaStsNm)) {
        html.push('C/A Status : ' + recentCaStsNm)
      }

      $('#' + type + '_tt_' + id).find('.tt_cont').html(html.join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    async showTooltipVslNm (e, id, type) {
      const ttId = type + '_tt_' + id
      const key = 'VSLNM_' + id
      let tempList = this.tempData[key]
      if (tempList === undefined) {
        const params = {
          blNo: id
        }

        await importSvc.getVesselChangeReason(params).then(async res => {
          tempList = res.data
          this.tempData[key] = tempList
        })
      }
      let html = []
      let logCont = []

      if (this.$ekmtcCommon.isNotEmpty(tempList)) {
        logCont = tempList.substring(tempList.indexOf('[') + 1, tempList.indexOf(']'))
        html.push('VESSEL CHANGE REASON')
        html.push(`: ${logCont}`)

        $('#' + ttId).find('.tt_cont').html(html.join('<br/>'))
        $('#' + ttId).show()
        this.tooltipTopCheck(ttId)
      }
    },
    async userListFnc (rvo) {
      const THIS = this
      let vo = {}

      await myRequest.getUserInfo({ apvUno: rvo[0].apvUno }).then(res => {
        vo = res.data
      })
      return vo
    },
    async userIngListFnc (rvo) {
      console.log('userIngListFnc rvo >>> ', rvo)
      const THIS = this
      await myRequest.getUserIngInfo({ blNo: rvo.blNo, ntcCd: '02' }).then(res => {
        const vo = res.data
        if (vo) {
          console.log(vo.myRequestUserList)
        }
      })
    },
    // 컨테이너 정보 Tooltip
    async showTooltipCntrInfo (e, id, colType, bkgNo, blNo) {
      $('.cntr_tt').hide()
      const ttId = colType + '_tt_' + id
      // const key = 'CNTR_' + id
      let type = ''

      if (colType === 'cntr01') {
        type = 'PKU'
      } else if (colType === 'cntr02') {
        type = 'GTI'
      } else if (colType === 'cntr03') {
        type = 'LDG'
      } else if (colType === 'cntr04') {
        type = 'DIS'
      } else if (colType === 'cntr05') {
        type = 'GTO'
      } else if (colType === 'cntr06') {
        type = 'RTN'
      }

      const key = 'CNTR_' + id + '_' + type
      let tvo = this.tempData[key]

      if (tvo === undefined) {
        const params = {
          bkgNo: bkgNo,
          blNo: blNo,
          type: type
        }

        await exportSvc.getOnGoingCntrInfo(params).then(async res => {
            tvo = res.data
            this.tempData[key] = tvo
          })
      }

      let text = ''

      if (colType === 'cntr01') {
        text = tvo.cntrInfoPku || ''
      } else if (colType === 'cntr02') {
        text = tvo.cntrInfoGti || ''
      } else if (colType === 'cntr03') {
        text = tvo.cntrInfoLdg || ''
      } else if (colType === 'cntr04') {
        text = tvo.cntrInfoDis || ''
        if (text !== '' && text.indexOf(',') > -1) {
          const arr = text.split(',').sort()
          const len = arr.length
          const arrTemp = []
          for (let i = 0; i < len; i++) {
            if (i > 0 && i % 3 === 0) {
              arrTemp.push('<br/>')
            }
            arrTemp.push(arr[i])
            if (i < len - 1) {
              arrTemp.push(', ')
            }
          }
          text = arrTemp.join('')
        }
      } else if (colType === 'cntr05') {
        text = tvo.cntrInfoGto || ''
      } else if (colType === 'cntr06') {
        text = tvo.cntrInfoRtn || ''
      }

      $('#' + colType + '_tt_' + id).find('.tt_cont').html(text.split('\n').join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    // 위험물 반입 (미승인 사유 Tooltip)
    async showTooltipWarn (e, id, type, bkgNo, docmRmk) {
      $('.bkg01_tt').hide()
      const ttId = type + '_tt_' + id

      let html = ''
      let htmlSnd = ''

      // document Remark 있으면 표시
      if (docmRmk !== undefined && docmRmk.length > 0) {
        htmlSnd = '<br/>'
        htmlSnd += `<a href="#" onclick="vmAppWpx.movePageMyRequest(event, '${bkgNo}')"><span style="color:blue"><u>${docmRmk}</u></span></a>`
      } else {
        htmlSnd = ''
      }

      if (htmlSnd !== undefined && htmlSnd.length > 0) {
        html = htmlSnd
      }

      if (html !== '') {
        $('#' + type + '_tt_' + id).find('.tt_cont').html(html)
        $('#' + ttId).show()
        this.tooltipTopCheck(ttId)
      }
    },
    movePageMyRequest (e, bkgNo) {
      this.$router.push({ name: 'my-request', params: { initBookingNo: bkgNo } }).catch(() => {})
    },
    closeCntrInfo (e, bkgNo, blNo, type) {
      e.preventDefault()
      const id = bkgNo + '_' + blNo
      const infoId = type + '_tt_' + id
      $('#' + infoId).hide()
    },
    closeTooltip (e, id, type) {
      e.preventDefault()
      const ttId = type + '_tt_' + id
      $('#' + ttId).hide()
    },
    findGridData (paramBkgNo, paramBlNo) {
      const vo = this.list.find(tvo => {
        const bkgNo = tvo.bkgNo
        const blNo = tvo.blNo || ''
        return bkgNo === paramBkgNo && blNo === paramBlNo
      })

      return vo
    },
    // VGM tooltip
    async fnClickDocs00 (e, bkgNo, bkgAcpnMethCd, blNo, type) {
      const containerInfo = await srInquiry.getVgmContainerInfo(bkgNo)
      this.commonBlCallback = ''

      if (type === 'VGMPOP_SAVE' || type === 'VGMPOP_OPEN') {
        const vgmFlag = type.split('_')[1]
        this.commonBlParams = {
          action: 'VGMPOP',
          vgmFlag: vgmFlag,
          bkgNo: bkgNo,
          blNo: blNo,
          methCd: bkgAcpnMethCd,
          originCntrList: containerInfo.data.containerList,
          tareModifyYn: 'Y'
        }

        if (vgmFlag === 'SAVE') {
          this.commonBLCallback = this.refresh
        }
      }

      if (this.$refs.commonBL === undefined) {
        this.commonBlComponent = 'common-bL'
      } else {
        this.$nextTick(() => {
          this.$refs.commonBL.initPageFlag()
          this.$refs.commonBL.doAction()
        })
      }
    },
    // 서류마감 tooltip
    async fnClickDocs01 (e, bkgNo, srRno, blNo, type) {
      e.preventDefault()
      const goParams = {
        bkgNo: bkgNo,
        srRno: srRno,
        blNo: blNo
      }
      this.goDetailPage('SR_EDIT', goParams)
    },
    // 서류마감 tooltip
    async fnClickDocs03 (e, bkgNo, srRno, blNo, type, issueStatus) {
      e.preventDefault()
      const goParams = {
        bkgNo: bkgNo,
        srRno: srRno,
        blNo: blNo
      }
      if (type === 'BL_ISSUE') {
        const arrBlNo = [blNo]
        this.goCalcIssueMain(arrBlNo)
      } else if (type === 'BL_CERTI') {
        this.goDetailPage('BL_CERTIFICATE', goParams)
      } else if (type === 'BL_COPY') {
        const items = [
          { blNo: blNo, porDlyYn: 'N', noCnee: 'Y', noNotify: 'Y' }
        ]

        const params = {
          searchList: { blNo: blNo, bkgNo: bkgNo, userId: this.auth.userId }
        }
        let isAuth = true
        await exportSvc.getOnGoingRequestBtn(params).then(async res => {
          const authInfo = res.data
          if (authInfo.report04 !== 'Y') {
            isAuth = false
          }
        }).catch(err => {
          console.log(err)
        })

        if (!isAuth) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') }) // 권한이 없습니다.
          return
        }

        this.blCopyPopCall(items)
      } else if (type === 'OBL_RE') {
        const params = {
          searchList: { blNo: blNo, bkgNo: bkgNo, userId: this.auth.userId }
        }
        let isAuth = true
        await exportSvc.getOnGoingRequestBtn(params).then(async res => {
          const authInfo = res.data
          if (authInfo.report04 !== 'Y') {
            isAuth = false
          }
        }).catch(err => {
          console.log(err)
        })
        if (!isAuth) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') }) // 권한이 없습니다.
          return
        }
        this.originalBLReIssuePopCall(blNo)
      } else if (type === 'SUR_TEL') {
        const params = {
          searchList: { blNo: blNo, bkgNo: bkgNo, userId: this.auth.userId }
        }
        // let isAuth = true
        // await exportSvc.getOnGoingRequestBtn(params).then(async res => {
        //   const authInfo = res.data
        //   if (authInfo.report04 !== 'Y') {
        //     isAuth = false
        //   }
        // }).catch(err => {
        //   console.log(err)
        // })
        // if (!isAuth) {
        //   this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') }) // 권한이 없습니다.
        //   return
        // }

        let arrBlNo = []
        arrBlNo.push(blNo)

        this.$refs.commonUnsolved.openBlPaymentPop(arrBlNo, 'O', '', 'SUR_TEL')
      } else {
        alert(type)
      }
    },
    async fnClickDocs04 (e, bkgNo, srRno, blNo, podCtrCd, type) {
      e.preventDefault()
      const params = {
        searchList: { blNo: blNo, bkgNo: bkgNo, userId: this.auth.userId }
      }
      let isAuth = true
      await exportSvc.getOnGoingRequestBtn(params).then(async res => {
        const authInfo = res.data
        if (authInfo.report04 !== 'Y') {
          isAuth = false
        }
      }).catch(err => {
        console.log(err)
      })

      if (!isAuth) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') }) // 권한이 없습니다.
        return
      }

      this.arrivalNoticePopCall([{ bkgNo: bkgNo, blNo: blNo }], podCtrCd)
    },
    // 운임 tooltip
    async fnClickDocs02 (e, bkgNo, srRno, blNo, type) {
      e.preventDefault()
      // console.log(bkgNo, blNo, type)

      if (type === 'FRT_REQUEST') {
        // 운임등록 요청
        if (!this.$ekmtcCommon.isNotEmpty(blNo)) {
          //this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX070G050.014') })
          return
        }
        this.openPopup('FareRegisReqPop', { blNo: blNo, index: '', invRno: '', rtnCode: '' })
      } else if (type === 'FRT_POPUP') {
        // 미정산 내역 팝업

        //CD_ID = 01007 (02: 운임, 30: 수출 ALL)
        let delegateYn = 'N'
        await transCommon.getBlDelegateYn({ blNo, arrCstGrpCatCd: ['02', '30'] }).then(async (res) => {
          if (res.data === 'Y') {
            delegateYn = 'Y'
          }
        })

        await trans.getBlPaymentAccountlessList({ blNo, delegateYn })
          .then(async (response) => {
            const list = response.data.paymentAccountlessList
            if (list.length > 0) {
              //셋팅할 데이터 to array
              list.map((item) => {
                  item.totalAmt = item.occrCurCd + ' ' + item.occrAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  item.locAmtDesc = item.occrCurCd + ' ' + item.occrAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              })
              const params = {
                blNo: blNo,
                list: list,
                eiCatCd: 'O'
              }
              this.openPopup('BLPaymentAccountlessPop', params)
            } else {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
            }
          })
      } else {
        alert('운임 : ' + type)
      }
    },
    getData (paramBkgNo, paramSrRno, paramBlNo) {
      const vo = this.list.find(tvo => {
        const bkgNo = tvo.bkgNo
        const srRno = tvo.srRno || ''
        const blNo = tvo.blNo || ''
        return bkgNo === paramBkgNo && srRno === paramSrRno && blNo === paramBlNo
      })

      return vo
    },
    // 출발지/도착지 스케줄 정보
    porDlySchedulePopCall (e, paramBkgNo) {
      e.preventDefault()
      const params = {
        bkgNo: paramBkgNo,
        kind: '02'
      }
      this.openPopup('PorDlySchedulePop', params)
    },
    // 위험물반입신고 요청서 팝업
    dangerDocReqPopCall (bkgNo, blNo) {
      // const params = {
      //   bkgNo: bkgNo,
      //   blNo: blNo,
      //   kind: blNo === '' ? 'BK' : 'BL'
      // }
      const params = {
        bkgNo: bkgNo,
        kind: 'BK'
      }
      this.openPopup('DangerDocReqPop', params)
    },
    // 선박정보 및 스케줄
    async vesselScheduleInfoPopCall (e, bkgNo, srRno, blNo) {
      e.preventDefault()
      const vo = this.getData(bkgNo, srRno, blNo)
      const params2 = {
        mobileYN: 'Y',
        startDate: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(0, 8),
        endDate: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(0, 8),
        startCtrCd: vo.polCtrCd,
        startPlcCd: vo.polPortCd,
        destCtrCd: vo.podCtrCd,
        destPlcCd: vo.podPortCd,
        searchYear: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(0, 4),
        searchMonth: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(4, 6),
        bound: 'O',
        calendarOrList: 'C'
      }
      let bkgDocCls = ''
      let bkgMfCls = ''
      let bkgCgoCls = ''
      let cfsCls = ''
      let polTrmlCd = ''
      let longParam = ''

      await schedule.getLegSchedule(params2).then((rtnData) => {
        const listSchedule = rtnData.data.listSchedule
        for (const item of listSchedule) {
          if (item.vslCd === vo.vslCd && item.voyNo === vo.voyNo) {
            polTrmlCd = item.polTml
            bkgDocCls = this.$ekmtcCommon.changeDatePattern(item.bkgDocCls, '.')
            bkgMfCls = this.$ekmtcCommon.changeDatePattern(item.bkgMfCls, '.')
            bkgCgoCls = this.$ekmtcCommon.changeDatePattern(item.bkgCgoCls, '.')
            cfsCls = this.$ekmtcCommon.changeDatePattern(item.cfsCls, '.')
            longParam = item.ts + ';' +
              item.vslNm + ';' +
              item.vslCd + ';' +
              item.voyNo + ';' +
              item.transitTime + ';' +
              item.polEtb + ';' +
              item.polEtbTm + ';' +
              item.etd + ';' +
              item.etdTm + ';' +
              item.eta + ';' +
              item.etaTm + ';' +
              item.polTml + ';' +
              item.podTml + ';' +
              item.polPicTel + ';' +
              item.itrmlNm + ';' +
              item.podPicTel + ';' +
              item.closeTime + ';' +
              item.cct + ';' +
              item.mrnNo + ';' +
              item.callSign + ';' +
              item.bkgClose + ';' +
              item.mfCls + ';' +
              item.webCls + ';' +
              item.cgoCls + ';' +
              item.jrstCstm + ';' +
              item.info + ';' +
              item.pol + ';' +
              item.pod + ';' +
              item.bkgDocCls + ';' +
              item.bkgCgoCls + ';' +
              item.bkgMfCls + ';' +
              item.cfsCls + ';' +
              item.pod1 + ';' +
              item.podEtd1 + ';' +
              item.podTrml1 + ';' +
              item.podPicTel1 + ';' +
              item.etaTm1 + ';' +
              item.transitTime1 + ';' +
              item.pol1 + ';' +
              item.vslNm2 + ';' +
              item.vslCd2 + ';' +
              item.voyNo2 + ';' +
              item.polEtd2 + ';' +
              item.podEtd2 + ';' +
              item.polTrml2 + ';' +
              item.podTrml2 + ';' +
              item.polPicNm2 + ';' +
              item.polPicTel2 + ';' +
              item.podPicTel2 + ';' +
              item.etdTm2 + ';' +
              item.etaTm2 + ';' +
              item.transitTime2 + ';' +
              item.tsDegree + ';' +
              item.vslNm3 + ';' +
              item.vslCd3 + ';' +
              item.voyNo3 + ';' +
              item.polEtd3 + ';' +
              item.etdTm3 + ';' +
              item.polTrml3 + ';' +
              item.polPicTel3 + ';' +
              item.podEtd3 + ';' +
              item.etaTm3 + ';' +
              item.podTrml3 + ';' +
              item.podPicTel3 + ';' +
              item.transitTime3 + ';' +
              item.vslNm4 + ';' +
              item.vslCd4 + ';' +
              item.voyNo4 + ';' +
              item.polEtd4 + ';' +
              item.etdTm4 + ';' +
              item.polTrml4 + ';' +
              item.polPicTel4 + ';' +
              item.podEtd4 + ';' +
              item.etaTm4 + ';' +
              item.podTrml4 + ';' +
              item.podPicTel4 + ';' +
              item.transitTime4 + ';' +
              item.polTrml1 + ';' +
              item.pol2 + ';' +
              item.pod3 + ';' +
              item.pod4 + ';' +
              item.pod2 + ';' +
              item.bkgDocPic + ';' +
              item.bkgCgoPic + ';' +
              item.vslNm1 + ';' +
              item.pol3 + ';' +
              item.pol4 + ';' +
              item.bkgCfsPic + ';' +
              item.bkgVslCd + ';' +
              item.bkgVoyNo + ';' +
              item.polCtrCd + ';' +
              item.rteCd
          }
        }
      })

      const detailParam = {
        param: longParam,
        bound: 'O'
      }

      let mfCls = ''
      if (this.$ekmtcCommon.isNotEmpty(longParam)) {
        await schedule.getLegScheduleDetail(detailParam).then((rtnData) => {
          mfCls = this.$ekmtcCommon.changeDatePattern(rtnData.data.bkgMfCls, '.')
        })
      }

      const params = {
        rteCd: vo.rteCd,
        polCtrCd: vo.polCtrCd,
        menuCd: '01',
        vslCd: vo.vslCd,
        voyNo: vo.voyNo,
        polPortCd: vo.polPortCd,
        podPortCd: vo.podPortCd,
        polTrmlCd: polTrmlCd,
        bound: 'O', // 수출: O, 수입: I
        bkgDocCls: bkgDocCls,
        bkgMfCls: this.$ekmtcCommon.isNotEmpty(mfCls) ? mfCls : bkgMfCls,
        bkgCgoCls: bkgCgoCls,
        cfsCls: cfsCls
      }
      this.openPopup('VesselScheduleInfoPop', params)
    },
    // 수출 담당자 연락처
    async exportManagerPopCall (bkgNo, srRno, blNo, anYn, podCtrCd, rpaCfmYn) {
      const vo = this.getData(bkgNo, srRno, blNo)
      const params2 = {
        mobileYN: 'N',
        startDate: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(0, 8),
        endDate: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(0, 8),
        startCtrCd: vo.polCtrCd,
        startPlcCd: vo.polPortCd,
        destCtrCd: vo.podCtrCd,
        destPlcCd: vo.podPortCd,
        searchYear: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(0, 4),
        searchMonth: vo.rvsdEndpDtm === undefined ? vo.fstEntDtm.substring(0, 8) : vo.rvsdEndpDtm.substring(4, 6),
        bound: 'O',
        calendarOrList: 'C'
      }

      let apvUno = ''

      // BL 수정 담당자 조회
      if (!this.$ekmtcCommon.isEmpty(vo.blNo)) {
        await exportSvc.getBlManagerInfo(vo).then(res => {
          console.log('getBlManagerInfo >>>> ', res)
          apvUno = res.data
        })
      } else {
        apvUno = ''
      }

      let bkgDocCls = '' // 서류마감, VGM마감
      let bkgMfCls = '' // EDI마감
      let bkgCgoCls = '' // 컨테이너마감
      let cfsCls = '' // CFS마감
      let vgmDocCls = '' // CFS마감
/*
      await schedule.getLegSchedule(params2).then((rtnData) => {
        const listSchedule = rtnData.data.listSchedule
        //console.log('result = ' + listSchedule)
        for (const item of listSchedule) {
          if (item.vslCd === vo.vslCd && item.voyNo === vo.voyNo) {
            bkgDocCls = this.$ekmtcCommon.changeDatePattern(item.bkgDocCls, '.')
            bkgMfCls = this.$ekmtcCommon.changeDatePattern(item.bkgMfCls, '.')
            bkgCgoCls = this.$ekmtcCommon.changeDatePattern(item.bkgCgoCls, '.')
            cfsCls = this.$ekmtcCommon.changeDatePattern(item.cfsCls, '.')
            vgmDocCls = this.$ekmtcCommon.changeDatePattern(item.vgmDocCls, '.')
          }
        }
*/
        const params = {
          bkgNo: bkgNo,
          blNo: vo.blNo || '',
          vslCd: vo.vslCd,
          voyNo: vo.voyNo,
          polShtmCd: vo.polShtmCd,
          polPortCd: vo.polPortCd,
          podPortCd: vo.podPortCd,
          polTrmlCd: vo.polTrmlCd,
          anStatus: anYn,
          bound: 'O',
          bkgDocCls: bkgDocCls,
          bkgMfCls: bkgMfCls,
          bkgCgoCls: bkgCgoCls,
          cfsCls: cfsCls,
          apvUno: apvUno,
          podCtrCd: podCtrCd,
          polCtrCd: vo.polCtrCd,
          vgmDocCls: vgmDocCls,
          rpaCfmYn: rpaCfmYn
        }

        this.openPopup('ExportManagerPop', params)
    //  })
    },
    freSurchargePopCall (e, polPlcCd, polCtrCd, podPlcCd, podCtrCd, frtAppNo, polPortNm, podPortNm, etd, eta) {
      if (frtAppNo === '' || frtAppNo === 'undefined') {
        this.$ekmtcCommon.alertDefault(this.$t('운임 번호가 없는 부킹은 운임 조회가 불가능합니다.'))
        return
      }

      this.surChargeParams = {
        porCtrCd: polCtrCd,
        porPlcCd: polPlcCd,
        dlyCtrCd: podCtrCd,
        dlyPlcCd: podPlcCd,
        frtAppNo: frtAppNo,
        frtResult: 'Y',
        polNm: polPortNm,
        podNm: podPortNm,
        startDt: etd,
        endDt: eta,
        frtEstnYn: 'I',
        frtExtsStsCd: '00',
        cstBidgMgrNo: '',
        cntrTypCd: 'GP',
        hotDealYn: 'N'
      }
      this.freSurchargeOpenPopup('FreSurchargePop')
    },
    freSurchargeOpenPopup (compNm) {
      if (this.frtSurchargeComponent !== null) {
        this.frtSurchargeComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
      }
      this.frtSurchargeComponent = compNm
      this.$ekmtcCommon.layerOpen('.my_surcharge_pop')
    },
    freSurchargeClosePopup (obj) {
      this.frtSurchargeComponent = null
      this.$ekmtcCommon.layerClose('.my_surcharge_pop')
    },
    async caRequestPopCall (blNo, caCfmYn) {
      const res = await trans.getCaRequestInfo(blNo)
      const rvo = res.data
      if (rvo) {
        let isReqAreaShow = false
        if (String(rvo.pencount) !== '0') {
          if (this.lang === 'KOR') {
           //await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL100_M1.031') })
          }
        } else if (String(rvo.temcount) !== '0') {
          isReqAreaShow = true
        }
        if (this.$ekmtcCommon.isNotEmpty(rvo.mfClosDt) && rvo.mfClosDt < rvo.toDayDt) {
          rvo.mfClosDtYn = 'Y'
        }

        let blIssYn = ''
        const surrYn = rvo.surrYn
        if (rvo.blIssYn === 'Y' && rvo.blCatCd === '01') {
          blIssYn = 'Y'
        }
        const params = {
          blNo: blNo,
          blIssYn: blIssYn,
          surrYn: surrYn,
          mfCloseDtYn: rvo.mfClosDtYn,
          tempCaNo: rvo.tempCaNo,
          isReqAreaShow: isReqAreaShow,
          caCfmYn: caCfmYn
        }
        const TH = this
        this.popupTp3Callback = (p) => {
          TH.search(TH.searchParams)
        }
        this.openTp3Popup('CaRequestPop', params)
      }
    },
    // 컨테이너 번호변경 요청서 팝업
    cntrNoCorrectReqPopCall (bkgNo, srRno, blNo) {
      const vo = this.getData(bkgNo, srRno, blNo)

      const params = {
        blNo: vo.blNo,
        bkgNo: vo.bkgNo
      }
      this.openPopup('CntrNoCorrectReqPop', params)
    },
    // Full 컨테이너 반출 요청서 팝업
    fullContainerOutReqPopCall (bkgNo, srRno, blNo) {
      const vo = this.getData(bkgNo, srRno, blNo)

      const params = {
        bkgNo: vo.bkgNo
      }
      this.openPopup('FullContainerOutReqPop', params)
    },
    freeTimeExtensReqPopCall (bkgNo, srRno, blNo) {
      const vo = this.getData(bkgNo, srRno, blNo)
      const query = {
        eiCatCd: 'O',
        dtKnd: 'BL',
        num: vo.blNo
      }
      this.$router.push({ name: 'FreeTimeRequest', query: query }).catch(() => {})
      // const params = {
      //   blNo: vo.blNo,
      //   eiCatCd: 'O',
      //   sr: '1'
      // }
      //this.openPopup('FreeTimeExtensReqPop', params)
    },
    containerShuttlePopCall (bkgNo, srRno, blNo) {
      const vo = this.getData(bkgNo, srRno, blNo)

      const params = {
        blNo: vo.blNo
      }
      this.openPopup('ContainerShuttlePop', params)
    },
    codRequestPopCall (bkgNo, srRno, blNo) {
      const vo = this.getData(bkgNo, srRno, blNo)

      const params = {
        blNo: vo.blNo,
        bkgNo: vo.bkgNo
      }
      this.openPopup('CodRequestPop', params)
    },
    shipbackReqPopCall (bkgNo, srRno, blNo) {
      const vo = this.getData(bkgNo, srRno, blNo)

      const params = {
        blNo: vo.blNo,
        bkgNo: vo.bkgNo
      }
      this.openPopup('ShipbackReqPop', params)
    },
    bookingConfirmPopCall (items) {
      const params = {
        items: items
      }

      console.log('bookingConfirmPopCall params >>>> ', params)
      this.openPopup('BookingConfirmPop', params)
    },
    shipmentFinishPopCall (items) {
      const params = {
        items: items
      }
      this.openPopup('ShipmentFinishPop', params)
    },
    delayNoticeOutPopCall (items) {
      const params = {
        inOut: 'O',
        items: items
      }
      this.openPopup('DelayNoticeOutPop', params)
    },
    vesselChangePopCall (items) {
      const params = {
        inOut: 'O',
        items: items
      }
      this.openPopup('VesselChangePop', params)
    },
    rollOverNoticePopCall (items) {
      console.log('rollOverNoticePopCall @@@@')
      const params = {
        inOut: 'O',
        items: items
      }
      this.openPopup('RollOverNoticePop', params)
    },
    blCopyPopCall (items) {
      const params = {
        items: items
      }
      this.openPopup('BlCopyPop', params)
    },
    certificatePopCall (items) {
      const params = {
        items: items
      }
      this.openPopup('CertificatePop', params)
    },
    invoicePopCall (items) {
      const params = {
        items: items
      }
      this.openPopup('InvoicePop', params)
    },
    async combineInvoicePopCall (items) {
      //type A : 출발지 TO 정보 선택 후 출력
      //type B : 바로 출력
      var type = 'B'

      //체크박스가 1개 선택되고, 출발지crtCd 가 JP인 경우
      if (items.length === 1) {
        var crtCd = items[0].crtCd
        if (crtCd === 'JP') {
          type = 'A'
        }
      }

      const params = {
        eiCatCd: 'O',
        items: items,
        type: type
      }

      await this.recalculateFreightBillInfo(items)

      await this.auxInvoiceCreate()

      this.openPopup('CombineInvoicePop', params)
    },
    arrivalNoticePopCall (items, podCtrCd) {
      const params = {
        items: items,
        podCtrCd: podCtrCd
      }
      this.openPopup('ArrivalNoticePop', params)
    },
    freightPopCall (blNo) {
      const params = {
        blNo: blNo,
        remarkCd: ''
      }
      this.isShowPop = true
      this.openTp2Popup('FreightPop', params)
    },
    freeTimePopCall (items) {
      const params = {
        items: items
      }
      this.openPopup('FreeTimePop', params)
    },
    originalBLReIssuePopCall (blNo) {
      const params = {
        kind: 'BL',
        blNo: blNo,
        reqCatCd: '08'
      }
      this.openPopup('OriginalBLReIssuePop', params)
    },
    async blRiderPopCall (items) {
      await this.initAttachPopupData()

      this.popupCallback = this.setFileInfo

      const params = {
        blNo: items.blNo,
        type: 'BL',
        podCtrCd: items.podCtrCd,
        uploadFileList: this.uploadFileList,
        btnDownYn: true
      }
      this.openPopup('blAttachPop', params)
    },
    setFileInfo () {
      this.initAttachPopupData()
    },
    async initAttachPopupData () {
      this.uploadFileList = []

      const arrPromise = [
        this.getBLAttachList()
      ]

      await Promise.all(arrPromise)
    },
    // 파일 조회
    async getBLAttachList () {
      const params = {
        blNo: this.riderBlNo,
        type: 'BL'
      }

      this.riderYn = 'N'

      await blAttach.getBLAttachList(params)
      .then(response => {
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.blAttachList

          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.blRecvDocKndCd + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?blNo=' + this.riderBlNo + '&blRecvDocKndCd=' + file.blRecvDocKndCd + '&fileSeq=' + file.fileSeq + '&type=BL_ATTACH',
                  category: file.blRecvDocKndCd,

                  blNo: this.riderBlNo,
                  blRecvDocKndCd: file.blRecvDocKndCd,
                  fileSeq: file.fileSeq
                }
              )

              if (file.blRecvDocKndCd === '03') {
                this.riderYn = 'Y'
              }
            })
          }
        }
      }).catch(err => {
          console.log(err)
      })
    },
    // 요청사항 - 부킹
    fnRequestBooking (type) {
      console.log('@@@@ enter fnRequestBooking: ', type)
      const key = 'booking' + type
      if (this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridView.getCheckedRows()

      if (checkedRows.length === 0) {
        return
      }

      if (type === '01' || type === '02' || type === '03') { // 부킹 취소(01), 부킹 분할(02), 부킹 병합(03)
        // 한국어 아니면 분할, 병합 불가
        // if ((type === '02' || type === '03') && this.lang !== 'KOR') {
        //   return
        // }

        // const bkgNo = gridView.getValue(checkedRows[0], 'bkgNo')
        const bkgNo = this.gridList[checkedRows[0]].bkgNo
        const srRno = (this.$ekmtcCommon.isEmpty(this.gridList[checkedRows[0]].srRno) ? this.gridList[checkedRows[0]].blNo : this.gridList[checkedRows[0]].srRno) || ''
        let action = ''

        if (type === '01') {
          action = 'CANCEL'
        } else if (type === '02') {
          action = 'SPLIT'
        } else {
          action = 'COMBINE'
        }

        // KMTC ON 부킹인 경우 취소시 출항일자 기준 조건 체크함
        const spcShiprYn = this.gridList[checkedRows[0]].spcShiprYn
        if (spcShiprYn === 'Y' && type === '01') {
          // 출항일 기준 날짜 체크
          let cntrMinusChk = this.chkKmtcOnCntrInfoMinus(this.gridList[checkedRows[0]].etd.substring(0, 8)) // false : 출항일 7일 이내, true : 출항일 7일 이전

          if (!cntrMinusChk) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.514'))
            return
          }
        }

        // 중국 S/R 취소 처리시
        if (action === 'CANCEL' && (this.memberDetail.bkgPlcCd === 'SHA' || this.memberDetail.bkgPlcCd === 'SZP' || this.auth.serviceLang === 'CHN')) {
          this.commonBookingParams = {
            action: action,
            bkgNo: bkgNo,
            srRno: srRno
          }
        } else {
          this.commonBookingParams = {
            action: action,
            bkgNo: bkgNo
          }
        }

        this.commonBookingCallback = this.searchAct

        if (this.$refs.commonBooking === undefined) {
          this.commonBookingComponent = 'common-booking'
        } else {
          this.$nextTick(() => {
            this.$refs.commonBooking.doAction()
          })
        }
      } else if (type === '04') { // 픽업지 변경
        const goParams = {
          // bkgNo: gridView.getValue(checkedRows[0], 'bkgNo'),
          // srRno: gridView.getValue(checkedRows[0], 'srRno'),
          // blNo: gridView.getValue(checkedRows[0], 'blNo')
          bkgNo: this.gridList[checkedRows[0]].bkgNo,
          srRno: this.gridList[checkedRows[0]].srRno,
          blNo: this.gridList[checkedRows[0]].blNo
        }
        this.goDetailPage('CHANGE_PICKUP_PLACE', goParams)
      } else {
        alert(type)
      }
    },
    // KMTC ON 컨테이너 수량 적게 변경시 출항일자 7일 이내인 경우 불가, 7일 이전인 경우 가능 체크
    chkKmtcOnCntrInfoMinus (polEtd) {
      let toDay = this.getToday()

      if (toDay.length < 8 || toDay === undefined || polEtd < 8 || polEtd === undefined) {
        return false
      }

      let minusDate = this.$ekmtcCommon.getStrToDate(toDay) - this.$ekmtcCommon.getStrToDate(polEtd)
      minusDate = Math.abs(minusDate / (1000 * 3600 * 24))

      // 오늘 날짜가 출항일자보다 크면 두 날짜 차이는 마이너스 이므로 - 붙여줌
      if (this.$ekmtcCommon.getStrToDate(toDay) > this.$ekmtcCommon.getStrToDate(polEtd)) {
        minusDate = Number(minusDate) * -1
      }

      if (Number(minusDate) < 7) {
        return false
      }
      return true
    },
    getToday () {
      let today = new Date()
      let year = today.getFullYear() //연도
      let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
      let date = ('0' + today.getDate()).slice(-2) //날짜

      return year + month + date
    },
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('menu.MENU03.010') + '_' + this.$t('msg.ONEX010T010.002'), // '진행중인 업무_수출',
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
//        applyDynamicStyles: true,  // 주석 풀면 서류, 컨테이너 토글시 엑셀 다운로드 오류 발생함. 풀지 말것.
        exportTemplate: true,
        allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU03.010') + '_' + this.$t('msg.ONEX010T010.002')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    }, /*
    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('menu.MENU03.010')}_${this.$t('msg.ONEX010T010.002')}` // 진행중인업무_수출
      this.$ekmtcCommon.gridExportExcel(gridView, title)
    }, */
    async fnBlSplit (bkgNo, srRno, blNo) {
      let type = ''

      const options = {
        useConfirmBtn: true,
        message: `<div class='text_left'>${app.$t('msg.ONEX010T010.125')}<br/>
                    <input type="radio" id="blSplitRadio02" name="blSplitRadio" value="NBL" /><label for="blSplitRadio02"><span></span>${app.$t('msg.ONEX010T010.126')}</label><input type="radio" id="blSplitRadio01" name="blSplitRadio" value="PBL" /><label for="blSplitRadio01"><span></span>${app.$t('msg.ONEX010T010.127')}</label>
                    <span style="color:red;">
                      In the case of same container => Part S/R+
                      In the case of different container => Normal S/R+
                    </span>
                  </div>
                  `,
        onConfirmSelf: (resolve, $Simplert) => {
          type = $('*[name=\'blSplitRadio\']:checked').val()
          if (type === undefined || type === '') {
            return
          }
          resolve(true)
          $Simplert.close()
        }
      }

      if (!await this.$ekmtcCommon.asyncAlertMessage(options)) {
        return
      }

      this.commonBlParams = {
        action: type,
        bkgNo: bkgNo,
        srRno: srRno,
        blNo: blNo,
        saveFlag: 'BL_EDIT'
      }
      this.commonBlCallback = this.searchAct

      if (this.$refs.commonBL === undefined) {
        this.commonBlComponent = 'common-bL'
      } else {
        this.$nextTick(() => {
          this.$refs.commonBL.doAction()
        })
      }
    },
    // 요청사항 - 정산
    fnRequestCharge (type) {
      const key = 'charge' + type
      if (this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridView.getCheckedRows()

      if (checkedRows.length === 0) {
        return
      }

      // const bkgNo = gridView.getValue(checkedRows[0], 'bkgNo')
      // const srRno = gridView.getValue(checkedRows[0], 'srRno') || ''
      // const blNo = gridView.getValue(checkedRows[0], 'blNo') || ''
      const bkgNo = this.gridList[checkedRows[0]].bkgNo
      const srRno = this.gridList[checkedRows[0]].srRno || ''
      const blNo = this.gridList[checkedRows[0]].blNo || ''

      if (type === '01') {
        // 정산 발행 화면으로 이동
        const arrBlNo = []
        checkedRows.forEach(i => {
          // const blNo = gridView.getValue(i, 'blNo')
          const blNo = this.gridList[i].blNo
          arrBlNo.push(blNo)
        })
        this.goCalcIssueMain(arrBlNo)
      } else if (type === '02') {
        // 운임 정정 요청
        this.freightPopCall(blNo)
      } else if (type === '03') {
        // Free Time 연장 요청
        this.freeTimeExtensReqPopCall(bkgNo, srRno, blNo)
      } else {
        alert(type)
      }
    },
    // 정산 발행 화면으로 이동
    goCalcIssueMain (arrBlNo) {
      this.setCurrentData()
      const searchParams = {
        eiCatCd: 'O',
        tab: '1',
        dtKnd: 'BL',
        asProfileYn: this.searchParams.profileYn,
        keyword: arrBlNo.join(',')
      }
      this.$router.push({ name: 'calcIssueMain', params: searchParams }).catch(() => {})
      // this.$store.commit('settle/updateTabIndex', '1')
      // this.$store.commit('settle/updateSearchParams', searchParams)
    },
    // 요청사항 - B/L
    async fnRequestBl (type) {
      const key = 'bl' + type
      if (this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridView.getCheckedRows()

      if (checkedRows.length === 0) {
        return
      }

      // const bkgNo = gridView.getValue(checkedRows[0], 'bkgNo')
      // const srRno = gridView.getValue(checkedRows[0], 'srRno') || ''
      // const blNo = gridView.getValue(checkedRows[0], 'blNo') || ''
      const bkgNo = this.gridList[checkedRows[0]].bkgNo
      const srRno = this.gridList[checkedRows[0]].srRno || ''
      const blNo = this.gridList[checkedRows[0]].blNo || ''
      const srCnclYn = this.gridList[checkedRows[0]].srCnclYn || ''
      const goParams = {
        bkgNo: bkgNo,
        srRno: srRno,
        blNo: blNo,
        srCnclYn
      }

      if (type === '01') {
      //1549371 Constraints control 부킹 제약 조건 변경
      let apclYn = 'N'
      await trans.getRtnPhbtChrChkSr(bkgNo).then((res) => {
          if (res.data.apclYn === 'Y') {
            apclYn = 'Y'
            this.$ekmtcCommon.alertDefault(res.data.rmk)
          }
        })
        .catch(() => {
        })
        if (apclYn === 'Y') {
          return
        }
        // B/L 작성
        // if (checkedRows.length === 1) {
          this.goDetailPage('BL_REG', goParams)
        // } else {
        //   this.setCurrentData()
        //   const items = []

        //   checkedRows.forEach(i => {
        //     // const bkgNo = gridView.getValue(i, 'bkgNo')
        //     // const srRno = gridView.getValue(i, 'srRno') || ''
        //     // const fstEntDtm = gridView.getValue(i, 'fstEntDtm') || ''
        //     const bkgNo = this.gridList[i].bkgNo
        //     const srRno = this.gridList[i].srRno || ''
        //     const fstEntDtm = this.gridList[i].fstEntDtm || ''
        //     items.push({ bkgNo: bkgNo, srRno: srRno, fstEntDtm: fstEntDtm })
        //   })

        //   // 대표 Booking 찾기
        //   const srList = items.filter(vo => vo.srRno !== '')
        //   let mstVo
        //   if (srList.length === 1) {
        //     mstVo = srList[0]
        //   } else if (srList.length > 1) {
        //     mstVo = srList.reduce((vo1, vo2) => parseInt(vo1.fstEntDtm) > parseInt(vo2.fstEntDtm) ? vo1 : vo2)
        //   } else {
        //     mstVo = items.reduce((vo1, vo2) => parseInt(vo1.fstEntDtm) > parseInt(vo2.fstEntDtm) ? vo1 : vo2)
        //   }

        //   const arrBkgNo = items.filter(vo => vo.bkgNo !== mstVo.bkgNo).map(vo => vo.bkgNo)
        //   const multiBkgParam = {
        //     bkgNo: mstVo.bkgNo,
        //     srRno: mstVo.srRno,
        //     arrBkgNo: arrBkgNo
        //   }
        //   //console.log('BL_REG', multiBkgParam)
        //   this.goDetailPage('BL_REG_MULTI', multiBkgParam)
        // }
      } else if (type === '02') {
        // B/L 분할
        this.fnBlSplit(bkgNo, srRno, blNo)
      } else if (type === '03') {
        // 컨테이너 번호변경 요청서 팝업
        this.cntrNoCorrectReqPopCall(bkgNo, srRno, blNo)
      } else if (type === '04') {
        // B/L Certificate
        this.goDetailPage('BL_CERTIFICATE', goParams)
      } else if (type === '05') {
        // C/A Request
        const goParams = {
          bkgNo: bkgNo,
          srRno: srRno,
          blNo: blNo
        }
        this.goDetailPageBl('BL_EDIT', goParams)
      } else if (type === '06') {
        // B/L Issue Request
        const checkedItems = gridView.getCheckedRows()
        let voArr = []

        if (checkedItems.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.024') }) // 대상을 선택해 주세요.
          return
        } else if (checkedItems.length > 1) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.031') }) // 대상을 1개만 선택해 주세요.
          return
        }

        // 사전 검증
        let arrBlNo = []
        const arrCheck = []
        const arrIsFwdrYn = []
        let issStstus = ''

        checkedItems.forEach(idx => {
          const rowData = provider.getJsonRows(idx, idx)
          if (rowData.length > 0) {
            const vo = rowData[0]
            voArr.push(vo)
            const isDuplicat = arrBlNo.filter(v => v === vo.blNo)
            // console.log('BL_ISSUE isDuplicat ::: ', isDuplicat)
            // console.log('BL_ISSUE rowData ::: ', rowData)

            if (isDuplicat.length === 0) {
              arrBlNo.push(vo.blNo)
              arrCheck.push({ blNo: vo.blNo, userId: this.auth.userId })
              arrIsFwdrYn.push({ blNo: vo.blNo, fwdrCstNo: vo.fwdrCstNo, isFwdrYn: vo.isFwdrYn, cstCd: this.memberDetail.cstCd, userCtrCd: this.memberDetail.userCtrCd })

              // 세금계산서 발행상태 변수 추가 전달
              issStstus = vo.issueStatus
            }
          }
        })

        // console.log('BL_ISSUE issStstus ::: ', issStstus)

        for (let vo of voArr) {
          if (!this.isPermitIssueBlBtn(vo)) {
            return
          }
        }

        checkedItems.forEach(idx => {
          const rowData = provider.getJsonRows(idx, idx)

          if (rowData.length > 0) {
            const vo = rowData[0]
            voArr.push(vo)

            const isDuplicat = arrBlNo.filter(v => v === vo.blNo)
            if (isDuplicat.length === 0) {
              arrBlNo.push(vo.blNo)
            }
          }
        })

        if (arrBlNo.length === 0 && ['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) < 0) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ADD20220219.010')) //'출력 대상에 B/L이 존재하지 않습니다.'
        }

        this.$refs.commonUnsolved.openBlPaymentPop(arrBlNo, 'O', arrIsFwdrYn, issStstus)
      } else {
        alert(type)
      }
    },
    // 픽업부킹 등록
    goPickupBookingPage () {
      this.$router.push({ name: 'booking-new', params: { mtyPkupUsagYn: 'Y' } }).catch(() => {})
    },
    goDetailPageBlAtGrid (e, page, gridIndex) {
      e.preventDefault()
      // const bkgNo = gridView.getValue(gridIndex, 'bkgNo')
      // const srRno = gridView.getValue(gridIndex, 'srRno') || ''
      // const blNo = gridView.getValue(gridIndex, 'blNo') || ''
      const bkgNo = this.gridList[gridIndex].bkgNo
      const srRno = this.gridList[gridIndex].srRno || ''
      const blNo = this.gridList[gridIndex].blNo || ''

      const goParams = {
        bkgNo: bkgNo,
        srRno: srRno,
        blNo: blNo
      }

      this.goDetailPageBl(page, goParams)
    },
    async goDetailPageBl (page, params) {
      if (page === 'BL_EDIT') {
        let auth = ''
        const authParams = {
          bizCd: 'BL',
          rqRno: params.blNo,
          eiCatCd: ''
        }
        await transCommon.isHaveAuth(authParams).then(async res => {
          auth = res.data
        })
        if (auth !== 'Y') {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
          return
        }
      }

      const THIS = this
      const popParams = {
        page: page,
        params: params
      }
      this.popupCallback = (p) => {
        THIS.closePopup()
        THIS.goDetailPage(p.page, p.params)
      }
      this.openPopup('BLModifyNotiPop', popParams)
      // this.goDetailPage(page, params)
    },
    goDetailPageAtGrid (e, page, gridIndex) {
      e.preventDefault()
      console.log('this.gridList[gridIndex]: ', this.gridList[gridIndex])

      // const bkgNo = gridView.getValue(gridIndex, 'bkgNo')
      // const srRno = gridView.getValue(gridIndex, 'srRno') || ''
      // const blNo = gridView.getValue(gridIndex, 'blNo') || ''
      const bkgNo = this.gridList[gridIndex].bkgNo
      const srRno = (this.$ekmtcCommon.isEmpty(this.gridList[gridIndex].srRno) ? this.gridList[gridIndex].blNo : this.gridList[gridIndex].srRno) || ''
      const blNo = this.gridList[gridIndex].blNo || ''
      const spcShiprYn = this.gridList[gridIndex].spcShiprYn || ''
      const srCnclYn = this.gridList[gridIndex].srCnclYn || ''
      const goParams = {
        bkgNo: bkgNo,
        srRno: srRno,
        blNo: blNo,
        spcShiprYn: spcShiprYn,
        srCnclYn
      }

      this.goDetailPage(page, goParams)
    },
    // 상세 페이지
    goDetailPage (page, params) {
      console.log('goDetailPage --->', page, params)
      this.setCurrentData()

      const bkgNo = params.bkgNo
      const srRno = params.srRno
      const blNo = params.blNo
      const spcShiprYn = params.spcShiprYn
      const srCnclYn = params.srCnclYn

      // GA dataLayer 추가
      window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: bkgNo,
        popup_bl_no: blNo
      })

      if (page === 'BOOKING_EDIT') {
        this.$router.push({ name: 'booking-edit', params: { bkgNo: bkgNo, srRno: srRno, blNo: blNo, spcShiprYn: spcShiprYn, srCnclYn } }).catch(() => {})
      } else if (page === 'BL_REG') {
        this.$router.push({ name: 'bl', params: { bkgNo: bkgNo, srCnclYn } }).catch(() => {})
      } else if (page === 'BL_REG_MULTI') {
        this.$router.push({ name: 'bl', params: params }).catch(() => {})
      } else if (page === 'SR_EDIT') {
        if (this.memberDetail.userCtrCd !== 'CN') {
          this.$router.push({ name: 'bl', params: { bkgNo: bkgNo, srRno: srRno, blNo: blNo } }).catch(() => {})
        } else {
          this.$router.push({ name: 'booking-edit', params: { bkgNo: bkgNo, srRno: srRno, blNo: blNo } }).catch(() => {})
        }
      } else if (page === 'BL_EDIT') {
        this.$router.push({ name: 'bl', params: { bkgNo: bkgNo, srRno: srRno, blNo: blNo } }).catch(() => {})
      } else if (page === 'BL_CERTIFICATE') {
        this.$router.push({ name: 'bl-certificate', params: { bkgNo: bkgNo, blNo: blNo } }).catch(() => {})
      } else if (page === 'CHANGE_PICKUP_PLACE') {
        this.$router.push({ name: 'pickup-detail', params: { detailBkgNo: bkgNo } }).catch(() => {})
      }
    },
    // 요청사항 - 운송
    fnRequestTrans (type) {
      const key = 'trans' + type
      if (this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridView.getCheckedRows()

      if (checkedRows.length === 0) {
        return
      }
      // const bkgNo = gridView.getValue(checkedRows[0], 'bkgNo')
      // const srRno = gridView.getValue(checkedRows[0], 'srRno') || ''
      // const blNo = gridView.getValue(checkedRows[0], 'blNo') || ''
      const bkgNo = this.gridList[checkedRows[0]].bkgNo
      const srRno = this.gridList[checkedRows[0]].srRno || ''
      const blNo = this.gridList[checkedRows[0]].blNo || ''

      // GA dataLayer 추가
      window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: bkgNo,
        popup_bl_no: blNo
      })

      if (type === '01') {
        this.dangerDocReqPopCall(bkgNo, blNo)
      } else if (type === '02') {
        this.fullContainerOutReqPopCall(bkgNo, srRno, blNo)
      } else if (type === '03') {
        this.containerShuttlePopCall(bkgNo, srRno, blNo)
      } else if (type === '04') {
        this.codRequestPopCall(bkgNo, srRno, blNo)
      } else if (type === '05') {
        this.shipbackReqPopCall(bkgNo, srRno, blNo)
      } else if (type === '06') {
        this.trmlVesselLocationPopCall(bkgNo, srRno, blNo)
      } else {
        alert(type)
      }
    },
    // 요청사항 - 각종 출력
    fnRequestReport (type) {
      const key = 'report' + type
      if (type !== '05' && type !== '08' && type !== '09' && type !== '10' && this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridView.getCheckedRows()
      if (checkedRows.length === 0) {
        return
      }

      this.popupParams.items = []

      // GA dataLayer 추가
      const ga_bkgNo = this.gridList[checkedRows[0]].bkgNo
      const ga_blNo = this.gridList[checkedRows[0]].blNo || ''
      window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: ga_bkgNo,
        popup_bl_no: ga_blNo
      })

      const items = []
      if (type === '01') {
        if (this.lang === 'KOR') {
          // 선적 마감 안내문
          checkedRows.forEach(i => {
            // const bkgNo = gridView.getValue(i, 'bkgNo')
            const bkgNo = this.gridList[i].bkgNo
            items.push({ bkgNo: bkgNo })
          })

          this.shipmentFinishPopCall(items)
        } else if (this.memberDetail.userCtrCd === 'CN') {
          // booking 확인서
          checkedRows.forEach(i => {
            // const bkgNo = gridView.getValue(i, 'bkgNo')
            const bkgNo = this.gridList[i].bkgNo
            const blNo = this.gridList[i].blNo

            items.push({
                         bkgNo: bkgNo,
                         gubun: '4',
                         srBlNo: blNo
                      })
          })

          this.bookingConfirmPopCall(items)
        } else {
          // booking 확인서
          checkedRows.forEach(i => {
            // const bkgNo = gridView.getValue(i, 'bkgNo')
            const bkgNo = this.gridList[i].bkgNo
            items.push({ bkgNo: bkgNo, gubun: '4' })
          })

          this.bookingConfirmPopCall(items)
        }
      } else if (type === '02') {
        // Delay Notice
        checkedRows.forEach(i => {
          // const bkgNo = gridView.getValue(i, 'bkgNo')
          const bkgNo = this.gridList[i].bkgNo
          items.push({
                       parm1: bkgNo,
                       docNo: '01',
                       rtGubun: '28',
                       userId: this.auth.userId,
                       ctrCd: this.auth.userCtrCd
          })
        })

        this.delayNoticeOutPopCall(items)
      } else if (type === '03') {
        // Vessel Change
        checkedRows.forEach(i => {
          // const bkgNo = gridView.getValue(i, 'bkgNo')
          const bkgNo = this.gridList[i].bkgNo
          // items.push({ parm1: bkgNo, docNo: '19', prtGubun: '29' })
          items.push({ parm1: bkgNo })
        })

        this.rollOverNoticePopCall(items)
      } else if (type === '04') {
        // B/L Copy
        checkedRows.forEach(i => {
          // const blNo = gridView.getValue(i, 'blNo')
          const blNo = this.gridList[i].blNo
          items.push({ blNo: blNo, porDlyYn: 'N', noCnee: 'Y', noNotify: 'Y' })
        })

        this.blCopyPopCall(items)
      } else if (type === '05') { // JamSin
      console.log('requestAreaBtn1:', this.requestAreaBtn.report05)
      console.log('requestAreaBtn2:', this.nonFreightYn)
        if (this.requestAreaBtn.report05 === 'Y') {
           this.auxInvoiceData = []
          // Invoice
          checkedRows.forEach(i => {
            // const blNo = gridView.getValue(i, 'blNo')
            const blNo = this.gridList[i].blNo
            items.push({ blNo: blNo, remark: '', crtCd: this.gridList[i].polCtrCd })

            if (this.gridList[i].podCtrCd === 'ID' || this.gridList[i].polCtrCd === 'ID') {
              this.auxInvoiceData.push({ blNo: blNo })
            }
          })

          this.combineInvoicePopCall(items)
        } else {
          if (this.nonFreightYn === 'Y') {
            this.fnInvoicePop()
          }
        }
      } else if (type === '06') {
        // Free Time 승인
        checkedRows.forEach(i => {
          // const blNo = gridView.getValue(i, 'blNo')
          const blNo = this.gridList[i].blNo
          items.push({ blNo: blNo })
        })

        this.freeTimePopCall(items)
      } else if (type === '07') {
        // B/L Certificate
        checkedRows.forEach(i => {
          // const blNo = gridView.getValue(i, 'blNo')
          const blNo = this.gridList[i].blNo
          items.push({ blNo: blNo, crtfNo: '' })
        })

        this.certificatePopCall(items)
      } else if (type === '09') {
        checkedRows.forEach(i => {
          // const blNo = gridView.getValue(i, 'blNo')
          const blNo = this.gridList[i].blNo
          const podCtrCd = this.gridList[i].podCtrCd || ''

          items.push({ blNo: blNo, crtfNo: '', podCtrCd: podCtrCd })
        })

        this.blRiderPopCall(items)
      } else if (type === '10') {
        let kind = ''
        checkedRows.forEach(i => {
          const blNo = this.gridList[i].blNo
          const issueStatus = this.gridList[i].issueStatus || ''

          items.push({ blNo: blNo, issueStatus: issueStatus })

          if (issueStatus === '02' || issueStatus === '17') {
            kind = 'surr'
          } else if (issueStatus === '03' || issueStatus === '14') {
            kind = 'waybill'
          }
        })

        if (kind === 'surr') {
          this.openPopup('SurrenderNoticePop', items)
        } else {
          this.openPopup('SeawaybillNoticePop', items)
        }
      } else {
        // RECEIPT > ONIM099V080 popup
        // this.$ekmtcCommon.alertDefault('RECEIPT(' + blNo + ')')
        const blList = []
        checkedRows.forEach(vo => {
          //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
          const eachBlNo = this.gridList[vo].blNo
          blList.push({ blNo: eachBlNo })
        })
        const params = {
          blList: blList
        }
        importSvc.findAIreportReceipt(params).then(res => {
          // TODO : check test value
          // this.popupParams.ReceiptPrintPop.testUsrNo = 'KONOIKE01'
          // this.popupParams.ReceiptPrintPop.testCtrCd = 'ID'
          console.log('findAIreportReceipt res >>>> ', res)

          const resList = res.data
          for (const item of resList) {
            const initObj = {
              rcvRno: item.rcvRno,
              prtRvstNo: item.prtRvstNo,
              cstNo: this.memberDetail.cstCd,
              // cstNo: 'KTII00',
              cstNm: item.cstNm
            }
            this.popupParams.items.push(initObj)
            // this.popupParams.ReceiptPrintPop.testAgtCd = item.agtCd
          }
          // check test value
          this.openPopup('ReceiptPrintPop')
        }).catch(err => {
          console.log(err)
        })
      }
    },
    fnBlErrChk (e, bkgNo, srRno, blNo) {
      e.preventDefault()

      this.commonBlParams = {
        action: 'ERROR_CHECK',
        blNo: blNo,
        srRno: blNo !== '' ? '' : srRno
      }

      this.commonBlCallback = this.searchAct

      if (this.$refs.commonBL === undefined) {
        this.commonBlComponent = 'common-bL'
      } else {
        this.$nextTick(() => {
          this.$refs.commonBL.doAction()
        })
      }
    },
    openPopup (compNm, params) {
      if (compNm === 'SurrenderNoticePop' || compNm === 'SeawaybillNoticePop') {
        this.popupParams.items = params
      } else {
        if (compNm !== 'ReceiptPrintPop') {
          this.popupParams = params
        }
      }

      this.popupComponent = compNm
      this.$ekmtcCommon.layerOpen('.export_ongoing_list_popup')
    },
    closePopup () {
      if (this.popupComponent !== 'ReceiptPrintPop') {
        this.popupParams = {}
      }
      this.popupComponent = null
      this.popupCallback = ''
      this.$ekmtcCommon.layerClose('.export_ongoing_list_popup')
    },
    openTp2Popup (compNm, params) {
      this.popupTp2Params = params
      this.popupTp2Component = compNm
      this.$ekmtcCommon.layerOpen('.export_ongoing_list_popup_tp2')
    },
    closeTp2Popup () {
      this.popupTp2Params = {}
      this.popupTp2Component = null
      this.popupTp2Callback = ''
      this.$ekmtcCommon.layerClose('.export_ongoing_list_popup_tp2')
    },
    openTp3Popup (compNm, params) {
      this.popupTp3Params = params
      this.popupTp3Component = compNm
      this.$ekmtcCommon.layerOpen('.export_ongoing_list_popup_tp3')
    },
    closeTp3Popup () {
      this.popupTp3Params = {}
      this.popupTp3Component = null
      this.popupTp3Callback = ''
      this.$ekmtcCommon.layerClose('.export_ongoing_list_popup_tp3')
    },
    openRequestArea () {
      $('#working-progress-request-area').stop().slideDown(300)
    },
    closeRequestArea () {
      $('#working-progress-request-area').stop().slideUp(300)
    },
    getCodeNm (cdId, cd) {
      const cdList = this.commonCodes[cdId]
      if (cdList === undefined) {
        return cdId + '_' + cd
      }

      const cdVo = cdList.find(vo => vo.cd === cd)
      if (cdVo === undefined) {
        return cd
      }
      return cdVo.cdNm
    },
    async recalculateFreightBillInfo (items) {
      let blArrStr = ''

      for (let i = 0; i < items.length; i++) {
        if (blArrStr !== '') {
          blArrStr += ','
        }

        blArrStr += items[i].blNo
      }

      let req = {
        blNo: blArrStr
      }

      await CalcIssue.recalculateFreightBillInfo(req).then(response => {
        console.log('Success')
      }).catch(e => {
        console.log(e)
      })
    },
    async auxInvoiceCreate () {
      let blArrStr = ''

      if (this.auxInvoiceData.length > 0) {
        for (let i = 0; i < this.auxInvoiceData.length; i++) {
          if (blArrStr !== '') {
            blArrStr += ','
          }

          blArrStr += this.auxInvoiceData[i].blNo
        }

        let req = {
          blNo: blArrStr,
          eiCatCd: 'O'
        }

        await CalcIssue.auxInvoiceCreate(req).then(response => {
          console.log('Success')
        }).catch(e => {
          console.log(e)
        })
      }
    },
    checkSelectCtr () {
      if (this.selCtr !== '') {
        if (this.selPo === 'POL') {
          this.gridList = this.list.filter(vo => vo.polCtrCd === this.selCtr)
        } else if (this.selPo === 'POD') {
          this.gridList = this.list.filter(vo => vo.podCtrCd === this.selCtr)
        }
        //if (this.onlyCancelYn === 'Y' || this.onlyPickupYn === 'Y') {
          this.gridList = this.gridList.filter(vo => {
            let isPp = true
            let isCc = true
            if (this.searchParams.kind !== '02') {
              isPp = this.onlyPickupYn === 'N' ? vo.mtyPkupUsagYn !== 'Y' : vo.mtyPkupUsagYn === 'Y'
              isCc = this.onlyCancelYn === 'N' ? vo.bkgStsCd !== '03' : vo.bkgStsCd === '03'
            }
            return isPp && isCc
          })
        //}
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      } else {
        this.gridList = this.list
        //if (this.onlyCancelYn === 'Y' || this.onlyPickupYn === 'Y') {
          this.gridList = this.gridList.filter(vo => {
            let isPp = true
            let isCc = true
            if (this.searchParams.kind !== '02') {
              isPp = this.onlyPickupYn === 'N' ? vo.mtyPkupUsagYn !== 'Y' : vo.mtyPkupUsagYn === 'Y'
              isCc = this.onlyCancelYn === 'N' ? vo.bkgStsCd !== '03' : vo.bkgStsCd === '03'
            }
            return isPp && isCc
          })
        //}
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      }
    },
    openRfDataInfoPop (blNo) {
      console.log('openRfDataInfoPop blNo @@@@@ ', blNo)

      const params = {
        kind: 'working',
        cntrList: [],
        detailParams: {},
        selectCntrList: [],
        detailList: [],
        rank: '',
        blNo: blNo
      }

      this.callRfDataNotiPop(params)
    },
    callRfDataNotiPop (params) {
      const THIS = this

      this.popupCallback = (p) => {
        THIS.closePopup()
        THIS.callRfDataInfoPop(p)
      }

      this.openPopup('RfDataNotiPop', params)
    },
    callRfDataInfoPop (params) {
      this.openPopup('RfDataInfoPop', params)
    },
    trmlVesselLocationPopCall (bkgNo, srRno, blNo) {
      const vo = this.getData(bkgNo, srRno, blNo)

      const params = {
        blNo: vo.blNo || '',
        bkgNo: vo.bkgNo || '',
        rteCd: vo.rteCd,
        polCtrCd: vo.polCtrCd,
        menuCd: '01',
        vslCd: vo.vslCd,
        voyNo: vo.voyNo,
        polPortCd: vo.polPortCd,
        podPortCd: vo.podPortCd,
        polTrmlCd: vo.polTrmlCd,
        podTrmlCd: vo.podTrmlCd
      }

      console.log('TerminalVesselLocation params @@@@@@ ', params)

      this.openPopup('TerminalVesselLocation', params)
    },
    isPermitIssueBlBtn (vo) {
      let rtnMsg = this.$t('msg.ADD20220219.013') + '\n' //'B/L 발행 요청이 불가능합니다.\n'
      let isOk = true

      if (vo.blStCd === '99') {
        if (this.memberDetail.userCtrCd !== 'JP' && vo.cntrStsCd === 'N' && vo.elYn === 'N') {
          isOk = false
        } else if (this.memberDetail.userCtrCd !== 'JP' && vo.elYn === 'N') {
          isOk = false
        } else if (this.memberDetail.userCtrCd !== 'JP' && vo.cntrStsCd === 'N' && vo.cntrCfsYn === 'N') {
          isOk = false
        } else {
          isOk = true
        }
      }

      if (!isOk) {
        this.openAlert(rtnMsg)
      }

       return isOk
    }
  }
}

</script>
<style scoped>
.multiline-editor {
    white-space: pre !important;
}
</style>
